import type { ColumnDef } from '@tanstack/react-table'
import { memo, useCallback, useMemo } from 'react'
import { useRecoilState } from 'recoil'

import { GameTable } from '@/modules/inhouse/common/components'

import { ARRAY_MAX } from '../../atoms/common-state'
import { allbetsTableAtom, mybetsTableAtom, onlineTableAtom } from './state'

type GetTable = <T>(columns: ColumnDef<object, T>[]) => React.ReactNode

export function useGameTable() {
  const [online, setOnline] = useRecoilState(onlineTableAtom)
  const [mybets, setMybets] = useRecoilState(mybetsTableAtom)
  const [allbets, setAllbets] = useRecoilState(allbetsTableAtom)

  const getOnlineComponent: GetTable = useCallback(
    columns => {
      return <MemoGameTable columns={columns} data={online as object[]} />
    },
    [online],
  )

  const getMybetsComponent: GetTable = useCallback(
    columns => {
      return <GameTable columns={columns} data={mybets as object[]} />
    },
    [mybets],
  )

  const getAllbetsComponent: GetTable = useCallback(
    columns => {
      return <MemoGameTable columns={columns} data={allbets as object[]} />
    },
    [allbets],
  )

  const unshiftOnline: <T>(...items: T[]) => void = useCallback(
    (...items) => {
      setOnline(state => {
        const next = [...state]
        for (const item of items) {
          if (next.length >= ARRAY_MAX) next.pop()
          next.unshift(item)
        }
        if (next.length >= ARRAY_MAX) next.pop()
        return next
      })
    },
    [setOnline],
  )

  const unshiftMybets: <T>(...items: T[]) => void = useCallback(
    (...items) => {
      setMybets(state => {
        const next = [...state]
        for (const item of items) {
          if (next.length >= ARRAY_MAX) next.pop()
          next.unshift(item)
        }
        if (next.length >= ARRAY_MAX) next.pop()
        return next
      })
    },
    [setMybets],
  )

  const unshiftAllbets: <T>(...items: T[]) => void = useCallback(
    (...items) => {
      setAllbets(state => {
        const next = [...state]
        for (const item of items) {
          if (next.length >= ARRAY_MAX) next.pop()
          next.unshift(item)
        }
        if (next.length >= ARRAY_MAX) next.pop()
        return next
      })
    },
    [setAllbets],
  )

  const getOnline = useCallback(
    () => ({
      data: online,
      getComponent: getOnlineComponent,
      setData: setOnline,
      unshift: unshiftOnline,
    }),
    [getOnlineComponent, online, setOnline, unshiftOnline],
  )

  const getMybets = useCallback(
    () => ({
      data: mybets,
      getComponent: getMybetsComponent,
      setData: setMybets,
      unshift: unshiftMybets,
    }),
    [getMybetsComponent, mybets, setMybets, unshiftMybets],
  )

  const getAllbets = useCallback(
    () => ({
      data: allbets,
      getComponent: getAllbetsComponent,
      setData: setAllbets,
      unshift: unshiftAllbets,
    }),
    [allbets, getAllbetsComponent, setAllbets, unshiftAllbets],
  )

  const clear = useCallback(() => {
    setOnline([])
    setMybets([])
    setAllbets([])
  }, [])

  return useMemo(
    () => ({
      getOnline,
      getMybets,
      getAllbets,
      clear,
    }),
    [getOnline, getMybets, getAllbets, clear],
  )
}

export default useGameTable

const MemoGameTable = memo(GameTable, (oldProps, newProps) => {
  const oldId = oldProps.data.slice(-1)[0]?._id
  const newId = newProps.data.slice(-1)[0]?._id

  const dataNoUpdate =
    oldProps.data.length === newProps.data.length &&
    newProps.data.length < ARRAY_MAX - 1

  const lastDataNoUpdate =
    oldProps.data.length === newProps.data.length && oldId === newId

  const skipRender = dataNoUpdate || lastDataNoUpdate

  return skipRender
})
