import { lazy } from 'react'
import type { RouteObject } from 'react-router-dom'

const AsyncInhouse = lazy(() => import('@/modules/inhouse'))

export const gameRouter: RouteObject[] = [
  {
    path: '/game',
    children: [
      {
        // inhouse游戏
        path: '/game/inhouse/:gameId',
        element: (
          <AsyncInhouse />
        ),
      },
    ],
  },
]
