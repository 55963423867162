import { getVariable } from '../env'
import PlatformAndroid from './PlatformAndroid'
import PlatformIOS from './PlatformIOS'
import { isFromApk, isFromIpa } from './utils'

const serverClientId = getVariable('NEXT_PUBLIC_GOOGLE_CLICENT_ID') ?? ''
const fbAppId = getVariable('NEXT_PUBLIC_FACEBOOK_APP_ID') ?? ''

const Platform = (function () {
  if (isFromApk()) {
    return new PlatformAndroid(serverClientId, fbAppId)
  } else if (isFromIpa()) {
    return new PlatformIOS(serverClientId, fbAppId)
  } else {
    return null
  }
})()

export default Platform
