import type {
  Action,
  AnyAction,
  Middleware,
  PayloadAction,
  ThunkDispatch,
} from "@reduxjs/toolkit";
import {
  createDraftSafeSelector,
  createSelector,
  createSlice,
  isAnyOf,
} from "@reduxjs/toolkit";
import type { ParsedQuery } from "query-string";
import queryString from "query-string";

import {
  getCurrencyCode,
  getSign,
  setCurrencyCode,
} from "@/common/locale/currency";
import { setSupportedCurrencies } from "@/common/locale/currency-const";
import { isFromApp } from "@/common/platform/utils";

import type { RootState } from "@/modules/app/store";
import {
  getClientStorageForWebView,
  getUserId,
  getUserToken,
  removeUserId,
  removeUserToken,
} from "@/modules/user/storage";
import { localStorage } from "@/utils/localStorage";
import { isConditionError } from "@/utils/rtk-query/handleError";
import property from "@/websites/current/property";

import { generateAutoActions, updateAt } from "../app/generateAutoActions";
import { startAppListening } from "../app/listenerMiddleware";

import { persistUserCredentials } from "./storage";
import { Balance, CurrencyType, User } from "./types";
import { userApi } from "./userApi";

// localStorage.removeItem('openTgModal')
// localStorage.removeItem('userId')
// // 是否不显示添加到主屏幕
// localStorage.removeItem('NOT_SHOW_ADD_TO_HOME_SCREEN')
// // 埋点保活上报时间戳
// localStorage.removeItem('nextLoginLogTimeStamp')
// //退出后清除
// sessionStorage.removeItem('code_time')
// sessionStorage.removeItem('last_phone')
// // 充值引导弹窗的倒计时
// localStorage.removeItem('rechargeCountDownFinishedTimeStamp')
// // 下次充值时间戳
// localStorage.removeItem('nextRechargeCountDownTimeStamp')
// // 是否有限时间内充值成功
// localStorage.removeItem('isRechargeSuccessInLimitTime')
// // 移除缓存的消息ids
// localStorage.removeItem('notificationListIds')

const removeItems = [
  "openTgModal",
  "userId",
  "NOT_SHOW_ADD_TO_HOME_SCREEN",
  "nextLoginLogTimeStamp",
  "code_time",
  "last_phone",
  "rechargeCountDownFinishedTimeStamp",
  "nextRechargeCountDownTimeStamp",
  "isRechargeSuccessInLimitTime",
  "notificationListIds",
  "luckyWheelStorage",
  "wheelActivityCountDown",
  "rechargeCountDown",
];
interface BalanceChanged {
  id: string;
  amount: number;
}

export type UserState = {
  isBigAgent?: boolean;
  isLoggedIn: boolean;
  token: string;
  userId: string;
  user?: User;
  vipInfo?: any;
  vipInfoExt?: any & {
    /** 是否开启了VIP保级 */
    hasSetLevelProtection: boolean;
    levelProtectionStatus: any;
  };
  vipLevelExpireNotifyDays?: number;
  balanceFloatNumber?: number[];
  balanceChanged: BalanceChanged | undefined;
  clickLogin?: boolean;
  clickLogout?: boolean;
  // 是否已经充值过
  recharged?: boolean;
  // 货币标识 （用于推广页）
  currencySign?: string;
  // 原来的货币标识 （用于充值弹窗）
  originalCurrencySign?: string;
  /**
   * 新login服务
   * 账号绑定信息
   */
  accountsInfo?: {
    account_type: 1;
    account_value: string;
    verified: boolean;
  }[];
  /** 请求用户信息接口状态 */
  loadedUserInfo: boolean;
};

if (!getUserToken() && isFromApp()) {
  getClientStorageForWebView();
}

const query = queryString.parse(window.location.search);

function getFirstElementIfArray(
  value: ParsedQuery<string>[keyof ParsedQuery<string>]
): string | null {
  if (Array.isArray(value)) {
    return value[0];
  }
  return value;
}

// async function getBalanceInfo() {
//   return new Promise((resolve) => {
//     resolve(10);
//   });
// }

const userId = getFirstElementIfArray(query.userId);
const token = getFirstElementIfArray(query.token);
if (token && userId) {
  localStorage.clear();
  persistUserCredentials(userId, token);
  // getBalanceInfo().then(updateBanlance);
  // window.location.href = window.location.pathname;
}

const initialState: UserState = {
  isLoggedIn: false,
  token: getUserToken(),
  userId: getUserId(),
  balanceFloatNumber: [],
  isBigAgent: undefined,
  balanceChanged: undefined,
  clickLogin: false,
  clickLogout: false,
  recharged: false,
  currencySign: getSign(),
  originalCurrencySign: getSign(),
  loadedUserInfo: true,
};

declare global {
  interface Window {
    AndroidWebView: {
      clearClientStorage: () => void;
    };
  }
}

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateAt,
    updateBanlance(state, { payload }: PayloadAction<Balance | number>) {
      // const CURRENCY = getCurrencyCode() as CurrencyType;
      const CURRENCY = CurrencyType.PHP; // fuck you 24/05/11 强制使用PHP 金额 上面未注释 后期如果启用多币种 可还原
      if (state.user) {
        if (typeof payload === "number") {
          if (!isNaN(payload) && state.user.balance_map[CURRENCY]) {
            state.user.balance_map[CURRENCY].amount = payload;
          }
        } else {
          if (typeof payload === "object") {
            state.user.balance_map[CURRENCY] = payload;
          }

          console.log(`unexpected payload type: ${typeof payload} with value: ${payload}`);
        }
      }
    },
    logout: () => {
      // localStorage.clear()
      removeItems.forEach((item) => {
        localStorage.removeItem(item);
      });

      if (window && window?.AndroidWebView?.clearClientStorage) {
        window?.AndroidWebView?.clearClientStorage();
      }
      return { ...initialState, token: "", userId: "", clickLogout: true };
    },
    setClickLogin: (state, action) => {
      state.clickLogin = !!action.payload;
    },
    toggleBalanceFloat: (state, { payload }) => {
      const curFloatArr = state.balanceFloatNumber?.slice() || [];
      if (payload.clear) {
        curFloatArr.shift();
      } else {
        curFloatArr.push(payload.balance);
        state.balanceChanged = {
          id: Date.now().toString(),
          amount: payload.balance,
        };
      }
      state.balanceFloatNumber = curFloatArr;
    },
    changeCurrencySign: (state, { payload }) => {
      state.currencySign = payload;
    },
    changeOriginalCurrencySign: (state, { payload }) => {
      state.originalCurrencySign = payload;
    },
    setUserInfoLoaded: (state, { payload }: { payload: boolean }) => {
      state.loadedUserInfo = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        isAnyOf(
          userApi.endpoints.login.matchFulfilled,
          userApi.endpoints.loginByFacebookAccout.matchFulfilled,
          userApi.endpoints.loginByGoogleAccout.matchFulfilled,
          userApi.endpoints.loginByGoogleAuthCode.matchFulfilled,
          userApi.endpoints.loginByFacebookAuthCode.matchFulfilled,
          userApi.endpoints.loginByPhoneVerifyCode.matchFulfilled
        ),
        (state, action) => {
          const { user, token } = action.payload.data;
          state.user = user;
          state.token = token;
          state.userId = user._id;
          state.isLoggedIn = true;
          state.clickLogin = true;
        }
      )
      .addMatcher(
        userApi.endpoints.getUserInfo.matchRejected,
        (state, action) => {
          const { error } = action;
          if (!isConditionError(error.name)) {
            return { ...initialState, token: "", userId: "" };
          }
        }
      )
      .addMatcher(
        userApi.endpoints.getUserInfo.matchFulfilled,
        (state, action) => {
          state.user = action.payload.data;
          state.isLoggedIn = true;
          const { bind_currency_info, recharge_amount } = action.payload
            .data as any;


          // 用户是否充值过
          if (recharge_amount && Object.keys(recharge_amount).length > 0) {
            state.recharged = true;
            state.currencySign = state.originalCurrencySign;
            // 重置货币符号 很多地方都是直接用的getSign()，所以这里要重置一下,
            // getSign直接走的getConfig会比state前,所以无法直接用store中的货币符号
            setSupportedCurrencies({
              currency: getCurrencyCode(),
              sign: state.originalCurrencySign,
            });
          }
          /// fuck you 24/5/11 使用url参数判断货币符号 以下为注释 后期如果启用多币种 可还原
          // setCurrencyCode(
          //   bind_currency_info?.bind_currency ||
          //     property.locale.currencies[0] ||
          //     ""
          // );
          state.loadedUserInfo = true;
        }
      );
  },
});

const selectSelf = (state: RootState) => state;
export const selectUser = createDraftSafeSelector(
  selectSelf,
  (state: RootState) => state.user
);

export const selectUserRecharged = createSelector(
  selectUser,
  (user) => user.recharged
);

export const selectUserCurrencySign = createSelector(
  selectUser,
  (user) => user.currencySign
);

export const selectUserOriginalCurrencySign = createSelector(
  selectUser,
  (user) => user.originalCurrencySign
);

export const selectUserIsLogin = createDraftSafeSelector(
  selectSelf,
  (state: RootState) => selectUser(state).userId && selectUser(state).token
);

export const selectUserVipInfo = createSelector(
  selectUser,
  (userInfo) => userInfo.vipInfo
);

export const selectVipLevel = createSelector(
  selectUserVipInfo,
  (userVipInfo) => userVipInfo?.level ?? 0
);
export const selectClickLogin = (state: RootState) =>
  selectUser(state).clickLogin;

export const selectClickLogout = (state: RootState) =>
  selectUser(state).clickLogout;

export const selectUserName = (state: RootState) =>
  selectUser(state).user?.nick_name ?? "";

export const selectAccount = (state: RootState) =>
  selectUser(state).user?.account ?? "";

export const selectUserToken = (state: RootState) => selectUser(state).token;

export const selectUserId = (state: RootState) => selectUser(state).userId;

export const selectUserRechargeAmount = createSelector(
  selectUserVipInfo,
  (vipInfo) => (vipInfo?.recharge ? Number(vipInfo?.recharge) : 0)
);
export const selectUserBetAmount = createSelector(
  selectUserVipInfo,
  (vipInfo) => (vipInfo?.bet ? Number(vipInfo?.bet) : 0)
);

// 用户注册时间
export const selectUserRegisterTime = (state: RootState) =>
  selectUser(state).user?.create_at ?? "";

export const selectAvatarId = (state: RootState) =>
  selectUser(state).user?.avatar ?? "0";

export const selectUserBalance = (state: RootState) => {
  // const CURRENCY = getCurrencyCode() as CurrencyType;
  const CURRENCY = CurrencyType.PHP; //  fuck you 24/05/11 强制使用PHP 金额 上面为原逻辑 后期如果启用多币种 可还原
  return selectUser(state).user?.balance_map[CURRENCY];
};

export const selectIsBigAgent = (state: RootState) =>
  selectUser(state).isBigAgent;

export const selectBalanceChanged = (state: RootState) =>
  selectUser(state).balanceChanged;

/**
 * 新登录注册账号绑定信息
 */

export const selectAccountsInfo = createSelector(
  selectUser,
  (user) => user.accountsInfo
);

/**
 * 获取用户信息接口请求状态
 */
export const selectUserInfoLoaded = createSelector(
  selectUser,
  (user) => user.loadedUserInfo
);

type ActionsType = (typeof userSlice)["actions"];
type ResponseType = {
  [k in keyof ActionsType]: ActionsType[k];
};
export const { updateBanlance } = generateAutoActions<ResponseType>(
  userSlice.actions
);
export const {
  logout,
  toggleBalanceFloat,
  setClickLogin,
  changeCurrencySign,
  changeOriginalCurrencySign,
  setUserInfoLoaded,
} = userSlice.actions;
export const userReducer = userSlice.reducer;

export type AuthMiddleware<
  State = unknown,
  BasicAction extends Action = AnyAction,
  ExtraThunkArg = undefined
> = Middleware<
  ThunkDispatch<State, ExtraThunkArg, BasicAction>,
  State,
  ThunkDispatch<State, ExtraThunkArg, BasicAction>
>;

const clearUserCredentials = () => {
  if (window && window?.AndroidWebView?.clearClientStorage) {
    window?.AndroidWebView?.clearClientStorage();
  }
  removeUserId();
  removeUserToken();
};

// 权限中间件
export const authMiddleware: AuthMiddleware = () => (next) => (action) => {
  // if (userSlice.actions.logout.match(action)) {
  //   debugger
  //   clearUserCredentials();
  // } else
  if (
    isAnyOf(
      userApi.endpoints.login.matchFulfilled,
      userApi.endpoints.loginByFacebookAccout.matchFulfilled,
      userApi.endpoints.loginByGoogleAccout.matchFulfilled,
      userApi.endpoints.loginByFacebookAuthCode.matchFulfilled,
      userApi.endpoints.loginByGoogleAuthCode.matchFulfilled,
      userApi.endpoints.loginByPhoneVerifyCode.matchFulfilled
    )(action)
  ) {
    const { token, user } = action.payload.data;
    persistUserCredentials(user._id, token);
  } else if (userApi.endpoints.getBindInfo.matchRejected(action)) {
    const { error } = action;
    if (!isConditionError(error.name)) {
      return clearUserCredentials();
    }
  }
  return next(action);
};

startAppListening({
  matcher: isAnyOf(
    userApi.endpoints.getBindInfo.matchRejected,
    userApi.endpoints.getUserInfo.matchRejected
  ),
  effect: (action, api) => {
    if (!isConditionError(action.error?.name)) {
      api.dispatch(userSlice.actions.logout());
    }
  },
});
