const Modal = {
  parts: ["header"],
  baseStyle: {
    colorScheme: {
      normal: {
        dialog: {
          "--modal-bg": "var(--chakra-colors-bg-modal)",
        },
      },
      multilayer: {
        dialog: {
          "--modal-bg": "var(--chakra-colors-bg-modal)",
        },
      },
      blackTransparent: {
        dialog: {
          "--modal-bg": "rgba(0,0,0,0.75)",
        },
      },
    },
    dialog: {
      mx: {
        base: "2.5",
        md: "auto",
      },
      bgColor: "var(--modal-bg)",
      color: "text.base",
    },
    header: {
      textAlign: "center",
      fontWeight: "extrabold",
      color: "text.accent",
      fontSize: "lg",
      pt: 7,
      pb: 2,
    },
    overlay: {
      layerStyle: "ls-modal-mask",
    },
    body: {
      m: 0,
      px: 5,
      pb: 8,
    },
  },
  variants: {
    round: {
      dialog: {
        pt: "env(safe-area-inset-top)",
        borderRadius: "2xl",
      },
      body: {
        borderRadius: "2xl",
      },
    },
    "fill-square": {
      dialog: {
        pt: "env(safe-area-inset-top)",
        my: 0,
        mx: 0,
        borderRadius: 0,
      },
    },
  },
  sizes: {
    sm: {
      dialog: {
        w: "356px",
        maxW: "356px",
      },
    },
    md: {
      dialog: {
        w: "411px",
        maxW: "411px",
      },
      body: {
        m: 0,
        px: 7,
        pb: 7,
      },
    },
    mmd: {
      dialog: {
        w: "460px",
        maxW: "460px",
      },
      body: {
        mx: 10,
        my: 5,
        p: 0,
      },
    },
    lg: {
      dialog: {
        w: "556px",
        maxW: "556px",
      },
    },
    "3xl": {
      dialog: {
        w: "794px",
        maxW: "794px",
      },
      body: {
        m: 0,
        px: 7,
        pb: 7,
      },
    },
  },
  defaultProps: {
    variant: "round",
    colorScheme: "normal",
  },
};

export default Modal;
