const projectName = "P2"
    , appName = "Philucky"
    , sdk = {
        GOOGLE_ANALYTICS_TAG: "G-2T55592P38",
        MS_CLARITY: "jy0hkfsf6j",
        FACEBOOK_PIXEL_ID: "",
        TIKTOK_ANALYTICS_KEY: "",
        KWAI_ANALYTICS_KEY: "",
        SENTRY_DSN: "https://8ef7fccf6db9c0cbef0559e50ef491a7@o1156239.ingest.sentry.io/4505647833022464",
        ADJUST_TOKEN: "17it9nqm"
    }
    , features = {
        referral: true,
        referralNew: true,
        rechargeCountDown: true,
        addToHomeScreenBtn: true,
        thirdGameRedirect: true,
        SHOW_BET_PROGRESS: true,
        newRechargeComfirmModal: true,
        newThirdPartLogin: true,
        apply_fb_login: true,
        rechargePackage: true,
        newNotification: true,
        newAdminHomePage: true,
        applyGmpPlatform: true,
        gameCollection: true,
        newPgsoft: true,
        withdrawalVideoTutorials: true,
        bottomRecommend: true,
        luckyWheel: true,
        gameSearch: true,
        newAppLoginReward: true,
        moneyRain: true,
        withdraw_error_tips: true,
        code_type_switch: true,
        loginAndSignUpV3: true,
        rechargeActivityV2: !0,
        rechargePackageV3: !0,
        newServiceList: !0
    }
    , property = {
        projectName: projectName,
        appName: appName,
        domain: "philucky.com",
        i18n_dyn: {
            email: "support@philucky.com",
            liveChatEmail: "mailto:support@philucky.com",
            LICENSE_NUMBER: "GLH-OCCHKTW0703172023",
            LICENSE_LINK: "https://licensing.gaming-curacao.com/validator/?lh=c26de3fd192d6ae3b7371dc88d260e17"
        },
        locale: {
            currencies: ["PHP"],
            i18nLocales: ["en", "pt"],
            fallbackLocale: "en"
        },
        social: {
            telegramLink: "https://t.me/philuckyoffical",
            whatsappLink: "https://gnanbono.com/j/?lucky"
        },
        index: {
            TITLE: "Invite friends to join Philucky and participate in free activities, you can receive 700PHP in cash and withdraw it to GCASH",
            GOOGLE_FONT: "https://fonts.googleapis.com/css2?family=Montserrat:wght@600;700;800;900&display=swap",
            NEW_GOOGLE_RECAPTCHA_SITE_KEY: "6Le0j2gpAAAAABak2mM3ORjmURhjXMchLXpnWZ8g",
            LANGUAGE: "en",
            CANONICAL: "https://www.philucky.com",
            COLLECTTITLE: "Philucky",
            DESCRIPTION: "Sa pagbabahagi ng event na ito, kikita ka ng 700PHP sa GCASH",
            KEYWORDS: "Online Casino, Hash Encryption Games, Traceable Fair Casino",
            SHARE_TITLE: "Invite friends to join Philucky and participate in free activities, you can receive 700PHP in cash and withdraw it to GCASH",
            SHARE_DESC: "Sa pagbabahagi ng event na ito, kikita ka ng 700PHP sa GCASH",
            SHARE_SITE_NAME: "Philucky",
            SHARE_SITE_IMAGE: "https://static.philucky.com/1705656210091474093_app%20icon-192%281%29.png"
        },
        sdk: sdk,
        colorMode: "light",
        variables: {
            RECHARGE_BONUS: 100,
            NEXT_PUBLIC_GOOGLE_CLICENT_ID: "1000536705882-oe7ug8bdfi77c5ivqdjn3aobmsn5s44b.apps.googleusercontent.com",
            NEXT_PUBLIC_FACEBOOK_APP_ID: "1167859524618748",
            NEW_GOOGLE_RECAPTCHA_SITE_KEY: "6Le0j2gpAAAAABak2mM3ORjmURhjXMchLXpnWZ8g",
            NEXT_PUBLIC_LIVE_CHAT_LICENSE: "15004992",
            NEXT_PUBLIC_ENABLE_GRECAPTCHA: true,
            SHOW_USD_RATE: !1,
            SHOW_UNAUTHORIZED: true,
            SHOW_BIND_SELECT: !1,
            SHOW_RECHARGE_SELECT: !1,
            HIDE_HOME_RANK: true,
            REGION_LIMIT_LIST: [],
            SHOW_SPORTS: !1,
            SHOW_FUNDIST: !1,
            PGSOFT_GAME_ID: 2016,
            JILI_GAME_ID: 2022,
            FACAI_GAME_ID: 2026,
            FACAI_LANGUAGE: "1",
            SHOW_TOP_BANNER: true,
            GAME_RENDER_PLATFORM: "P2",
            MODAL_RESOURCE_SIGN: "P2",
            USE_WALLET_CONFIG: true,
            HOME_PAGE_CONFIG: "P2",
            REFERRAL_REFUND_FROM_API: !1,
            AGENT_ARCHIEVE_TAB: !1,
            ARCHIEVE_FROM_API: true,
            RECHARGE_BY_POST: true,
            WITHDRAW_FREE_LIST: true,
            OFFICE_METHOD: "GET",
            NOT_OFFICE_METHOD: "GET",
            SLOT_PARAMS: {
                country: "PH",
                language: "en"
            },
            GAME_HISTORY_SELECT: [{
                value: "evoplay",
                label: "Evoplay"
            }, {
                value: "jili",
                label: "JiLiGame"
            }, {
                value: "facai",
                label: "FacaiGame"
            }, {
                value: "pgsoft",
                label: "PGSoft"
            }, {
                value: "fundist",
                label: "Fundist"
            }],
            S3_MULTI_DOMAIN: "static.philucky.com",
            SHOW_BIND_PHONE: true,
            PHONE_AREA_CODE: "+63",
            ONLINE_METHOD: "GET",
            SHOW_NEW_RECHARGE_ACT: true,
            INVITE_TINYURL: "",
            GC_URl: "https://licensing.gaming-curacao.com/validator/?lh=c26de3fd192d6ae3b7371dc88d260e17",
            FIRST_LOGIN_REWARD: true,
            GMP_LANG: "en"
        },
        features: features,
        apk: {
            LINK: "/apk/philucky.apk",
            NAME: "philucky.apk"
        },
        gameDirectList: ["PGsoft", "Evoplay", "Live_casino", "Jili", "FAcai", "BGaming", "Betsoft", "Spinomenal", "Yggdrasil"]
    }
const colorMode = 'light'
export { projectName, appName, features, sdk, property, colorMode }
export default property
