// axios instance

import type { AxiosRequestConfig } from "axios";
import axios from "axios";

import { isFeatureEnabled } from "@/common/feature";
import { getUserId, getUserToken } from "@/modules/user/storage";
import { pm } from "@/utils/atom-performance/PerformanceMonitor";
import { getDeviceType } from "@/utils/tools";

const TIMEOUT = 2 * 60 * 1000;
const instance = axios.create({ timeout: TIMEOUT });

const DEVICE_TYPE = {
  apk: "android_app",
  "android-desktop-icon": "android_desktop",
  pc: "pc",
  android: "android_web",
  "ios-desktop-icon": "ios_desktop",
  ios: "ios_web",
  ios_app: "ios_app",
}[getDeviceType()];

export const getAuthorization = () => {
  if (typeof window === "undefined") {
    return null;
  }
  const token = getUserToken() ?? "";
  const userId = getUserId() ?? "";
  // console.log("--fuck you getAuthorization:", token, userId);
  return `Bearer ${getUserToken()}`;

  // return `${userId};${token}`;
};

const signupUrl = "/user/register";
const signinUrl = "/user/login";
const rechargeUrl = "/user/recharge";
const withdrawUrl = "/user/withdraw";
const targetUrls = [signinUrl, signupUrl, rechargeUrl, withdrawUrl];

instance.interceptors.request.use(
  (config) => {
    // 在发送请求之前做些什么
    if (config.headers) {
      const authorization = getAuthorization();
      if (authorization) {
        config.headers.authorization = authorization;
      }

      if (isFeatureEnabled("loginAndSignUpV3")) {
        config.headers["U-DeviceType"] = DEVICE_TYPE;
      }
    }

    (config as any)["request-startTime"] = performance.now();
    return config;
  },
  (error) => {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

instance.interceptors.response.use((response) => {
  const currentTime = performance.now();
  const startTime = (response.config as any)["request-startTime"];

  if (response.config.url && targetUrls.includes(response.config.url)) {
    const cost = currentTime - startTime;
    pm.log({
      eventName: response.config.url,
      eventCategory: "common_cost",
      value: cost,
    });
  }

  return response;
});

export const request = instance;
export declare type RequestMethod = AxiosRequestConfig["method"];
export declare type RequestData = AxiosRequestConfig["data"];
export declare type RequestParams = AxiosRequestConfig["params"];

export default request;

