import { memo } from 'react'
import {  useUserAuth } from '@/utils/hooks'
import { useGetUserInfoQuery } from '../user/userApi'

function _QueriesOnAppInit() {
  const [userId, token] = useUserAuth()
  useGetUserInfoQuery({ token, user_id: userId }, { skip: !token || !userId })
  return null
}

const QueriesOnAppInit = memo(_QueriesOnAppInit)

export default QueriesOnAppInit
