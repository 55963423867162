const layerStyles = {
  "ls-prim3-to-seco3": {
    bgGradient: "linear-gradient(to-b, fill-prim.reset3, fill-seco.reset3)",
    color: "white",
  },
  "ls-prim": {
    bgGradient: "linear-gradient(to-r, fill-prim.reset1, fill-prim.reset2)",
    color: "white",
  },
  "ls-seco": {
    bgGradient: "linear-gradient(to-r, fill-seco.reset1, fill-seco.reset2)",
    color: "white",
  },
  "ls-prim-to-r": {
    bgGradient: "linear-gradient(to-r, fill-prim.reset1, fill-prim.reset2)",
    color: "white",
  },
  "ls-tert": {
    bgGradient: "linear-gradient(to-r, fill-tert.reset1, fill-tert.reset2)",
    color: "white",
  },
  "ls-seco-to-r": {
    bgGradient: "linear-gradient(to-r, fill-seco.reset1, fill-seco.reset2)",
    color: "white",
  },
  "ls-accent": {
    bgGradient: "linear-gradient(to-b, fill-accent.a1, fill-accent.a2)",
    color: "white",
  },
  "ls-accent-to-b": {
    bgGradient: "linear-gradient(to-b, fill-accent.a1, fill-accent.a2)",
    color: "white",
  },
  "ls-gold": {
    bgGradient: "linear-gradient(to-b, fill-gold.a1, fill-gold.a2)",
    color: "white",
  },
  "ls-silver": {
    bgGradient: "linear-gradient(to-r, fill-silver.a1, fill-silver.a2)",
    color: "white",
  },
  "ls-gray": {
    bgGradient: "linear-gradient(to-r, fill-gray.a1, fill-gray.a2)",
    color: "white",
  },
  "ls-blur": {
    color: "white",
    backdropFilter: "blur(24px)",
    background: "blackAlpha.100",
  },
  "ls-modal-mask": {
    backdropFilter: "blur(100px)",
    background: "blackAlpha.50",
  },
  "ls-modal-mask-blur-50": {
    backdropFilter: "blur(50px)",
    background: "blackAlpha.600",
  },
  "ls-modal-mask-blur-100": {
    color: "white",
    backdropFilter: "blur(100px)",
    background: "blackAlpha.700",
  },
  "text-omit": {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  "ls-drawer-mask": {
    background: "transparent",
  },
  "ls-white-transparent": {
    background: "rgba(255,255,255,0.1)",
    color: "white",
  },
  seco: {
    background: "fill-seco.reset1",
    color: "white",
  },
};
export default layerStyles;
