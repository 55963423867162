const Tag =  {
    baseStyle: {
        container: {
            fontWeight: 700
        }
    },
    variants: {
        stage1: {
            container: {
                backgroundColor: "rgba(224, 68, 97, 0.2)",
                color: "#e04461"
            }
        },
        stage2: {
            container: {
                backgroundColor: "rgba(62, 204, 127, 0.2)",
                color: "#3ecc7f"
            }
        },
        stage3: {
            container: {
                backgroundColor: "rgba(3, 188, 202, .2)",
                color: "#03bcca"
            }
        },
        stage4: {
            container: {
                backgroundColor: "rgba(50, 143, 222, .2)",
                color: "#328fde"
            }
        },
        stage5: {
            container: {
                backgroundColor: "rgba(236, 143, 64, .2)",
                color: "#ec8f40"
            }
        }
    }
}

export default Tag;