const LoginAndSignUp = {
  parts: ["LoginSideAdColor", "LoginSideAdBg", "TripartiteLogin", "LoginForm"],
  baseStyle: {
    LoginSideAdColor: {
      color: "white",
    },
    LoginSideAdBg: {
      bg: "gray.300",
    },
    TripartiteLogin: {
      color: "gray.700",
    },
    LoginForm: {
      color: "gray.700",
    },
    SignupForm: {
      color: "gray.700",
    },
  },
};

export default LoginAndSignUp;
