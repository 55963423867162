const Campaign = {
  baseStyle: {},
  variants: {
    outline: {
      addon: {
        color: "gray.600",
      },
      field: {
        borderColor: "gray.400",
        border: "2px solid",
        fontSize: "14px",
        fontWeight: "bold",
        lineHeight: "18px",
        color: "gray.900",
        bg: "gray.50",
        _hover: {
          borderColor: "prim.500",
        },
        _focusVisible: {
          borderColor: "prim.500",
          boxShadow: "none",
        },
        _disabled: {
          bg: "gray.300",
          _hover: {
            borderColor: "gray.400",
          },
        },
        _placeholder: {
          fontSize: "14px",
          fontWeight: "semibold",
          color: "text.base",
          lineHeight: "16px",
          borderColor: "gray.400",
        },
      },
      element: {
        bg: "transparent",
        color: "gray.600",
      },
    },
    filled: {
      field: {
        _focusVisible: {
          bg: "gray.100",
          borderColor: "transparent",
        },
      },
    },
  },
  sizes: {
    md: {
      field: {
        h: "46px",
      },
      group: {
        "--input-height": "46px",
      },
    },
    sm: {
      field: {
        h: "36px",
      },
      group: {
        "--input-height": "36px",
      },
    },
  },
};

export default Campaign;
