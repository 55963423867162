
import type { types } from '@/utils/atom-shared'

let _games: Record<string, types.GameConfig> | null = null

export async function loadInhouseConfig(): Promise<void> {
  // const currencyCode = getCurrencyCode() || ''
  // const res = await axios.get(
  //   getHashUrl(getLocalConfigBaseUrl(currencyCode) + '/inhouse-config.json'),
  // )
  // if (res.status !== 200) {
  //   fatal(`load inhouse config failed: ${res.status}`)
  //   return
  // }
  _games = { "dice": { "defaultBetAmount": 10, "minBetAmount": 10 }, "mines": { "defaultBetAmount": 10, "minBetAmount": 10 }, "double": { "defaultBetAmount": 10, "minBetAmount": 10 }, "crash": { "defaultBetAmount": 10, "minBetAmount": 10 }, "ring": { "defaultBetAmount": 10, "minBetAmount": 10 }, "coinflip": { "defaultBetAmount": 10, "minBetAmount": 10 }, "limbo": { "defaultBetAmount": 10, "minBetAmount": 10 }, "stairs": { "defaultBetAmount": 10, "minBetAmount": 10 }, "keno": { "defaultBetAmount": 10, "minBetAmount": 10 }, "roulette": { "defaultBetAmount": 10, "minBetAmount": 10 }, "plinko": { "defaultBetAmount": 10, "minBetAmount": 10 }, "fierybot": { "defaultBetAmount": 10, "minBetAmount": 10 } };
  // _games = res.data
}

/** 读取游戏配置 */
export function getGameConfig(gameId: string): types.GameConfig {
  // if (!_games) {
  //   throw new Error('inhouse config not loaded')
  // }
  _games = { "dice": { "defaultBetAmount": 10, "minBetAmount": 10 }, "mines": { "defaultBetAmount": 10, "minBetAmount": 10 }, "double": { "defaultBetAmount": 10, "minBetAmount": 10 }, "crash": { "defaultBetAmount": 10, "minBetAmount": 10 }, "ring": { "defaultBetAmount": 10, "minBetAmount": 10 }, "coinflip": { "defaultBetAmount": 10, "minBetAmount": 10 }, "limbo": { "defaultBetAmount": 10, "minBetAmount": 10 }, "stairs": { "defaultBetAmount": 10, "minBetAmount": 10 }, "keno": { "defaultBetAmount": 10, "minBetAmount": 10 }, "roulette": { "defaultBetAmount": 10, "minBetAmount": 10 }, "plinko": { "defaultBetAmount": 10, "minBetAmount": 10 }, "fierybot": { "defaultBetAmount": 10, "minBetAmount": 10 } };

  return _games[gameId]
}
