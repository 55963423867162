const AtomSelect = {
    parts: ["valueContainer", "control", "menuList", "singleValue", "option", "optionText", "optionIcon", "placeholder"],
    baseStyle: {
        valueContainer: {
            color: "text.accent"
        },
        option: {
            alignItems: "center",
            cursor: "pointer",
            color: "text.secondary",
            _hover: {
                color: "prim.500"
            },
            _selected: {
                color: "text.accent"
            }
        },
        optionIcon: {
            color: "prim.500",
            _groupHover: {
                color: "inherit"
            }
        },
        singleValue: {
            color: "text.accent",
            alignItems: "center"
        },
        menuList: {
            boxShadow: "0px 8px 16px 0px rgba(0,0,0,0.1)",
            "--menu-bg": "var(--chakra-colors-bg-control)",
            border: "none"
        },
        placeholder: {
            color: "gray.600"
        },
        dropdownIndicator: {
            pl: "0",
            pr: "3"
        }
    },
    sizes: {
        "2xl": {
            valueContainer: {
                pl: "3",
                pr: "0",
                textStyle: "h5"
            },
            control: {
                borderRadius: "lg",
                h: "50px",
                minH: "50px"
            },
            menuList: {
                borderRadius: "lg"
            },
            option: {
                textStyle: "text3",
                px: 4,
                h: 10
            },
            optionIcon: {
                mr: 3
            }
        },
        xl: {
            valueContainer: {
                pl: "3",
                textStyle: "h5"
            },
            control: {
                borderRadius: "lg",
                h: "48px",
                minH: "48px"
            },
            menuList: {
                borderRadius: "lg"
            },
            option: {
                textStyle: "text3",
                px: 4,
                h: 10
            },
            optionIcon: {
                mr: 3
            }
        },
        lg: {
            valueContainer: {
                pl: "3",
                textStyle: "h5"
            },
            control: {
                borderRadius: "lg",
                h: "46px",
                minH: "46px"
            },
            menuList: {
                borderRadius: "lg"
            },
            option: {
                textStyle: "text3",
                px: 4,
                h: 10
            },
            optionIcon: {
                mr: 3
            }
        },
        md: {
            valueContainer: {
                pl: "2",
                textStyle: "text5"
            },
            control: {
                borderRadius: "md",
                h: "9",
                minHeight: "9"
            },
            menuList: {
                borderRadius: "md"
            },
            option: {
                textStyle: "text5"
            },
            optionIcon: {
                mr: 2.5
            }
        }
    },
    variants: {
        filled: {}
    },
    defaultProps: {
        variant: "filled",
        size: "md"
    }
}

export default AtomSelect;