const semanticClolrs = {
    text: {
        accent: "gray.900",
        secondary: "gray.800",
        base: "gray.700",
        moneyPrim: "four.400",
        money: "four.500",
        moneyFail: "prim.500",
        moneyWin: "#ff0000",
        moneyBet: "#1376FA",
        cashbackTabItemHover: "#FF1111",
        rdmModalTitle: "white",
        rdmModal: "four.400",
        tgModalColor: "#000000",
        tgModalColor1: "#3F4F70",
        Title: "prim.400",
        sectionHeading: "prim.400"
    },
    icon: {
        accent: "gray.900",
        secondary: "gray.800",
        base: "gray.700",
        ImCheckmark: "prim.500"
    },
    bg: {
        baseLayer: "gray.100",
        modal: "gray.100",
        content: "gray.500",
        control: "gray.500",
        cashbackTab: "gray.500",
        cashbackCourse: "gray.500",
        drawer: "rgba(0,0,0,0.75)",
        promotionGrid: "gray.400",
        rdmModal: "linear-gradient(0deg, #105BBC 0%, #022054 100%)",
        tgModalBg: "transaparent",
        tgModalClose: "#000000",
        appLayout: {
            header: "gray.400",
            midCol: "gray.400",
            content: "gray.200",
            blackContent: "#0D121B",
            sideNav: "gray.300",
            noticePanel: "gray.400",
            footer: "gray.400",
            tabBar: "gray.500"
        }
    },
    facebook: "#169EDF",
    datepicker: {
        panelBg: "gray.500",
        headerBg: "gray.400",
        border: "gray.600",
        cellBgActive: "prim.500",
        cellBgInRange: "prim.800",
        dayNameText: "gray.700",
        cellText: "text.accent",
        cellTextActive: "white",
        cellTextInRange: "white",
        cellTextOutRange: "gray.600"
    },
    signupFail: {
        bg: "system.reverse.info"
    },
    rewardCenter: {
        item: {
            bg: "#222C3D",
            canReceiveText: "seco.500",
            cannotReceiveText: "#FFC93D",
            cashBackText: "gray.900",
            wheelText: "gray.900",
            progressFinishNumber: "#1376FA",
            progressTotalNumber: "gray.900",
            progressBar: "#1376FA",
            countdownNumber: "#1376FA",
            upgradeNumber: "#1376FA"
        }
    },
    announceDialog: {
        modalTopTitle: "white",
        modalTopSingleTitle: "gray.900",
        modalTitle: "white",
        modalContent: "gray.800",
        closeBtnColor: "white",
        modalBtnColor: "white",
        bg: "linear-gradient(0deg, #105BBC 0%, #022054 100%)",
        modalConBg: "gray.500",
        ModalBtnBg: "prim.500",
        remindColor: "gray.50",
        borderLineColor: "rgba(255, 255, 255, 0.06)"
    },
    rechargeActBlock: {
        numBg: "linear-gradient(90deg, #FFFAAA 0%, #FFF 56.41%, #FFFAAC 100%)",
        bottomBg: "#E09E40"
    },
    recharge: {
        hot: "#F9A43D"
    },
    serviceList: {
        bg: "gray.100",
        titleColor: "white",
        itemBg: "gray.500",
        itemTitle: "white",
        itemDesc: "gray.700"
    },
    notificationList: {
        bg: "gray.500"
    }
}
export default semanticClolrs;