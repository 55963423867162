const Checkbox = {
    parts: ["container", "control", "icon"],
  baseStyle: {},
  variants: {
      signup: {
          container: {
              alignItems: "start"
          },
          control: {
              width: "18px",
              height: "18px",
              borderRadius: "3px",
              borderColor: "gray.400",
              _checked: {
                  bg: "transparent",
                  borderColor: "green.500",
                  color: "green.500",
                  _hover: {
                      bg: "transparent",
                      borderColor: "green.500"
                  }
              },
              _hover: {
                  borderColor: "green.500"
              }
          },
          icon: {
              bgColor: "transparent",
              borderColor: "green.500",
              color: "green.500"
          },
          label: {
              fontSize: "12px",
              lineHeight: "18px",
              fontWeight: "600"
          }
      },
      selectway: {
          container: {
              alignItems: "center"
          },
          control: {
              width: 5,
              height: 5,
              my: "px",
              borderRadius: "6px",
              borderColor: "gray.700",
              rounded: "full",
              _checked: {
                  bg: "transparent",
                  borderColor: "green.500",
                  color: "green.500",
                  _hover: {
                      bg: "transparent",
                      borderColor: "green.500"
                  }
              },
              _hover: {
                  borderColor: "green.500"
              }
          },
          icon: {
              bgColor: "transparent",
              borderColor: "#fff",
              color: "green.500"
          },
          label: {
              fontSize: "12px",
              lineHeight: "16px",
              fontWeight: "600"
          }
      },
      tgModal: {
          container: {
              alignItems: "center"
          },
          control: {
              width: "18px",
              height: "18px",
              my: "px",
              borderRadius: "3px",
              borderColor: "gray.50",
              _checked: {
                  bg: "transparent",
                  borderColor: "gray.50",
                  color: "gray.50",
                  _hover: {
                      bg: "transparent",
                      borderColor: "gray.50"
                  }
              },
              _hover: {
                  borderColor: "gray.50"
              }
          },
          icon: {
              bgColor: "transparent",
              borderColor: "gray.50",
              color: "gray.50"
          },
          label: {
              fontSize: "12px",
              lineHeight: "16px",
              fontWeight: "600"
          }
      },
      announceModal: {
          container: {
              alignItems: "center"
          },
          control: {
              width: "18px",
              height: "18px",
              my: "px",
              borderRadius: "3px",
              borderColor: "white",
              _checked: {
                  bg: "transparent",
                  borderColor: "white",
                  color: "white",
                  _hover: {
                      bg: "transparent",
                      borderColor: "white"
                  }
              },
              _hover: {
                  borderColor: "white"
              }
          },
          icon: {
              bgColor: "transparent",
              borderColor: "white",
              color: "white"
          },
          label: {
              fontSize: "12px",
              lineHeight: "16px",
              fontWeight: "600",
              color: "white"
          }
      },
      actModal: {
          container: {
              alignItems: "center"
          },
          control: {
              width: "18px",
              height: "18px",
              my: "px",
              borderRadius: "3px",
              borderColor: "gray.900",
              _checked: {
                  bg: "transparent",
                  borderColor: "gray.900",
                  color: "gray.900",
                  _hover: {
                      bg: "transparent",
                      borderColor: "gray.900"
                  }
              },
              _hover: {
                  borderColor: "gray.900"
              }
          },
          icon: {
              bgColor: "transparent",
              borderColor: "gray.900",
              color: "gray.900"
          },
          label: {
              fontSize: "12px",
              lineHeight: "16px",
              fontWeight: "600",
              color: "white"
          }
      }
  }
}

export default Checkbox;