const textStyles = {
  h1: {
    fontSize: "40px",
    lineHeight: "52px",
    fontWeight: "900",
  },
  h2: {
    fontSize: "30px",
    lineHeight: "40px",
    fontWeight: "800",
  },
  h3: {
    fontSize: "20px",
    lineHeight: "26px",
    fontWeight: "800",
  },
  h4: {
    fontSize: "18px",
    lineHeight: "24px",
    fontWeight: "800",
  },
  h5: {
    fontSize: "16px",
    lineHeight: "20px",
    fontWeight: "800",
  },
  h6: {
    fontSize: "14px",
    lineHeight: "18px",
    fontWeight: "800",
  },
  h7: {
    fontSize: "12px",
    lineHeight: "16px",
    fontWeight: "800",
  },
  h8: {
    fontSize: "12px",
    fontStyle: "italic",
    fontWeight: "800",
    lineHeight: "16px",
  },
  h9: {
    fontSize: "11px",
    fontStyle: "italic",
    fontWeight: "800",
    lineHeight: "14px",
  },
  text1: {
    fontSize: "16px",
    lineHeight: "20px",
    fontWeight: "700",
  },
  text2: {
    fontSize: "16px",
    lineHeight: "20px",
    fontWeight: "600",
  },
  text3: {
    fontSize: "14px",
    lineHeight: "18px",
    fontWeight: "700",
  },
  text4: {
    fontSize: "14px",
    lineHeight: "18px",
    fontWeight: "600",
  },
  text5: {
    fontSize: "12px",
    lineHeight: "16px",
    fontWeight: "700",
  },
  text6: {
    fontSize: "12px",
    lineHeight: "16px",
    fontWeight: "600",
  },
  text7: {
    fontSize: "11px",
    lineHeight: "10px",
    fontWeight: "800",
    transform: "scale(0.91)",
  },
};

export default textStyles;
