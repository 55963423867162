import type React from 'react'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { getVariable } from '@/common/env'
import { getFeatureFlag, isFeatureEnabled } from '@/common/feature'
import { useAppDispatch, useAppSelector } from '@/modules/app/store'
import {
  DrawerKey,
  toggleDrawerStart,
  toggleLiveChatWigetVisibility,
} from '@/modules/app-layout/app-layout-slice'
import {
  getIsBindCurrency,
  selectIsOpenActive,
  setCallBackType,
  switchBindModal,
} from '@/modules/bind-currency/currencySlice'
import {
  NEED_CLEAR_DATA_KEY,
  openModal,
} from '@/modules/global-modal/globalModalSlice'
import { GlobalModalKey } from '@/modules/global-modal/types'
import { selectUserIsLogin } from '@/modules/user/userSlice'

import { useNavToHomeGame } from './useNavToHomeGame'

export type Action = {
  href?: string
  eventName: string
  data?: {
    navigation_type?: number
    target?: string
    [key: string]: any
  }
}

const NEED_LOGIN_MODAL_KEYS = [
  GlobalModalKey.Coupon,
  GlobalModalKey.LoginReward,
  GlobalModalKey.LuckyWheel,
  GlobalModalKey.TgModal,
  GlobalModalKey.newRechargeActModal,
]

const newAdminHomePage = isFeatureEnabled('newAdminHomePage')
const serviceListTgLink = getFeatureFlag('serviceListTgLink')

/** 通用事件触发，可以用来实现配置化的行为 */
export function useActionTrigger<T extends HTMLElement>() {
  const dispatch = useAppDispatch()
  const isLogin = useAppSelector(selectUserIsLogin)
  const navigate = useNavigate()
  const SHOW_BIND_SELECT = getVariable('SHOW_BIND_SELECT')
  const isBindCurrency = useAppSelector(getIsBindCurrency)
  const isOpenActive = useAppSelector(selectIsOpenActive)


  const navToHomeGame = useNavToHomeGame()

  return useCallback(
    (action?: Action, callback?: () => void): React.MouseEventHandler<T> =>
      e => {
        e?.preventDefault()
        if (!action) return undefined
        if (action.href) {
          if (action?.data?.navigation_type === 1) {
            // 站外打开
            if (action?.data?.target === '_blank') {
              window.open(action.href)
            } else {
              window.location.href = action.href
            }
          } else {
            // 站内打开
            if (action?.data?.target === '_blank') {
              window.open(window.location.origin + action.href)
            } else {
              callback && callback()
              return navigate(action.href)
            }
          }
        }
        const modalKey = action.data?.key
        callback && callback()
        switch (action.eventName) {
          case 'open-global-modal':
            if (!isLogin && NEED_LOGIN_MODAL_KEYS.includes(modalKey)) {
              dispatch(openModal({ key: GlobalModalKey.Login }))
            } else {
              if (modalKey === 'LuckyWheel' && !wheelStatus) {
                dispatch(
                  openModal({
                    key: GlobalModalKey.Confirm,
                    data: { title: 'WHEEL_END', content: 'WHEEL_END_DESC' },
                  }),
                )
              } else if (!isBindCurrency && SHOW_BIND_SELECT) {
                dispatch(
                  setCallBackType({
                    callBackType: modalKey,
                  }),
                )
                dispatch(switchBindModal())
                return
              } else if (!isOpenActive) {
                dispatch(openModal({ key: GlobalModalKey.RegionLimit }))
              } else {
                const params = { key: modalKey }
                // 管理后台功能改进2.0
                newAdminHomePage &&
                  NEED_CLEAR_DATA_KEY.includes(modalKey) &&
                  Object.assign(params, {
                    data: {
                      isManu: true,
                    },
                  })
                dispatch(openModal(params))
              }
            }
            break
          case 'newRechargeActModal':
            dispatch(openModal({ key: GlobalModalKey.newRechargeActModal }))
            break
          // live_chat
          case 'toggle-live-chat':
            dispatch(toggleLiveChatWigetVisibility(action.data?.license))
            break
          case 'go-sports':
            break
          // 切换侧边导航
          case 'toggle-menu':
            dispatch(toggleDrawerStart(DrawerKey.Left))
            break
          case 'nav-to-home-game':
            navToHomeGame(action.data?.gameId)
            break
          // 回到首页
          case 'go-home':
            navigate('/')
            break
          // 打开客服列表
          case 'service-list':
            if (serviceListTgLink) {
              dispatch(
                openModal({
                  key: GlobalModalKey.ServiceList,
                }),
              )
            } else {
              dispatch(toggleLiveChatWigetVisibility())
            }
            break
          default:
            break
        }
      },
    [
      navigate,
      isLogin,
      dispatch,
      navToHomeGame,
      wheelStatus,
      isBindCurrency,
      SHOW_BIND_SELECT,
      isOpenActive,
    ],
  )
}
