const proseStyles = {
  baseStyle: {
    h1: {
      color: "gray.900",
      fontWeight: "800",
      fontSize: "1.25rem",
      lineHeight: "1.5",
      mt: "1.25rem",
      mb: "1.25rem",
    },
    h2: {
      color: "gray.800",
      fontWeight: "800",
      fontSize: "1rem",
      lineHeight: "1.5",
      mt: "1rem",
      mb: "1rem",
    },
    h3: {
      color: "gray.800",
      fontWeight: "600",
      fontSize: "0.875rem",
      lineHeight: "1.5",
      mt: "0.875rem",
      mb: "0.875rem",
    },
    h4: {
      color: "gray.800",
      fontWeight: "600",
      fontSize: "1rem",
      lineHeight: "1.5",
      mt: "1rem",
      mb: "1rem",
    },
    h5: {
      color: "seco.500",
      fontSize: "2.5rem",
      fontWeight: 800,
      lineHeight: 1,
      margin: "1.875rem 0 0.375rem",
    },
    h6: {
      color: "link",
      fontSize: "2.5rem",
      fontWeight: 800,
      lineHeight: 1,
      margin: "1.875rem 0 0.375rem",
    },
    p: {
      color: "gray.700",
      fontWeight: "600",
      fontSize: "0.875rem",
      lineHeight: "1.5",
      mb: "0.875rem",
    },
    ol: {
      color: "gray.800",
      fontWeight: "800",
      fontSize: "1.25rem",
      lineHeight: "1.5",
      mb: "1.25rem",
      textAlign: "center",
      padding: 0,
    },
    li: {
      color: "gray.700",
      fontWeight: "600",
      fontSize: "0.875rem",
      lineHeight: "1.5",
      mb: 0,
      mt: 0,
      ml: "2rem",
      listStyle: "none",
    },
    sub: {
      display: "block",
      color: "gray.600",
      fontWeight: "600",
      fontSize: "0.875rem",
      lineHeight: "1.5",
      mb: "0.875rem",
      ml: "70%",
    },
    a: {
      color: "link",
    },
  },
};
export default proseStyles;
