const Stat = {
    baseStyle: {
        container: {
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center"
        },
        helpText: {
            m: 0
        }
    },
    sizes: {
        none: {},
        md: {
            label: {
                fontSize: "12px",
                lineHeight: "16px",
                fontWeight: "600",
                mb: "1"
            },
            number: {
                fontSize: "16px",
                fontWeight: "800",
                lineHeight: "20px"
            },
            helpText: {}
        },
        lg: {
            label: {
                fontSize: "12px",
                lineHeight: "16px",
                fontWeight: "700"
            },
            number: {
                fontSize: "18px",
                lineHeight: "24px",
                fontWeight: "800"
            },
            helpText: {
                fontSize: "12px",
                lineHeight: "16px",
                fontWeight: "700"
            }
        },
        xl: {
            label: {
                fontSize: "22px",
                lineHeight: "27px",
                fontWeight: "800"
            },
            number: {
                fontSize: "30px",
                fontWeight: "800",
                lineHeight: "64px"
            },
            helpText: {
                fontSize: "12px",
                lineHeight: "16px",
                fontWeight: "600"
            }
        },
        xxl: {
            label: {
                textStyle: "h4"
            },
            number: {
                textStyle: "h2"
            },
            helpText: {
                textStyle: "text6"
            }
        }
    }
}

export default Stat;