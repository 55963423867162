const Progress = {
    baseStyle: {
        colorScheme: {
            gray: {
                filledTrack: {
                    bg: "white"
                },
                track: {
                    bg: "whiteAlpha.300"
                }
            },
            vip: {
                filledTrack: {
                    bg: ["white", "prim.500"]
                },
                track: {
                    bg: ["whiteAlpha.500", "gray.50"]
                }
            },
            "vip-level": {
                filledTrack: {
                    bg: ["white", "prim.500"]
                },
                track: {
                    bg: ["whiteAlpha.500", "gray.50"]
                }
            }
        }
    },
    variants: {
        round: {
            label: {},
            filledTrack: {
                borderRadius: "full",
                transition: "all .5s"
            },
            track: {
                borderRadius: "full"
            }
        }
    },
    sizes: {
        md: {
            track: {
                h: "2.5"
            }
        }
    },
    defaultProps: {
        variant: "round"
    }
}

export default Progress;