import type { BaseQueryFn } from '@reduxjs/toolkit/dist/query'
import { AxiosError, isAxiosError } from 'axios'

import { getApiBaseUrl} from '@/common/env'

import { filterPathError } from './filterPathError'
import type { RequestData, RequestMethod, RequestParams } from './request'
import request from './request'
import type { QueryError } from './types'

const DEFAULT_BASE_URL = getApiBaseUrl()

export const axiosBaseQuery =
  (
    { baseUrl = DEFAULT_BASE_URL }: { baseUrl: string } = {
      baseUrl: DEFAULT_BASE_URL,
    },
  ): BaseQueryFn<
    {
      url: string
      method: RequestMethod
      data?: RequestData
      params?: RequestParams['params']
      grecaptchaTokenStr?: string
      headers?: Record<string, any>
    },
    unknown,
    QueryError
  > =>
  async ({ url, method, data, params, grecaptchaTokenStr, headers }) => {
    try {
      // let grecaptchaToken = ''
      // if (grecaptchaTokenStr) {
      //   if (getVariable('NEW_GOOGLE_RECAPTCHA_SITE_KEY')) {
      //     // 新的google验证
      //     grecaptchaToken =
      //       params?.grecaptcha_token || data?.grecaptcha_token || ''
      //   } else {
      //     // 老得谷歌验证，后期需要删掉（删除掉不需要验证的接口）
      //     grecaptchaToken = await getGrecaptchaToken(grecaptchaTokenStr)
      //   }
      // }
      const result = await request({
        url,
        baseURL: baseUrl,
        method,
        data: {
          // ...(grecaptchaToken && { grecaptcha_token: grecaptchaToken }),
          ...data,
        },
        params: {
          // ...(grecaptchaToken && { grecaptcha_token: grecaptchaToken }),
          ...params,
        },
        headers: { ...headers },
      })

      if (
        result?.data.message === 'not login or login fail' ||
        (result?.data?.code !== 200 &&
          result?.data?.code !== 0 &&
          !['OK', 'SUCCESS', ''].includes(
            String(
              result?.data?.message || result?.data?.status,
            ).toLocaleUpperCase(),
          ))
      ) {
        if (filterPathError.includes(url)) {
          return { data: result.data }
        } else {
          throw new AxiosError(
            result?.data?.message || result?.data?.msg,
            result.data.code,
          )
        }
      } else {
        return { data: result.data }
      }
    } catch (error: unknown) {
      const result: { error: QueryError } = {
        error: { status: 0, message: '', data: null },
      }
      if (error instanceof Error) {
        result.error.message = error.message
        if (isAxiosError(error)) {
          const codeNumber = Number(error.code)
          if (!isNaN(codeNumber)) {
            result.error.status = codeNumber
          }
        }
      }
      return result
    }
  }

export default axiosBaseQuery
