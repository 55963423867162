const PinInput = {
    baseStyle: {},
    variants: {
        outline: {
            borderColor: "gray.300",
            border: "2px solid",
            fontSize: "14px",
            fontWeight: "bold",
            lineHeight: "18px",
            color: "gray.900",
            bg: "gray.50",
            _hover: {
                borderColor: "gray.500"
            },
            _disabled: {
                _hover: {
                    borderColor: "gray.300"
                },
                _placeholder: {
                    color: "gray.500"
                }
            },
            _placeholder: {
                fontSize: "14px",
                fontWeight: "semibold",
                color: "text.base",
                lineHeight: "16px",
                borderColor: "gray.400"
            },
            _focusVisible: {
                borderColor: "prim.500"
            }
        }
    }
}

export default PinInput;