const Popover = {
    baseStyle: {
        popper: {
            minWidth: "0px!important",
            px: 2,
            zIndex: "popover"
        },
        content: {
            maxWidth: "calc(100vw - 2 * var(--chakra-space-2))"
        }
    }
}

export default Popover;