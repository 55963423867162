const Table = {
  baseStyle: {
    table: {
      cursor: "default",
    },
    td: {
      textStyle: "h5",
      py: "2",
      px: "2",
      _first: {
        borderLeftRadius: "lg",
      },
      _last: {
        borderRightRadius: "lg",
      },
    },
    th: {
      fontFamily: "inherit",
    },
    colorScheme: {
      normal: {
        th: {
          "--th-bg": "var(--chakra-colors-gray-50)",
        },
      },
      dark: {
        th: {
          "--th-bg": "transparent",
          "--th-border-color": "var(--chakra-colors-gray-800)",
          "--text-color": "var(--chakra-colors-white)",
        },
        td: {
          "--td-bg": "transparent",
          "--td-border-color": "var(--chakra-colors-gray-800)",
          "--text-color": "var(--chakra-colors-white)",
        },
      },
    },
  },
  variants: {
    borderless: {
      th: {
        fontFamily: "inherit",
        textAlign: "center",
        color: "var(--text-color, var(--chakra-colors-prim-400))",
        bg: "var(--th-bg, var(--chakra-colors-gray-50))",
        fontWeight: "extrabold",
        textTransform: "capitalize",
        position: "relative",
        _notLast: {
          _after: {
            content: "''",
            position: "absolute",
            top: "50%",
            right: "0",
            transform: "translateY(-50%)",
            height: "1.5em",
            borderRight: "1px solid",
            borderColor:
              "var(--th-border-color, var(--chakra-colors-gray-600))",
          },
        },
      },
      td: {
        textAlign: "center",
        fontSize: "12px",
        bg: "var(--td-bg, var(--chakra-colors-gray-50))",
        color: "var(--text-color, var(--chakra-colors-text-accent))",
        lineHeight: "16px",
        fontWeight: "700",
      },
    },
    simple: {
      th: {
        fontFamily: "inherit",
        textAlign: "center",
        color: "prim.400",
        borderColor: "inherit",
        borderBottom: "0",
        textTransform: "capitalize",
        position: "relative",
        _notLast: {
          _after: {
            content: "''",
            position: "absolute",
            top: "50%",
            right: "0",
            transform: "translateY(-50%)",
            height: "1.5em",
            borderRight: "1px solid",
            borderColor: "gray.600",
          },
        },
      },
      td: {
        textStyle: "text4",
        textAlign: "center",
        border: "none",
        color: "text.accent",
      },
    },
    separate: {
      table: {
        borderCollapse: "separate",
        borderSpacing: "0 0.625rem",
      },
      td: {
        textStyle: "h6",
        py: "2",
        px: "2",
        _first: {
          borderLeftRadius: "lg",
        },
        _last: {
          borderRightRadius: "lg",
        },
      },
      th: {
        fontSize: "12px",
        lineHeight: "16px",
        fontWeight: "600",
        textTransform: "capitalize",
      },
    },
  },
  sizes: {
    md: {
      td: {
        textStyle: "text4",
        py: "2",
        px: "2",
      },
      th: {
        textStyle: "text6",
        py: "2",
        px: "2",
      },
      caption: {
        fontSize: "12px",
        lineHeight: "16px",
        fontWeight: "600",
      },
    },
  },
  defaultProps: {},
};

export default Table;
