import { io } from "socket.io-client";

import { getUserId, getUserToken } from "@/modules/user/storage";

import { getWssBaseUrl } from "./env";

export function getSocket(_url?: string, token?: string) {
  const flag = !!_url;
  if (typeof NOWS !== "undefined" && NOWS) {
    return makeSocketObj(null);
  }
  if (flag) {
    if ((window as any).new_gSocket) {
      return makeSocketObj((window as any).new_gSocket);
    }
  } else {
    if ((window as any).gSocket) {
      tickSocket((window as any).gSocket);
      return makeSocketObj((window as any).gSocket);
    }
  }
  //   const url = WS_URL || getURL('/', 'wss'); // WS_URL可以在全局变量中配置
  const url = flag ? _url : getWssBaseUrl(); // WS_URL可以在全局变量中配置
  //由于本地联调没有wss证书，需要替换ws
  //   const url = getURL('/', ONLINE ? 'wss' : 'ws'); // WS_URL可以在全局变量中配置
  console.log("getWssBaseUrl:", url);
  console.log("getWssBaseUrl: flag ", flag);
  console.log("getWssBaseUrl: token ", token);
  const authToken = getUserToken();
  const userId = getUserId();
  console.log({ authToken, userId });
  const opts = {
    path: "/game-service/socket.io",
    ...(authToken && userId
      ? {
          auth: { token: getUserToken(), userId: getUserId() },
          extraHeaders: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      : undefined),
  };
  const socket = flag
    ? io(url, opts)
    : io(url, {
        secure: true,
        ...opts,
      });

  socket.on("connect", () => {
    const engine = socket.io.engine;
    console.log("engineengineengineengine:", engine.transport.name);
    engine.once("upgrade", () => {
      // called when the transport is upgraded (i.e. from HTTP long-polling to WebSocket)
      tickSocket(socket);
    });

    // called for each packet received
    engine.on("packet", ({ type, data }) => {
      // console.log('Packet', type, data)
    });

    engine.on("packetCreate", ({ type, data }) => {
      // called for each packet sent
    });

    engine.on("drain", () => {
      // called when the write buffer is drained
    });

    engine.on("close", (reason) => {
      // called when the underlying connection is closed
    });
  });

  socket.io.on("reconnect_attempt", () => {
    // ...
  });

  socket.io.on("reconnect", () => {
    (socket as any).roomId && joinRoom((socket as any).roomId);
    // ...
  });

  socket.on("error", (err) => {
    // ...
  });

  socket.on("connect_error", () => {
    // ...
  });

  socket.on("disconnect", (reason) => {
    (socket as any).isLogin = false;
  });

  if (flag) {
    (window as any).new_gSocket = socket;
  } else {
    (window as any).gSocket = socket;
  }

  tickSocket(socket);
  return makeSocketObj(socket);
}

export async function leaveRoom(roomId?: string) {
  const { socket } = getSocket();

  if (socket.roomId) {
    socket.emit("leave", socket.roomId, ({ code }: { code: number }) => {
      // console.log('leave code', code);
      if (code === 200) socket.roomId = null;
    });
  }
}

export async function joinRoom(roomId: string) {
  const { socket } = getSocket();
  function listenSocketFinish() {
    return new Promise((resolve, reject) => {
      let raf: null | number = null;
      if (!socket.connected) {
        raf = requestAnimationFrame(async () => {
          const res = await listenSocketFinish();
          // console.log('=============== listenSocketFinish =================');

          if (res) resolve(res);
        });
      } else {
        if (typeof raf === "number") {
          cancelAnimationFrame(raf);
        }
        resolve(true);
      }
    });
  }
  await listenSocketFinish();

  if (socket.timer) clearTimeout(socket.timer);
  if (socket) {
    socket.emit("joinRoom", roomId, ({ code }: { code: number }) => {
      // console.log('join room:' + code);
      if (code !== 200) {
        socket.timer = setTimeout(() => joinRoom(roomId), 3000);
      } else {
        socket.roomId = roomId;
      }
    });
  }
}

function makeSocketObj(socket: any) {
  return {
    connected: !!socket,
    socket,
    listen: listen.bind(socket),
    unlisten: unlisten.bind(socket),
  };
}

function listen(id: string, event: string, callback: (...res: any) => void) {
  if (!(window as any).listenMap) {
    (window as any).listenMap = {};
  }
  const listenMap = (window as any).listenMap;

  const key = `${id}:${event}`;
  if (listenMap[key]) {
    unlisten.bind(this)(id, event);
  }
  listenMap[key] = callback;
  if (this) {
    this.on(event, callback);
  }
}

function unlisten(id: string, event: string) {
  const listenMap = (window as any).listenMap;
  const key = `${id}:${event}`;
  delete listenMap[key];
  if (this) {
    this.off(event);
  }
}

function tickSocket(socket: any) {
  if (
    !socket.isLogin &&
    !socket.isLogging &&
    getUserToken() !== "null" &&
    getUserId() !== "null" &&
    getUserToken() !== "undefined" &&
    getUserToken() !== "getUserId" &&
    getUserToken() &&
    getUserId()
  ) {
    socket.isLogging = true;
    socket.emit(
      "login",
      { token: getUserToken(), userId: getUserId() },
      (success: any) => {
        console.log("login succsee :", success);
        socket.isLogin = success;
        socket.isLogging = false;
      }
    );
  }
}
