import axios from "axios";

import { isFeatureEnabled } from "@/common/feature";
import { api } from "@/services/api";
import type { Response } from "@/services/types";
import atomGtag from "@/utils/gtag/atom-gtag";
import {
  getDeviceType,
  getN1ServiceBuryData,
  getUrlParam,
} from "@/utils/tools";

import type { AccountBindInfo, GoogleUserInfo, User } from "./types";

/** 是否开启了转盘活动 */
const isEnableLuckyWheel = isFeatureEnabled("luckyWheel");

export const DEVICE_TYPE = {
  apk: "android_app",
  "android-desktop-icon": "android_desktop",
  pc: "pc",
  android: "android_web",
  "ios-desktop-icon": "ios_desktop",
  ios: "ios_web",
  ios_app: "ios_app",
}[getDeviceType()];

export interface LoginArgs {
  account: string;
  password: string;
  loginType?: "google" | "facebook";
}

export interface AuthQueryArgs {
  token: string;
  user_id: string;
}

// 获取谷歌账户的用户信息
export const getGoogleUserInfo = async (accessToken: string) => {
  const rawResponse = await axios.get<GoogleUserInfo>(
    "https://www.googleapis.com/oauth2/v2/userinfo",
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  );
  return rawResponse.data;
};

export type LoginResponse = Response<{ token: string; user: User }>;

export const userApi = api.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<LoginResponse, LoginArgs>({
      query: (data) => ({
        url: "/user/login",
        method: "POST",
        data,
        grecaptchaTokenStr: "login",
        headers: {
          AccountType: data?.loginType || "email",
          DeviceType: DEVICE_TYPE,
        },
      }),
      invalidatesTags: ["Auth", "Login"],
    }),
    signUp: builder.mutation<
      Response<User>,
      LoginArgs & { person?: string; code?: string }
    >({
      query: (data) => {
        const actualData: Record<string, any> = {
          ...data,
          ...getN1ServiceBuryData(),
        };

        if (isEnableLuckyWheel) {
          const fromActivity = getUrlParam("fromActivity");
          if (fromActivity && fromActivity === "luckyWheel") {
            actualData["from"] = "act_raffle";
          }
        }
        if (!actualData.person) delete actualData.person;
        try {
          /**
           * GA 默认推荐事件
           * https://developers.google.com/analytics/devguides/collection/ga4/reference/events?sjid=3090215773354844901-EU&hl=zh-cn&client_type=gtag#sign_up
           */
          atomGtag("event", "sign_up");
        } catch (error) {}
        return {
          url: "/user/register",
          method: "POST",
          data: actualData,
          grecaptchaTokenStr: "register",
        };
      },
    }),
    resetPasswordByPassword: builder.mutation<
      Response<unknown>,
      LoginArgs & { code: string }
    >({
      query: (data) => ({
        url: "/user/reset_password",
        method: "POST",
        data,
        grecaptchaTokenStr: "reset_password",
      }),
    }),
    sentResetPasswordEmail: builder.mutation<
      Response<unknown>,
      { account: string }
    >({
      query: (data) => ({
        url: "/user/reset_password_email",
        method: "POST",
        data,
        grecaptchaTokenStr: "reset_password_email",
      }),
    }),
    /** 绑定facebook账号 */
    bindFacebookAccout: builder.mutation<
      LoginResponse,
      { token: string; user_id: string; authorization_code: string }
    >({
      query: (data) => ({
        url: "/v2/user/bind_facebook",
        method: "POST",
        data,
      }),
      invalidatesTags: ["BindInfo"],
    }),
    /** facebook登录 */
    loginByFacebookAccout: builder.mutation<
      LoginResponse,
      { authorization_code: string }
    >({
      query: (data) => ({
        url: "/v2/user/login_facebook",
        method: "POST",
        data,
        grecaptchaTokenStr: "login",
        headers: {
          AccountType: "facebook",
          DeviceType: DEVICE_TYPE,
        },
      }),
      invalidatesTags: ["Auth", "Login"],
    }),
    /** 绑定google账号 */
    bindGoogleAccout: builder.mutation<
      LoginResponse,
      {
        token: string;
        user_id: string;
        authorization_code?: string;
        user_info?: any;
        redirect_uri?: string;
      }
    >({
      query: (data) => ({
        url: "/v2/user/bind_google",
        method: "POST",
        data,
      }),
      invalidatesTags: ["BindInfo"],
    }),
    /** google登录 */
    loginByGoogleAccout: builder.mutation<
      LoginResponse,
      { userInfo: GoogleUserInfo }
    >({
      query: ({ userInfo }) => ({
        url: "/v2/user/login_google",
        method: "POST",
        data: {
          user_info: userInfo,
          email: userInfo.email,
        },
        grecaptchaTokenStr: "login",
        headers: {
          AccountType: "google",
          DeviceType: DEVICE_TYPE,
        },
      }),
      invalidatesTags: ["Auth", "Login"],
    }),
    loginByGoogleAuthCode: builder.mutation<
      LoginResponse,
      { authorization_code: string; redirect_uri?: string }
    >({
      query: ({ authorization_code, redirect_uri }) => {
        const actualData: Record<string, any> = {
          authorization_code,
          redirect_uri: redirect_uri || "",
        };
        if (isEnableLuckyWheel) {
          const fromActivity = getUrlParam("fromActivity");
          if (fromActivity && fromActivity === "luckyWheel") {
            actualData["from"] = "act_raffle";
          }
        }
        return {
          url: "/v2/user/login_google",
          method: "POST",
          data: actualData,
          grecaptchaTokenStr: "login",
          headers: {
            AccountType: "google",
            DeviceType: DEVICE_TYPE,
          },
        };
      },
      invalidatesTags: ["Auth", "Login"],
    }),
    loginByFacebookAuthCode: builder.mutation<
      LoginResponse,
      { authorization_code: string }
    >({
      query: ({ authorization_code }) => {
        const actualData: Record<string, any> = { authorization_code };
        if (isEnableLuckyWheel) {
          const fromActivity = getUrlParam("fromActivity");
          if (fromActivity && fromActivity === "luckyWheel") {
            actualData["from"] = "act_raffle";
          }
        }

        return {
          url: "/v2/user/login_facebook",
          method: "POST",
          data: actualData,
          grecaptchaTokenStr: "login",
          headers: {
            AccountType: "facebook",
            DeviceType: DEVICE_TYPE,
          },
        };
      },
      invalidatesTags: ["Auth", "Login"],
    }),
    /** 更新用户头像 */
    setUserAvatar: builder.mutation<any, { avatar: string } & AuthQueryArgs>({
      query: (data) => {
        return {
          url: "/user/update_avatar",
          method: "POST",
          data,
        };
      },
      invalidatesTags: ["Auth"],
    }),
    /** 获取绑定信息 */
    getBindInfo: builder.query<Response<AccountBindInfo>, AuthQueryArgs>({
      query: (data) => {
        return {
          url: "/v2/user/bind_info",
          method: "POST",
          data,
        };
      },
      providesTags: ["Auth", "BindInfo"],
    }),
    getUserInfo: builder.query<Response<User>, AuthQueryArgs>({
      query: (data) => {
        return {
          url: "/financial-service/balance/my",
          method: "GET",
          data,
        };
      },
      providesTags: ["UserInfo", "Auth"],
    }),
    rechargeTracker: builder.query<
      Response<{ events: any }>,
      { token: string; user_id: string }
    >({
      query: ({ token, user_id }) => {
        return {
          url: "/user/event",
          method: "POST",
          data: {
            token,
            user_id,
          },
        };
      },
      providesTags: ["Auth"],
    }),
    checkEmailExist: builder.query<unknown, { account: string }>({
      query: (params) => ({
        url: "/v2/user/account_check",
        method: "post",
        data: params,
      }),
    }),
    // 登录保活
    loginLog: builder.mutation<
      LoginResponse,
      { token: string; user_id: string }
    >({
      query: (data) => ({
        url: "/api/v1/platform/asset_order/behavior/login_log",
        method: "POST",
        data,
        headers: {
          AccountType: "email",
          DeviceType: DEVICE_TYPE,
        },
      }),
      invalidatesTags: ["Auth", "UserInfo"],
    }),
    // 获取手机验证码
    getPhoneVerifyCode: builder.mutation<
      Response<unknown>,
      {
        area_code: string;
        phone: string;
        code_type?: "resign" | "login" | undefined;
      }
    >({
      query: (data) => ({
        url: "/v2/user/register_phone_code",
        method: "POST",
        data,
      }),
    }),
    // 手机验证码登录
    loginByPhoneVerifyCode: builder.mutation<
      LoginResponse,
      { code: string; phone_number: string }
    >({
      query: (data) => ({
        url: "/v2/user/phone_code_login",
        method: "POST",
        data,
        headers: {
          AccountType: "phone",
          DeviceType: DEVICE_TYPE,
        },
      }),
      invalidatesTags: ["Auth", "Login"],
    }),
    // 手机注册
    phoneRegister: builder.mutation<
      Response<User>,
      { code: string; phone: string; password: string; person: string }
    >({
      query: (data) => {
        const actualData: Record<string, any> = { ...data };
        if (isEnableLuckyWheel) {
          const fromActivity = getUrlParam("fromActivity");
          if (fromActivity && fromActivity === "luckyWheel") {
            actualData["from"] = "act_raffle";
          }
        }

        return {
          url: "/v2/user/phone_register",
          method: "POST",
          data: actualData,
        };
      },
    }),
    // 获取邮箱验证码
    getEmailVerifyCode: builder.mutation<Response<unknown>, { email: string }>({
      query: (data) => ({
        url: "/v2/user/send_register_email",
        method: "POST",
        data,
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useSignUpMutation,
  useResetPasswordByPasswordMutation,
  useSentResetPasswordEmailMutation,
  useBindFacebookAccoutMutation,
  useBindGoogleAccoutMutation,
  useLoginByFacebookAccoutMutation,
  useLoginByGoogleAccoutMutation,
  useGetBindInfoQuery,
  useGetUserInfoQuery,
  useSetUserAvatarMutation,
  useLazyRechargeTrackerQuery,
  useLazyCheckEmailExistQuery,
  useLazyGetUserInfoQuery,
  useLoginLogMutation,
  useLoginByGoogleAuthCodeMutation,
  useLoginByFacebookAuthCodeMutation,
  useGetPhoneVerifyCodeMutation,
  useLoginByPhoneVerifyCodeMutation,
  usePhoneRegisterMutation,
  useGetEmailVerifyCodeMutation,
} = userApi;
