const Notification = {
    parts: ["root", "title", "itemContainer"],
    baseStyle: {
        itemConainer: {
            "--heading-color": "var(--chakra-colors-text-accent)",
            "--content-color": "var(--chakra-colors-text-base)",
            "--item-bg": "var(--chakra-colors-gray-500)"
        }
    }
}

export default Notification;