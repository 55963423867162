

import '@/modules/app/prelude'

import ReactDOM from 'react-dom/client'

import App from '@/modules/app/App'

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <App />,
)
