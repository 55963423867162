const H24HoursRank = {
  parts: ["item", "binWin", "binWinText", "amount"],
  baseStyle: {
    item: {
      borderRadius: 8,
      _after: {
        content: "''",
        position: "absolute",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        borderRadius: "8px",
        backgroundSize: "100% 100%",
      },
    },
    binWin: {
      marginLeft: "5px",
    },
    amount: {
      position: "relative",
      width: "100%",
      height: "28px",
      background: "linear-gradient(180deg, #A76F1A 0%, #AE760D 100%)",
      justifyContent: "center",
      p: {
        fontStyle: "normal",
        background: "linear-gradient(180deg, #FFF 0%, #FFE271 100%)",
        bgClip: "text",
      },
    },
  },
  defaultProps: {},
};

export default H24HoursRank;
