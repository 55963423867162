import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import {
  getConfigBaseUrl,
  getPageConfigBaseUrl,
  getVariable,
} from '@/common/env'
import type { HomePageConfig } from '@/modules/app/home-page-config-types'

import getHashUrl from '@/utils/get-hash-url'

const MODAL_RESOURCE_SIGN = getVariable('MODAL_RESOURCE_SIGN')
const HOME_PAGE_CONFIG = getVariable('HOME_PAGE_CONFIG')

const jsonQuery = fetchBaseQuery({
  baseUrl: getConfigBaseUrl(),
})

export const jsonApi = createApi({
  reducerPath: 'json',
  baseQuery: jsonQuery,
  endpoints: builder => ({
    getBounsAndPerson: builder.query<
      { base_person: number; base_bonus: number },
      void
    >({
      query: () => {
        return {
          url: getHashUrl('/public/number.json'),
          method: 'GET',
        }
      },
    }),
   
  }),
})

export const { useGetBounsAndPersonQuery } = jsonApi

const pageConfigJsonQuery = fetchBaseQuery({
  baseUrl: getPageConfigBaseUrl(),
})

export const pageConfigApi = createApi({
  reducerPath: 'pageConfigJson',
  baseQuery: pageConfigJsonQuery,
  endpoints: builder => ({
    getHomePageConfig: builder.query<HomePageConfig, void>({
      query: () => {
        return {
          url: getHashUrl(
            `/pageconfig/homePageConfig_${HOME_PAGE_CONFIG}.json`,
          ),
          method: 'GET',
        }
      },
    }),
  }),
})

export const {  useGetHomePageConfigQuery } = pageConfigApi
