import type { PayloadAction } from '@reduxjs/toolkit'
import { createSelector, createSlice } from '@reduxjs/toolkit'

import { isFeatureEnabled } from '@/common/feature'
import i18n from '@/common/locale/i18n'
import { jsonApi } from '@/services/jsonApi'

import type { RootState } from '../app/store'
import type {
  CategoryLabelListItem,
  CategoryLabelMap,
  GameFile,
  GameListItem,
  ManulacturerListItem,
} from './gameInterface'
import getGameData from './performance-utils/getGameData'
import getSortedCate from './performance-utils/getSortedCate'
import getSortedList from './performance-utils/getSortedList'
import getSortedManu from './performance-utils/getSortedManu'

interface GameSlice {
  showTips: boolean
  tipsText: string
  // ****** 优化
  manus: ManulacturerListItem[]
  allManus: ManulacturerListItem[]
  games: GameListItem[]
  GMPGames: GameListItem[]
  selfGames: GameListItem[]
  catesKeyByLabelId: Record<string | number, CategoryLabelListItem>
  catesKeybyLabelType: Record<string | number, CategoryLabelListItem[]>
  manusKeybyId: Record<string | number, ManulacturerListItem>
  // ******* 优化
}

/**
 * 几种路由跳转方式
 */
export const labelType: CategoryLabelMap = {
  jili: {
    baseUrl: '/game/jili',
    type: 'jili',
    supportCurrency: ['ALL'],
  },
  facai: {
    baseUrl: '/game/facai',
    type: 'facai',
    supportCurrency: ['ALL'],
  },
  slotegrator: {
    baseUrl: '/game/slot',
    type: 'slot',
    supportCurrency: ['ALL'],
  },
  evoplay: {
    baseUrl: '/game/slot',
    type: 'evoplay',
    supportCurrency: ['ALL'],
  },
  hillingo: {
    baseUrl: '/game/self',
    type: 'inhouse',
    supportCurrency: ['ALL'],
  },

  inhouse: {
    baseUrl: '/game/inhouse',
    type: 'inhouse',
    supportCurrency: ['ALL'],
  },
  live_casino: {
    baseUrl: '/game/live',
    type: 'live',
    supportCurrency: ['ALL'],
  },
  pgsoft: {
    baseUrl: '/game/pgsoft',
    type: 'pgsoft',
    supportCurrency: ['ALL'],
  },
  fundist: {
    baseUrl: '/game/fundist',
    type: 'fundist',
    supportCurrency: ['ALL'],
  },
}

export const FAVORITE_LABEL_ID = 999

/**
 * 整理游戏文件数据
 * @param data
 * @returns
 */
function formatGameData(data: GameFile) {
  const { game_list, manulacturer_list, category_label_list } = data
  /** 优化 */
  const collectionCate: CategoryLabelListItem = {
    label_type: 2,
    label_id: FAVORITE_LABEL_ID,
    label_name: i18n.t('FAVORITE_GAME'),
    icon: '/assets/images/favorite-icon.png',
    is_enabled: 1,
    display_games: 1,
    sort_weight:
      (category_label_list.find(item => item.label_id === 6)?.sort_weight ??
        0) + 1,
    game_sort: [],
    data: [],
  }

  const { manusKeybyId, list: manus } = getSortedManu(manulacturer_list)
  const cates = isFeatureEnabled('gameCollection')
    ? [collectionCate, ...category_label_list]
    : category_label_list
  const { catesKeyByLabelId, catesKeybyLabelType } = getSortedCate(
    cates,
    game_list,
  )
  const { games, GMPGames, selfGames } = getSortedList(game_list)
  getGameData({
    manusKeybyId,
    catesKeyByLabelId,
    catesKeybyLabelType,
    games,
  })

  const endManus = manus.filter(manu => manu.data.length > 0)
  /** 优化 */

  return {
    allManus: manulacturer_list,
    manus: endManus,
    games: games,
    GMPGames: GMPGames,
    selfGames: selfGames,
    manusKeybyId,
    catesKeyByLabelId,
    catesKeybyLabelType,
  }
}

const initialState: GameSlice = {
  games: [],
  GMPGames: [], // GMP游戏中台游戏列表
  selfGames: [], // 平台的自营游戏
  manus: [],
  allManus: [],
  catesKeyByLabelId: {},
  catesKeybyLabelType: {},
  manusKeybyId: {},
  showTips: false,
  tipsText: '',
}
export const gameSlice = createSlice({
  name: 'gameList',
  initialState,
  reducers: {
    switchShowTips: (state, action) => {
      if (!state.showTips) {
        state.tipsText = action.payload || ''
      }
      state.showTips = !state.showTips
    },
  },
  extraReducers: _ => {
    // builder.addMatcher(
    //   jsonApi.endpoints.getGameList.matchFulfilled,
    //   (state, action: PayloadAction<any>) => {
    //     const { payload } = action
    //     const {
    //       games,
    //       GMPGames,
    //       selfGames,
    //       catesKeyByLabelId,
    //       catesKeybyLabelType,
    //       manusKeybyId,
    //       manus,
    //       allManus,
    //     } = formatGameData(payload)
    //     state.manus = manus
    //     state.games = games
    //     state.catesKeyByLabelId = catesKeyByLabelId
    //     state.catesKeybyLabelType = catesKeybyLabelType
    //     state.manusKeybyId = manusKeybyId
    //     state.GMPGames = GMPGames
    //     state.selfGames = selfGames
    //     state.allManus = allManus
    //   },
    // )
  },
})

export const { switchShowTips } = gameSlice.actions

export const gameReducer = gameSlice.reducer
export const selectGame = (state: RootState) => state.gameModal
export const getShowTips = (state: RootState) => selectGame(state).showTips

export const getTipsText = (state: RootState) => selectGame(state).tipsText

export const selectGameList = (state: RootState) => selectGame(state).games

export const selectAllGame = createSelector(
  selectGame,
  gameState => gameState.games,
)

// 所有GMP游戏（游戏中台的游戏）
export const selectAllGMPGames = createSelector(
  selectGame,
  gameState => gameState.GMPGames,
)

// 所有平台自营游戏
export const selectAllSelfGames = createSelector(
  selectGame,
  gameState => gameState.selfGames,
)

export const selecSelfGamesUuidToIdMap = createSelector(
  selectAllSelfGames,
  gameList => {
    return gameList.reduce((acc, cur) => {
      if (cur.expand?.game_uuid && cur.game_id) {
        acc[cur.expand.game_uuid] = cur.game_id
      }
      return acc
    }, {} as Record<string, string | number>)
  },
)

export const selectAllGameIncludeSelf = createSelector(
  selectAllGame,
  selectAllSelfGames,
  (games, selfGames) =>
    selfGames
      .map((game): GameListItem => ({ ...game, game_id: game.game_name }))
      .concat(games),
)

// 所有的标签、分类列表
export const selectAllCateByLableType = (state: RootState) =>
  selectGame(state).catesKeybyLabelType

export const selectAllCateByLableId = createSelector(
  selectGame,
  gameState => gameState.catesKeyByLabelId,
)

// 所有的厂商列表
export const selectManuObj = createSelector(
  selectGame,
  gamesState => gamesState.manusKeybyId,
)

export const selectManuList = createSelector(
  selectGame,
  gamesState => gamesState.manus,
)

// 所有的厂商列表
export const selectAllManuList = createSelector(
  selectGame,
  gamesState => gamesState.allManus,
)
