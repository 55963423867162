import { useMediaQuery } from '@chakra-ui/react'

export const MAX_MOBILE_WIDTH = 768

export function useLargerThanMobile(): boolean {
  const [isLargerThanMobile] = useMediaQuery(
    `(min-width: ${MAX_MOBILE_WIDTH}px)`,
    { ssr: false },
  )
  return isLargerThanMobile
}
