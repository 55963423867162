import { getVariable } from '@/common/env'
import { getCurrencyCodeAsync } from '@/common/locale/currency'
import {
  setSupportedCurrencies,
  supportedCurrencies,
} from '@/common/locale/currency-const'
import i18n from '@/common/locale/i18n'
import store from '@/modules/app/store'
import {  pageConfigApi } from '@/services/jsonApi'
import { isFromAd } from '@/utils/tools'

import { currentInfoLoader } from '../bind-currency/loader'
import { loadInhouseConfig } from '../inhouse/inhouse-config'
import {
  changeCurrencySign,
  changeOriginalCurrencySign,
} from '../user/userSlice'
import { initDynamicI18n } from './initI18n'

export interface ResourcePromise<T = any> {
  p: Promise<T>
  isAsync?: boolean
}

// export async function gameListLoader() {
//   await store.dispatch(jsonApi.endpoints.getGameList.initiate())
// }

export async function homePageConfigLoader() {
  await store.dispatch(pageConfigApi.endpoints.getHomePageConfig.initiate())
}

export async function loadCurrencyWithCoinSign() {
  const currentCode = await getCurrencyCodeAsync()
  const currentConfig = supportedCurrencies[currentCode]
  // 这里是存一份到store中，因为会有充值之后，更新显示的操作，如果只存到supportedCurrencies中，会导致充值之后，显示的货币符号不会更新
  store.dispatch(changeCurrencySign('\u{e603}'))
  store.dispatch(changeOriginalCurrencySign(currentConfig.sign))
  setSupportedCurrencies({
    currency: currentCode,
    sign: '\u{e603}',
    originalSign: currentConfig.sign,
  })
}

/** 初始加载资源清单 */
export function initLoad() {
  const list: Promise<void>[] = []
  const SHOW_BIND_SELECT = getVariable('SHOW_BIND_SELECT')
  SHOW_BIND_SELECT && list.push(currentInfoLoader())
  list.push(i18n.loadNamespaces(['common', 'help']))
  list.push(loadInhouseConfig())
  isFromAd() && list.push(loadCurrencyWithCoinSign())
  return list
}

// 游戏资源、VIP、运营配置文件、i18n等语言文件
export function afterLoad() {
  initDynamicI18n()
}
