import type { TDate } from 'ahooks/lib/useCountDown'


import type { ActTipsProps, RechargePackageProps } from '../activity/type'
import type { DiversionItemProps } from '../app/home-page-config-types'
import type { LoginModalTab } from '../login/LoginAndSignUp'
import type { WalletModalTab } from '../wallet/walletSlice'

export enum GlobalModalKey {
  Login = 'Login',
  ResetPassword = 'ResetPassword',
  Coupon = 'Coupon',
  LuckyWheel = 'LuckyWheel',
  LoginReward = 'LoginReward',
  RewardSuccess = 'RewardSuccess',
  RegionLimit = 'RegionLimit',
  Wallet = 'Wallet',
  Confirm = 'Confirm',
  Announce = 'Announce',
  RedeemResult = 'RedeemResult',
  TgModal = 'TgModal',
  SplashAds = 'SplashAds',
  RechargeActivity = 'RechargeActivity',
  RechargeGuide = 'RechargeGuide',
  AppDownloadDiversion = 'AppDownloadDiversion',
  IntroductionToDownloadModal = 'IntroductionToDownloadModal',
  AddToHomeScreen = 'AddToHomeScreen',
  RechargePackage = 'RechargePackage',
  Loading = 'Loading',
  WrongPassword = 'WrongPassword',
  FavoriteLimit = 'FavoriteLimit',
  ServiceList = 'ServiceList', // 客服列表
  IOSSecurityAwareness = 'IOSSecurityAwarenessModal',
  QrCode = 'QrCode',
  // 关于新的充值活动****
  newRechargeActModal = 'newRechargeActModal',
  ActTips = 'ActTips',
  // 关于新的充值活动****
  // 转盘活动
  LuckyWheelActivity = 'LuckyWheelActivity',
  /**
   * 分享弹窗
   */
  SharesModal = 'SharesModal',
  LoginAndSignUpConfigError = 'LoginAndSignUpConfigError',
  MPesaModal = 'MPesaModal',
  /**
   * 新版绑定信息弹窗
   */
  BindInfoModal = 'BindInfoModal',
  Search = 'Search',
  // google re_captcha弹窗
  GoogleRecpatcha = 'GoogleRecpatcha',
  /**
   * 现金雨
   */
  MoneyRain = 'MoneyRain',
  /**
   * Gcash支付弹窗
   */
  GcashPaymentModal = 'GcashPaymentModal',
}

export interface ModalState<D = unknown> {
  open: boolean
  data?: D
}

type IntroductionToDownloadModalType =
  | ({ modalCfg: { title: string; content: string; reward?: string } } & {
      firstLoginRewardInfo?: never
    })
  | ({ modalCfg?: never } & {
      firstLoginRewardInfo: {
        reward: number
        endTime?: TDate
        biz_id?: string
        is_require_recharge?: boolean
        user_is_recharge?: boolean
        open_platform?: string[]
      }
    })

export type LoginModalState = ModalState<{
  index: LoginModalTab
  tab?: string
  defaultInput?: string
  isBackToHomeOnClosed?: boolean
}>

export type LoginModalData = LoginModalState['data']

export type ModalStateMap = Record<GlobalModalKey, ModalState> & {
  modalFormation: (keyof typeof GlobalModalKey)[]
  [modalId: string]: any
  OrderedModals: GlobalModalKey[]
  hiding: {
    [overlayId: string]: boolean
  }
} & {
  [GlobalModalKey.Wallet]: ModalState<{
    defaultActivedTabKey?: WalletModalTab
    extraWithdrawOptions?: unknown
    from?: string
  }>
} &  {
  [GlobalModalKey.AppDownloadDiversion]: ModalState<{
    modalCfg?: DiversionItemProps
  }>
} & {
  [GlobalModalKey.IntroductionToDownloadModal]: ModalState<IntroductionToDownloadModalType>
} & {
  [GlobalModalKey.AddToHomeScreen]: ModalState<{
    position?: 'other' | 'float-center-button'
  }> & {
    [GlobalModalKey.Login]: LoginModalState
  }
} & {
  [GlobalModalKey.RechargePackage]: ModalState<{
    rechargePackageInfo: RechargePackageProps
  }>
} & {
  [GlobalModalKey.ActTips]: ModalState<ActTipsProps>
  [GlobalModalKey.QrCode]: ModalState<{
    title: string
    desc: string
    imageUrl?: string
  }>
  [GlobalModalKey.MPesaModal]: ModalState<{
    rechargeParams: {
      rechargeAmount: number
      taskId?: string
      taskGift?: number
    }
  }>
  [GlobalModalKey.SharesModal]: ModalState<
    Record<
      'email' | 'facebook' | 'whatsapp' | 'telegram' | 'twitter',
      string
    > & {
      scene: 'landingPage' | 'successWithDrawModal'
      amount?: number
    }
  >
  [GlobalModalKey.MoneyRain]: ModalState<{
    modalType: 'rules' | 'game'
    isOpen: boolean
  }>
  [GlobalModalKey.LuckyWheelActivity]: ModalState<{ isOpen?: boolean }>
  [GlobalModalKey.BindInfoModal]: ModalState<{
    type: 'email' | 'phone' | 'reEmail' | 'rePhone'
    verifyType?: any
    source?: 'withdraw'
  }>
  [GlobalModalKey.GcashPaymentModal]: ModalState<{
    orderId: string
    cashier: string
  }>
}

export interface ModalContextValue {
  state: ModalStateMap
  open(key: GlobalModalKey, modalData: unknown): void
  close(key: GlobalModalKey): void
  toggle(key: GlobalModalKey, modalData: unknown): void
}
