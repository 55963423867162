const colors = {
  whiteAlpha: {
    50: "rgba(255, 255, 255, 0.01)",
    100: "rgba(255, 255, 255, 0.06)",
    200: "rgba(255, 255, 255, 0.10)",
    300: "rgba(255, 255, 255, 0.15)",
    400: "rgba(255, 255, 255, 0.24)",
    500: "rgba(255, 255, 255, 0.35)",
    600: "rgba(255, 255, 255, 0.48)",
    700: "rgba(255, 255, 255, 0.64)",
    800: "rgba(255, 255, 255, 0.80)",
    900: "rgba(255, 255, 255, 0.92)",
  },
  blackAlpha: {
    50: "rgba(0, 0, 0, 0.01)",
    100: "rgba(0, 0, 0, 0.06)",
    200: "rgba(0, 0, 0, 0.10)",
    300: "rgba(0, 0, 0, 0.15)",
    400: "rgba(0, 0, 0, 0.24)",
    500: "rgba(0, 0, 0, 0.35)",
    600: "rgba(0, 0, 0, 0.48)",
    700: "rgba(0, 0, 0, 0.64)",
    800: "rgba(0, 0, 0, 0.80)",
    900: "rgba(0, 0, 0, 0.92)",
  },
  prim: {
    50: "#fffcf0",
    100: "#fff5d1",
    200: "#f2dea0",
    300: "#FFDF80",
    400: "#FFC93D",
    500: "#E9AE15",
    600: "#DF9B38",
    700: "#804f0a",
    800: "#593203",
    900: "#331b01",
  },
  seco: {
    50: "#fff2f0",
    100: "#ffcec7",
    200: "#ffa89e",
    300: "#FD685E",
    400: "#FF3A34",
    500: "#FF1111",
    600: "#D11319",
    700: "#ab0712",
    800: "#85000d",
    900: "#5e000d",
  },
  tert: {
    50: "#FFFBF0",
    100: "#FFF0C9",
    200: "#FFE1A1",
    300: "#ffd078",
    400: "#ffbc4f",
    500: "#FCA327",
    600: "#D67F15",
    700: "#b05f09",
    800: "#8a4300",
    900: "#632d00",
  },
  four: {
    50: "#fdfff0",
    100: "#fcffd1",
    200: "#fcffa8",
    300: "#ffff80",
    400: "#FFF957",
    500: "#F9EB2D",
    600: "#D4C11C",
    700: "#AD980E",
    800: "#877104",
    900: "#614E02",
  },
  gray: {
    50: "#000000",
    100: "#0D121B",
    200: "#111922",
    300: "#141C26",
    400: "#1C2433",
    500: "#222C3D",
    600: "#3F526E",
    700: "#55657E",
    800: "#ADB6C4",
    900: "#ffffff",
    1e3: "#D2D6DB",
  },
  red: {
    50: "#FFF2F0",
    100: "#FFDED9",
    200: "#FFB8B0",
    300: "#FF8F87",
    400: "#FF645E",
    500: "#F43434",
    600: "#CF2127",
    700: "#A8131C",
    800: "#820814",
    900: "#5C0510",
  },
  orange: {
    50: "#FFFBF0",
    100: "#FFF5E0",
    200: "#FFE6B8",
    300: "#FFD48F",
    400: "#FFBF66",
    500: "#F9A43D",
    600: "#D48128",
    700: "#AD6018",
    800: "#87440C",
    900: "#612D08",
  },
  blue: {
    50: "#F0F9FF",
    100: "#C9EAFF",
    200: "#A1D6FF",
    300: "#78C0FF",
    400: "#4FA7FF",
    500: "#2382F1",
    600: "#1464CC",
    700: "#0847A6",
    800: "#002F80",
    900: "#001E59",
  },
  green: {
    50: "#D3EBD8",
    100: "#8EDEA2",
    200: "#64D183",
    300: "#3FC469",
    400: "#1DB853",
    500: "#00AC41",
    600: "#008537",
    700: "#005E2A",
    800: "#00381B",
    900: "#001209",
  },
  link: "#1376FA",
  white: "#ffffff",
  "fill-prim": {
    reset1: "#E9AE15",
    reset2: "#FFC93D",
    reset3: "#019874",
    hover1: "#FFC52F",
    hover2: "#FFDF80",
    pressed1: "#DF9B38",
    pressed2: "#DF9B38",
    disable1: "#39404B",
    disable2: "#525A65",
  },
  "fill-seco": {
    reset1: "#FF1111",
    reset2: "#FF3A34",
    reset3: "#00768F",
    hover1: "#FF3A34",
    hover2: "#FD685E",
    pressed1: "#D11319",
    pressed2: "#FF1111",
    disable1: "#39404B",
    disable2: "#525A65",
  },
  "fill-tert": {
    reset1: "#293956",
    reset2: "#3D4F6C",
    hover1: "#3D4E6C",
    hover2: "#3F526E",
    pressed1: "#1C2433",
    pressed2: "#141C26",
    disable1: "#39404B",
    disable2: "#525A65",
  },
  "fill-accent": {
    a1: "#022054",
    a2: "#105BBC",
  },
  "fill-gold": {
    a1: "#986A12",
    a2: "#BF9A54",
  },
  "fill-silver": {
    a1: "#425D7A",
    a2: "#7E9BC0",
  },
  "fill-gray": {
    a1: "#39404B",
    a2: "#525A65",
  },
  system: {
    normal: {
      info: "#235CF1",
      warn: "#F9A43D",
      fail: "#F43434",
      success: "#00AC41",
      link: "#1376FA",
      white: "#FFFFFF",
    },
    reverse: {
      info: "#06304B",
      warn: "#FFFBF0",
      fail: "#FFF2F0",
      success: "#D3EBD8",
      link: "#F0F7FF",
      white: "#F4F6F7",
    },
  },
};
export default colors;
