// 最早执行的JS代码

import "intl-pluralrules";
import "@/styles/globals.css";
import "@/common/locale/currency";
import queryString from "query-string";
import i18n from "@/common/locale/i18n";
import property from "@/websites/P2/property";
import { setCurrencyCode } from "@/common/locale/currency";

// import { init } from '@/common/tracker'

// init tracker
// init()

// debug i18n
const query = queryString.parse(window.location.search);
if (query.lang) {
  i18n.changeLanguage((query.lang as string) || property.locale.fallbackLocale);
}

if (query.currency) {
  setCurrencyCode(
    (query.currency as string) || property.locale.currencies[0] || ""
  );
}
