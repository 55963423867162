import property from '@/websites/current/property'

/* NOTE: 此处用于判断各个网站差异性配置，具体差异可以在 property.json 中配置 features 字段
 * 缺省字段视为false，非缺省时可以配置为true或者一个字符串来表示多种状态之一
 * 不应该配置更详细的参数，其他参数应该在具体功能的配置中考虑方案
 */

/** 判断某个功能是否开放 */
export function isFeatureEnabled(key: string): boolean {
  return !!(property.features as Record<string, string>)[key]
}

/** 获取功能相关参数 */
export function getFeatureFlag(key: string): string {
  return (property.features as Record<string, string>)[key] || ''
}

export function FeatureToggle({
  feature,
  children,
}: {
  feature: string
  children?: React.ReactNode
}) {
  if (isFeatureEnabled(feature)) {
    return children
  }
  return null
}
