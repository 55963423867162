import { ChakraProvider } from '@chakra-ui/react'
// import { useLayoutEffect } from 'react'
import { Provider } from 'react-redux'
import { RouterProvider } from 'react-router-dom'

import { store } from '@/modules/app/store'
import { router } from '@/routes/root'
import theme from '@/styles/theme'
import globalToastOption from '@/styles/toast'
// import ErrorBoundary from '../error/ErrorBoundary'

function App() {
  // useLayoutEffect(() => {
  //   if (window) {
  //     window.close = () => null
  //   }
  // }, [])

  // useEffect(() => {
  //   FPSMonitor.getInstance().start()

  //   return () => {
  //     FPSMonitor.getInstance().destroy()
  //   }
  // }, [])

  return (
    <Provider store={store}>
      <ChakraProvider theme={theme} toastOptions={globalToastOption}>
          {/* <ErrorBoundary> */}
            <RouterProvider router={router} />
          {/* </ErrorBoundary> */}
      </ChakraProvider>
    </Provider>
  )
}

export default App
