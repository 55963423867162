const CopyInput = {
    baseStyle: {
        colorScheme: {
            white: {
                field: {
                    color: "text.base",
                    "--bg-color": "var(--chakra-colors-white)"
                }
            },
            "black-alpha-10": {
                field: {
                    "--bg-color": "rgba(255, 255, 255, 0.10)",
                    color: "white"
                }
            },
            prim: {
                field: {
                    color: "prim.500",
                    "--bg-color": "var(--chakra-colors-white)"
                }
            }
        }
    },
    variants: {
        filled: {
            field: {
                color: "gray.700",
                bg: "var(--bg-color)",
                _hover: {
                    bg: "var(--bg-color)"
                },
                _focus: {
                    bg: "var(--bg-color)"
                }
            }
        },
        "filled-share": {
            field: {
                color: "white",
                bg: "var(--bg-color)",
                _hover: {
                    bg: "var(--bg-color)"
                },
                _focus: {
                    bg: "var(--bg-color)"
                }
            }
        }
    }
}

export default CopyInput;