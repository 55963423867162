const FormLabel = {
    parts: ["allGameBtnT", "allGameBtnB", "onlineNum"],
    baseStyle: {
        allGameBtnT: {
            display: "flex"
        },
        allGameBtnB: {
            display: "none"
        },
        onlineNum: {}
    },
    defaultProps: {}
}
export default FormLabel;