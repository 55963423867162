const AppLayout = {
    parts: ["leftColumn", "rightColumn", "middleColumn", "content", "main", "header", "footer", "sideNav", "notice", "tabBar"],
    baseStyle: {
        middleColumn: {
            bg: "gray.400"
        },
        content: {
            bg: "bg.baseLayer"
        },
        header: {
            bg: "gray.400"
        },
        footer: {
            bg: "gray.400"
        },
        sideNav: {
            bg: "gray.300"
        },
        notice: {
            bg: "gray.400"
        },
        tabBar: {
            bg: "gray.400"
        }
    }
}

export default AppLayout;