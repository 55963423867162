import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice, isAnyOf } from '@reduxjs/toolkit'

import { getVariable } from '@/common/env'
import { setCurrencyCode } from '@/common/locale/currency'
import i18n from '@/common/locale/i18n'
import { startAppListening } from '@/modules/app/listenerMiddleware'
import type { RootState } from '@/modules/app/store'
import property from '@/websites/current/property'

import { currencyApi } from './currencyApi'

export interface CurrencyRateInfo {
  ID: number
  currency: string
  exchange_rate: number
  [key: string]: any
}

interface CurrencySlice {
  isBindCurrency: boolean
  showBindCurrency: boolean
  bindCurrency?: string
  callBackType: string
  currencyRateList: CurrencyRateInfo[] // 汇率
  currencyFixedRateList: CurrencyRateInfo[] // 固定比例
}

const initialState: CurrencySlice = {
  isBindCurrency: false,
  showBindCurrency: false,
  bindCurrency: '',
  callBackType: '',
  currencyRateList: [],
  currencyFixedRateList: [],
}
export const currencySlice = createSlice({
  name: 'currency',
  initialState,
  reducers: {
    switchBindModal: state => {
      if (state.isBindCurrency) {
        state.showBindCurrency = false
        return
      }
      state.showBindCurrency = !state.showBindCurrency
    },
    setCallBackType: (
      state,
      action: PayloadAction<{ callBackType: string }>,
    ) => {
      const { callBackType } = action.payload
      state.callBackType = callBackType
    },
    setCurrenctRateList: (state, action: PayloadAction<CurrencyRateInfo[]>) => {
      state.currencyRateList = action.payload || []
    },
    setBindCurrency: (
      state,
      action: PayloadAction<{
        bindCurrency: string
        isBindCurrency: boolean
      }>,
    ) => {
      const { bindCurrency, isBindCurrency } = action.payload
      state.bindCurrency = bindCurrency
      state.isBindCurrency = isBindCurrency
      state.showBindCurrency = false
    },
  },
  extraReducers: builder => {
    builder
      .addMatcher(
        isAnyOf(
          currencyApi.endpoints.bindCurrencyInfo.matchFulfilled,
          currencyApi.endpoints.bindCurrency.matchFulfilled,
        ),
        (state, action) => {
          const { data } = action.payload
          if (data && data.currency) {
            state.bindCurrency = data.currency
            state.isBindCurrency = true
            setCurrencyCode(
              data.currency || property.locale.currencies[0] || '',
            )
          } else {
            state.showBindCurrency = true
          }
        },
      )
      .addMatcher(
        currencyApi.endpoints.bindCurrency.matchFulfilled,
        (state, action) => {
          const { data } = action.payload
          if (data && data.currency && state.showBindCurrency) {
            state.showBindCurrency = false
          }
        },
      )
      .addMatcher(
        currencyApi.endpoints.currencyRateInfo.matchFulfilled,
        (state, action) => {
          const { data } = action.payload
          state.currencyRateList = data || []
        },
      )
      .addMatcher(
        currencyApi.endpoints.currencyFixedRateInfo.matchFulfilled,
        (state, action) => {
          const { data } = action.payload
          state.currencyFixedRateList = data || []
        },
      )
  },
})

export const {
  switchBindModal,
  setBindCurrency,
  setCallBackType,
  setCurrenctRateList,
} = currencySlice.actions

export const currencyModalReducer = currencySlice.reducer

export const selectCurrency = (state: RootState) => state.currencyModal

export const getShowBindCurrency = (state: RootState) =>
  selectCurrency(state).showBindCurrency

export const getCurrencyRateList = (state: RootState) =>
  selectCurrency(state).currencyRateList

export const getCurrencyFixedRateList = (state: RootState) =>
  selectCurrency(state).currencyFixedRateList

export const getBindCurrency = (state: RootState) =>
  selectCurrency(state).bindCurrency
export const getIsBindCurrency = (state: RootState) =>
  selectCurrency(state).isBindCurrency

export const getCallBackType = (state: RootState) =>
  selectCurrency(state).callBackType

export const selectIsOpenActive = (state: RootState) => {
  if (getVariable('SHOW_BIND_SELECT')) {
    const supportCurrencies = property.locale.currencies || []
    return (
      supportCurrencies.includes(getBindCurrency(state) || '') ||
      getBindCurrency(state) === ''
    )
  } else {
    return true
  }
}

// 监听绑定货币成功的 action，动态更新 i18n
startAppListening({
  matcher: currencyApi.endpoints.bindCurrency.matchFulfilled,
  effect: action => {
    const currency = action.payload?.data?.currency
    if (!currency) return
    i18n.addResource(i18n.language, 'dyn', 'currency', currency)
  },
})
