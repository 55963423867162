export const COM_USER_ID = 'userId'
export const COM_DEVICE_ID = 'deviceId'
export const COM_SYSTEM = 'system'
export const COM_LANGUAGE = 'language'
export const COM_TIMEZONE = 'timezone'

export const EVT_PAGEVIEW = 'pageview'
export const EVT_CLICK = 'click'
export const EVT_ACTION = 'action'
export const EVT_ERROR = 'error'
export const EVT_PAGELOAD = 'pageLoad'

export type TrackKey = string
export type TrackFn = (event: EventName, data?: TrackData) => void
export type TrackValue = string | number
export type TrackData = Record<TrackKey, TrackValue>
export type EventConfigType = Partial<Record<EventName, string[]>>

export enum TrackerName {
  FaceBook,
  KWai,
  TikTok,
  Adjust,
  AdjustPay,
  KWaiApi,
  AdjustWeb,
}

export enum LoginType {
  Login,
  FbLogin,
  GoogleLogin,
  EmailLogin,
  PhoneLogin,
  ApkLogin,
}

export enum RegType {
  Register,
  FbRegister,
  EmailRegister,
  PhoneRegister,
  ApkRegister,
}

export enum PayType {
  FirstPay,
  SecondPay,
  MorePay,
}

export enum EventName {
  ContentView = 'ContentView',
  Login = 'Login',
  FbLogin = 'FbLogin',
  // google 登录
  GoogleLogin = 'GoogleLogin',
  //注册成功
  CompleteRegistration = 'CompleteRegistration',
  CompleteFbRegistration = 'CompleteFbRegistration',
  PlaceAnOrder = 'PlaceAnOrder',
  CompletePurchase = 'CompletePurchase',
  SecondRecharge = 'SecondRecharge',
  MoreRecharge = 'MoreRecharge',
  // 进入首页 N1_AdjustWeb 里面叫 EnterLobby
  EnterHome = 'EnterHome',
  // 进入注册页面
  EnterRegister = 'EnterRegister',
  // 手机号注册成功
  PhoneRegister = 'PhoneRegister',
  // email注册成功
  EmailRegister = 'EmailRegister',
  // 进入登录页面
  EnterLogin = 'EnterLogin',
  // fb 登录成功
  FbLoginSuccess = 'FbLoginSuccess',

  // 手机号登录成功
  PhoneLogin = 'PhoneLogin',
  // email登录成功
  EmailLogin = 'EmailLogin',
  // 点击钱包按钮 （N1_AdjustWeb 里面叫 add_cash ）
  ClickWallet = 'ClickWallet',
  // 充值界面展示
  ShowRechargePanel = 'ShowRechargePanel',
  // 点击充值按钮
  ClickRecharge = 'ClickRecharge',
  // 提现界面展示
  ShowWithdrawPanel = 'ShowWithdrawPanel',
  // 点击下载按钮
  ClickDownload = 'ClickDownload',
  // apk注册
  ApkRegister = 'ApkRegister',
  // apk登录
  ApkLogin = 'ApkLogin',
  // adjust Login 统计不同
  AdjustLogin = 'AdjustLogin',
  // 首次打开
  FirstOpen = 'FirstOpen',
  // 打开促销活动
  viewPromotion = 'viewPromotion',
  // 添加游戏到收藏列表
  addToWishList = 'addToWishList',
}
