const Link = {
    baseStyle: {
        color: "link",
        textDecoration: "underline"
    },
    variants: {
        unstyled: {
            textDecoration: "none",
            _hover: {
                textDecoration: "none"
            }
        }
    }
}

export default Link;