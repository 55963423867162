import axios from 'axios'

import { getPlatform } from '@/utils/platform'

import { getApiBaseUrl, isProduction } from '../env'

export interface ShushuReportArgs {
  from: string
  game_id: string | number
  game_name: string
  manufacturer: number | string
  label_id?: string | number
  user_id?: string
}

export function setShushuGameReportData(args: ShushuReportArgs): void {
  window.shushu = args
}

const DEFAULT_BASE_URL = getApiBaseUrl()
export function shushuReport(
  args: ShushuReportArgs & {
    /**  0打开事件 1关闭事件 */
    type: 0 | 1
    visit_time?: number
  },
): void {
  const platform = getPlatform()

  /** 先同步 B3 */
  if (platform !== 'b3' && platform !== 'B3') return

  const _args = {
    ...args,
    game_id: `${args.game_id}`,
    label_id: `${args.label_id}`,
    manufacturer: `${args.manufacturer}`,
  }

  if (!isProduction()) {
    console.log('shushu args', _args)
  }
  axios.post<any, any, ShushuReportArgs>(
    `${DEFAULT_BASE_URL}/v1/shushu/play_game`,
    _args,
  )
}
