import type { ImageProps } from '@chakra-ui/react'
import { AspectRatio, Image, Skeleton } from '@chakra-ui/react'
import { useState } from 'react'
import type { LazyLoadProps } from 'react-lazyload'
import LazyLoad from 'react-lazyload'

import { handleS3SourceDomain } from '@/utils/tools'

export type LazyImageProps = ImageProps &
  LazyLoadProps & {
    noLazy?: boolean
  }

/**
 *
 * @param props
 *  - `once` 是否重复加载，默认为false，即只加载一次
 * - `debounce` 延迟加载时间 单位ms
 * - `offset` 偏移量 用于提前加载
 * - `height` 高度 用于占位
 * 更多参数请参考
 * https://github.com/twobin/react-lazyload
 * 其余参数同Image保持一致
 * @returns
 */
function LazyImage({ noLazy = true, ...props }: LazyImageProps) {
  return (
    <>
      {noLazy ? (
        <Image
          {...props}
          src={handleS3SourceDomain(props.src || '')}
          loading='lazy'
        />
      ) : (
        <LazyLoad offset={10} height='auto' {...props}>
          <Image {...props} src={handleS3SourceDomain(props.src || '')} />
        </LazyLoad>
      )}
    </>
  )
}

export default LazyImage

interface ImageWithLoadingProps {
  ratio: number
  src: string
  alt?: string
  noLazy?: boolean
}

export function ImageWithLoading({
  src,
  ratio,
  alt = src,
  noLazy = false,
  ...props
}: ImageWithLoadingProps & LazyImageProps) {
  const [isLoaded, setIsLoaded] = useState(false)

  return (
    <Skeleton isLoaded={isLoaded}>
      <AspectRatio ratio={ratio}>
        <LazyImage
          src={src}
          alt={alt}
          objectFit='cover'
          objectPosition='center'
          noLazy={noLazy}
          onLoad={() => setIsLoaded(true)}
          {...props}
        />
      </AspectRatio>
    </Skeleton>
  )
}
