const Campaign = {
    baseStyle: {
        cardOdd: {
            layerStyle: "ls-seco"
        },
        cardEven: {
            layerStyle: "ls-prim"
        }
    },
    defaultProps: {}
}

export default Campaign;