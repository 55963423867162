import { useCallback, useMemo } from 'react'

import { localStorage } from '@/utils/localStorage'
import property from '@/websites/current/property'

import {
  decimalFormatByCode,
  getSignByCode,
  integerFormatByCode,
} from './currency-const'

const CURRENCY_KEY = 'atom-currency'
let _currentCurrencyCode = loadCurrency()

function loadCurrency(): string {
  const availableCurrencies = property.locale.currencies
  const storedCurrency = localStorage.getItem(CURRENCY_KEY)
  const supportRegions = property.locale.regions || []
  const currentRegion = navigator.language
  if (storedCurrency && availableCurrencies.includes(storedCurrency)) {
    return storedCurrency
  }
  if (supportRegions.includes(currentRegion)) {
    return availableCurrencies[
      supportRegions.findIndex(item => item === currentRegion)
    ]
  }
  return availableCurrencies[0]
}

function writeCurrency(currency: string): void {
  localStorage.setItem(CURRENCY_KEY, currency)
}

/** 获取当前货币代码清单，如：["USD", "BRL"] */
export function getCurrencyCodeList(): string[] {
  return property.locale.currencies
}

/** 设置货币代码 */
export function setCurrencyCode(code: string) {
  _currentCurrencyCode = code
  writeCurrency(code)
}

/** 获取当前货币代码，如：USD */
export function getCurrencyCode(): string {
  return _currentCurrencyCode
}

export function getCurrencyCodeAsync(): Promise<string> {
  return new Promise(resolve => {
    resolve(_currentCurrencyCode)
  })
}

/** 获取当前货币的符号，如：¥ */
export function getSign(coin?: string): string {
  return getSignByCode(coin ?? _currentCurrencyCode)
}

/** 整数格式化如：1,000 */
export function integerFormat(value: number): string {
  return integerFormatByCode(_currentCurrencyCode, value)
}

/** 分数格式化如：1,000.00 */
export function decimalFormat(value: number): string {
  return decimalFormatByCode(_currentCurrencyCode, value)
}

/** 便利的hooks，提供基于code的 getSign / integerFormat / decimalFormat API */
export function useCurrency(code: string) {
  const getSign = useCallback(() => getSignByCode(code), [code])
  const integerFormat = useCallback(
    (value: number) => integerFormatByCode(code, value),
    [code],
  )
  const decimalFormat = useCallback(
    (value: number) => decimalFormatByCode(code, value),
    [code],
  )
  return useMemo(
    () => ({
      getSign,
      integerFormat,
      decimalFormat,
    }),
    [getSign, integerFormat, decimalFormat],
  )
}
