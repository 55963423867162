const VipInfo = {
    parts: ["item", "progressColor", "progressText"],
      baseStyle: {
          item: {
              bg: ["gray.500", "gray.400"]
          },
          progressText: {
              color: ["four.500", "prim.500"]
          }
      },
      defaultProps: {}
}

export default VipInfo;