import type { Platform as IPlatform, ThirdPartSignResult } from './types'

export enum Native3rdPartyLoginChannel {
  Google = 'GG',
  Facebook = 'FB',
}

interface Android3rdPartyLoginResult {
  channel: string
  isSigned: boolean
  signResult: {
    serverAuthCode: string
  }
}

export default class PlatformAndroid implements IPlatform {
  googleClientId: string
  fbAppId: string

  constructor(googleClientId: string, fbAppId: string) {
    this.googleClientId = googleClientId
    this.fbAppId = fbAppId
  }

  isGoogleChannel(channel: string) {
    return channel === Native3rdPartyLoginChannel.Google
  }

  isFacebookChannel(channel: string) {
    return channel === Native3rdPartyLoginChannel.Facebook
  }

  googleSingIn() {
    return this.thirdPartSignIn(Native3rdPartyLoginChannel.Google)
  }

  facebookSingIn() {
    return this.thirdPartSignIn(Native3rdPartyLoginChannel.Facebook)
  }

  firebaseEvent(args: {
    key: string
    value: string | number
    [key: string]: string | number
  }) {
    try {
      window.AndroidWebView?.firebaseEvent(JSON.stringify(args))
    } catch (error) {
      window.AndroidWebView?.firebaseEvent(args.key, args.vallue)
    }
  }

  setClientStorage(key: string, val: string) {
    window.AndroidWebView?.setClientStorage(key, val)
  }

  getClientStorage(key: string) {
    const clientStorage = this.getAllClientStorage()
    clientStorage ? clientStorage[key] : null
    return clientStorage ? clientStorage[key] : null
  }

  removeClientStorage(key: string) {
    window.AndroidWebView?.removeClientStorage(key)
  }

  clearClientStorage() {
    window.AndroidWebView?.clearClientStorage()
  }

  getAllClientStorage() {
    const clientStorage = window.AndroidWebView?.getAllClientStorage() || '{}'
    return JSON.parse(clientStorage)
  }

  thirdPartSignIn(channel: Native3rdPartyLoginChannel) {
    return new Promise<ThirdPartSignResult>((resove, reject) => {
      const serverClientId =
        channel === Native3rdPartyLoginChannel.Google
          ? this.googleClientId
          : this.fbAppId
      window.AndroidWebView?.do3rdSignIn(
        channel,
        JSON.stringify({ serverClientId }),
      )
      window.on3rdSignResult = res => {
        let data: null | Android3rdPartyLoginResult = null
        if (typeof res === 'string') {
          data = JSON.parse(res) as Android3rdPartyLoginResult
        } else if (typeof res === 'object') {
          data = res as unknown as Android3rdPartyLoginResult
        }
        if (!data || data.channel !== channel) {
          reject('invalid data')
        } else if (!data.isSigned) {
          reject('signin failed')
        } else {
          resove({
            channel: data.channel,
            isSigned: data.isSigned,
            authCode: data.signResult?.serverAuthCode,
          })
        }
      }
    })
  }
}
