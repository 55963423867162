const Button = {
    baseStyle: {
        colorScheme: {
            prim: {
                "--btn-bg": "var(--chakra-colors-prim-500)",
                "--btn-text": "var(--chakra-colors-white)",
                "--btn-border": "var(--chakra-colors-prim-500)",
                "--btn-bg-hover": "var(--chakra-colors-prim-400)",
                "--btn-bg-active": "var(--chakra-colors-prim-600)",
                "--btn-bg-disabled": "var(--chakra-colors-prim-300)",
                "--btn-text-hover": "var(--chakra-colors-white)",
                "--btn-text-active": "var(--chakra-colors-white)",
                "--btn-text-disabled": "var(--chakra-colors-prim-500)"
            },
            white: {
                "--btn-bg": "var(--chakra-colors-bg-control)",
                "--btn-text": "var(--chakra-colors-text-accent)",
                "--btn-border": "var(--chakra-colors-bg-control)",
                "--btn-bg-hover": "var(--chakra-colors-bg-control)",
                "--btn-bg-active": "var(--chakra-colors-bg-control)",
                "--btn-bg-disabled": "var(--chakra-colors-bg-control)",
                "--btn-text-hover": "var(--chakra-colors-text-accent)",
                "--btn-text-active": "var(--chakra-colors-text-accent)",
                "--btn-text-disabled": "var(--chakra-colors-text-base)"
            },
            seco: {
                "--btn-bg": "var(--chakra-colors-seco-500)",
                "--btn-text": "var(--chakra-colors-white)",
                "--btn-border": "var(--chakra-colors-seco-500)",
                "--btn-bg-hover": "var(--chakra-colors-seco-400)",
                "--btn-bg-active": "var(--chakra-colors-seco-600)",
                "--btn-bg-disabled": "var(--chakra-colors-seco-300)",
                "--btn-text-hover": "var(--chakra-colors-white)",
                "--btn-text-active": "var(--chakra-colors-white)",
                "--btn-text-disabled": "var(--chakra-colors-seco-500)"
            },
            four: {
                "--btn-bg": "var(--chakra-colors-four-500)",
                "--btn-text": "var(--chakra-colors-black)",
                "--btn-border": "var(--chakra-colors-four-500)",
                "--btn-bg-hover": "var(--chakra-colors-four-400)",
                "--btn-bg-active": "var(--chakra-colors-four-600)",
                "--btn-bg-disabled": "var(--chakra-colors-four-300)",
                "--btn-text-hover": "var(--chakra-colors-black)",
                "--btn-text-active": "var(--chakra-colors-black)",
                "--btn-text-disabled": "var(--chakra-colors-four-500)"
            },
            gray: {
                "--btn-bg": "var(--chakra-colors-gray-600)",
                "--btn-text": "var(--chakra-colors-text-accent)",
                "--btn-border": "var(--chakra-colors-text-accent)",
                "--btn-bg-hover": "var(--chakra-colors-gray-700)",
                "--btn-bg-active": "var(--chakra-colors-gray-500)",
                "--btn-bg-disabled": "var(--chakra-colors-gray-600)",
                "--btn-text-hover": "var(--chakra-colors-text-accent)",
                "--btn-text-active": "var(--chakra-colors-text-accent)",
                "--btn-text-disabled": "var(--chakra-colors-gray-800)"
            },
            "fill-prim": {
                "--btn-bg-disabled": "var(--chakra-colors-gray-300)",
                "--btn-text": "var(--chakra-colors-white)",
                "--btn-text-hover": "var(--chakra-colors-white)",
                "--btn-text-active": "var(--chakra-colors-white)",
                "--btn-text-disabled": "var(--chakra-colors-gray-500)",
                bgGradient: "linear(to-r, fill-prim.reset1, fill-prim.reset2)",
                _hover: {
                    bgGradient: "linear(to-r, fill-prim.hover1, fill-prim.hover2)"
                },
                _active: {
                    bgGradient: "linear(to-r, fill-prim.pressed1, fill-prim.pressed2)"
                },
                _disabled: {
                    bgGradient: "none"
                }
            },
            "fill-seco": {
                "--btn-bg-disabled": "var(--chakra-colors-gray-200)",
                "--btn-text": "var(--chakra-colors-white)",
                "--btn-text-hover": "var(--chakra-colors-white)",
                "--btn-text-active": "var(--chakra-colors-white)",
                "--btn-text-disabled": "var(--chakra-colors-gray-500)",
                bgGradient: "linear(to-r, fill-seco.reset1, fill-seco.reset2)",
                _hover: {
                    bgGradient: "linear(to-r, fill-seco.hover1, fill-seco.hover2)"
                },
                _active: {
                    bgGradient: "linear(to-r, fill-seco.pressed1, fill-seco.pressed2)"
                },
                _disabled: {
                    bgGradient: "none"
                }
            },
            "fill-tert": {
                "--btn-bg-disabled": "var(--chakra-colors-gray-300)",
                "--btn-text": "var(--chakra-colors-white)",
                "--btn-text-hover": "var(--chakra-colors-white)",
                "--btn-text-active": "var(--chakra-colors-white)",
                "--btn-text-disabled": "var(--chakra-colors-gray-500)",
                bgGradient: "linear(to-b, fill-tert.reset1, fill-tert.reset2)",
                _hover: {
                    bgGradient: "linear(to-b, fill-tert.hover1, fill-tert.hover2)",
                    _disabled: {
                        bgGradient: "none"
                    }
                },
                _active: {
                    bgGradient: "linear(to-b, fill-tert.pressed1, fill-tert.pressed2)"
                },
                _disabled: {
                    bgGradient: "none"
                }
            },
            "fill-gold": {
                "--btn-bg-disabled": "var(--chakra-colors-gray-300)",
                "--btn-text": "var(--chakra-colors-white)",
                "--btn-text-hover": "var(--chakra-colors-white)",
                "--btn-text-active": "var(--chakra-colors-white)",
                "--btn-text-disabled": "var(--chakra-colors-gray-500)",
                bgGradient: "linear-gradient(0deg, #F6BF65 0%, #DD9A3C 100%)",
                _hover: {
                    bgGradient: "linear-gradient(0deg, #F6BF65 0%, #DD9A3C 100%)"
                },
                _active: {
                    bgGradient: "linear-gradient(0deg, #F6BF65 0%, #DD9A3C 100%)"
                },
                _disabled: {
                    bgGradient: "none"
                }
            },
            "fill-reward-success-confirm": {
                "--btn-bg": "var(--chakra-colors-tert-500)",
                "--btn-text": "var(--chakra-colors-white)",
                "--btn-border": "var(--chakra-colors-tert-500)",
                "--btn-bg-hover": "var(--chakra-colors-tert-400)",
                "--btn-bg-active": "var(--chakra-colors-tert-600)",
                "--btn-bg-disabled": "var(--chakra-colors-seco-300)",
                "--btn-text-hover": "var(--chakra-colors-white)",
                "--btn-text-active": "var(--chakra-colors-white)",
                "--btn-text-disabled": "var(--chakra-colors-gray-600)"
            },
            "fill-seco-task": {
                "--btn-bg-disabled": "var(--chakra-colors-gray-200)",
                "--btn-text": "var(--chakra-colors-white)",
                "--btn-text-hover": "var(--chakra-colors-white)",
                "--btn-text-active": "var(--chakra-colors-white)",
                "--btn-text-disabled": "var(--chakra-colors-text-base)",
                bgGradient: "linear(to-b, fill-seco.reset1, fill-seco.reset2)",
                _hover: {
                    bgGradient: "linear(to-b, fill-seco.hover1, fill-seco.hover2)"
                },
                _active: {
                    bgGradient: "linear(to-b, fill-seco.pressed1, fill-seco.pressed2)"
                }
            },
            "fill-seco-task-finish": {
                "--btn-bg": "var(--chakra-colors-seco-500)",
                "--btn-text": "var(--chakra-colors-white)",
                "--btn-border": "var(--chakra-colors-seco-500)",
                "--btn-bg-hover": "var(--chakra-colors-seco-400)",
                "--btn-bg-active": "var(--chakra-colors-seco-600)",
                "--btn-bg-disabled": "var(--chakra-colors-seco-300)",
                "--btn-text-hover": "var(--chakra-colors-white)",
                "--btn-text-active": "var(--chakra-colors-white)",
                "--btn-text-disabled": "var(--chakra-colors-white)"
            },
            "fill-seco-active-amount": {
                "--btn-bg-disabled": "var(--chakra-colors-gray-200)",
                "--btn-text": "var(--chakra-colors-white)",
                "--btn-text-hover": "var(--chakra-colors-white)",
                "--btn-text-active": "var(--chakra-colors-white)",
                "--btn-text-disabled": "var(--chakra-colors-text-base)",
                bgGradient: "linear-gradient(90deg, #F11 0%, #FF3A34 100%)",
                _hover: {
                    bgGradient: "linear-gradient(90deg, #F11 0%, #FF3A34 100%)"
                },
                _active: {
                    bgGradient: "linear-gradient(90deg, #F11 0%, #FF3A34 100%)"
                }
            },
            "fill-seco-unactive-amount": {
                "--btn-bg-disabled": "var(--chakra-colors-gray-200)",
                "--btn-text": "var(--chakra-colors-white)",
                "--btn-text-hover": "var(--chakra-colors-white)",
                "--btn-text-active": "var(--chakra-colors-white)",
                "--btn-text-disabled": "var(--chakra-colors-text-base)",
                bgGradient: "linear-gradient(90deg, #1474FF 0%, #3185FF 100%)",
                _hover: {
                    bgGradient: "linear-gradient(90deg, #1474FF 0%, #3185FF 100%)"
                },
                _active: {
                    bgGradient: "linear-gradient(90deg, #1474FF 0%, #3185FF 100%)"
                }
            },
            "fill-gray": {
                "--btn-bg-disabled": "var(--chakra-colors-gray-200)",
                "--btn-text": "var(--chakra-colors-white)",
                "--btn-text-hover": "var(--chakra-colors-white)",
                "--btn-text-active": "var(--chakra-colors-white)",
                "--btn-text-disabled": "var(--chakra-colors-text-base)",
                bgGradient: "linear-gradient(to-b, fill-gray.a1, fill-gray.a2)",
                _hover: {
                    bgGradient: "linear-gradient(to-b, fill-gray.a1, fill-gray.a2)"
                },
                _active: {
                    bgGradient: "linear-gradient(to-b, fill-gray.a1, fill-gray.a2)"
                }
            }
        }
    },
    variants: {
        solid: {
            bgColor: "var(--btn-bg)",
            borderColor: "var(--btn-border)",
            color: "var(--btn-text)",
            fontWeight: "bold",
            _hover: {
                bgColor: "var(--btn-bg-hover)",
                color: "var(--btn-text-hover)"
            },
            _active: {
                bgbgColor: "var(--btn-bg-active)",
                color: "var(--btn-text-active)"
            },
            _disabled: {
                opacity: "1",
                bgGradient: "none",
                bgColor: "var(--btn-bg-disabled)",
                color: "var(--btn-text-disabled)",
                _hover: {
                    bgColor: "var(--btn-bg-disabled)",
                    color: "var(--btn-text-disabled)",
                    bgGradient: "none"
                }
            }
        },
        link: {
            bg: "transparent",
            textDecoration: "underline",
            color: "link",
            _hover: {
                bg: "transparent"
            },
            _disabled: {
                bg: "transparent"
            }
        },
        outline: {
            border: "2px solid",
            bg: "transparent",
            borderRadius: "lg",
            bgColor: "transparent",
            borderColor: "var(--btn-border)",
            color: "var(--btn-border)",
            fontWeight: "bold",
            _hover: {
                bg: "transparent"
            },
            _active: {
                bg: "transparent"
            },
            _disabled: {
                bg: "transparent"
            }
        },
        ghost: {
            color: "prim.500",
            bg: "transparent",
            fontWeight: "bold",
            _hover: {
                bg: "transparent",
                _hover: {
                    bg: "transparent"
                },
                _active: {
                    bg: "transparent"
                }
            }
        },
        unstyled: {
            h: "auto",
            p: "0",
            borderRadius: "none",
            background: "none",
            _active: {
                background: "none"
            },
            _hover: {
                background: "none"
            }
        },
        unstyledIcon: {
            height: "max-content",
            p: "0",
            lineHeight: "normal",
            minW: "auto",
            borderRadius: "none",
            background: "none",
            _active: {
                background: "none"
            },
            _hover: {
                background: "none"
            }
        },
        shortcut: {
            w: "47px",
            bgColor: "gray.500",
            color: "#fff",
            _hover: {
                bgColor: "prim.500"
            },
            _active: {
                bgColor: "prim.600"
            }
        }
    },
    sizes: {
        sm: {
            h: "30px",
            fontSize: "14px",
            fontWeight: "extrabold",
            borderRadius: "md"
        },
        mdsm: {
            h: "36px",
            px: 4,
            fontSize: "12px",
            fontWeight: "bold",
            borderRadius: "md"
        },
        mdmd: {
            h: "38px",
            px: 4,
            fontSize: "14px",
            fontWeight: "extrabold",
            borderRadius: "lg"
        },
        md: {
            h: "38px",
            fontSize: "18px",
            lineHeight: "24px",
            fontWeight: "800",
            borderRadius: "lg"
        },
        lg: {
            h: "46px",
            textStyle: "h3",
            borderRadius: "lg"
        }
    }
}

export default Button;