import { useEffect } from "react";

import { getCurrencyCode } from "@/common/locale/currency";
import { getSocket } from "@/common/ws";
import { updateBanlance } from "@/modules/user/userSlice";
import { useUserAuth } from "@/utils/hooks";


export function useBalanceUpdater() {
  const [userId, token] = useUserAuth();

  useEffect(() => {
    const { listen, unlisten } = getSocket();
    listen(
      "topBarBalanceUpdate",
      "balance",
      ({ balance, currency = "", ...rest }) => {
        console.log("balance:", balance);

        // 推送消息币种不是当前用户绑定的币种
        const CURRENCY = getCurrencyCode();
        const { typ, pay_out, business_id } = rest;
        if (typ === "recharge") {
          updateBanlance(balance);
          return;
        }
        if (
          location.pathname.includes("/game/inhouse") ||
          CURRENCY !== currency
        ) {
          return;
        }
        updateBanlance(balance);
        // GMP游戏
        // if (location.pathname.includes('/game/self') && pay_out > 0) {
        //   dispatch(
        //     toggleBalanceFloat({
        //       balance: pay_out,
        //     }),
        //   )
        // }
      }
    );

    return () => {
      unlisten("topBarBalanceUpdate", "balance");
    };
  }, [userId, token]);
}
