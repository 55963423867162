import type { Platform as IPlatform, ThirdPartSignResult } from './types'

export enum IOS3rdPartyLoginChannel {
  Google = 'google',
  Facebook = 'facebook',
}
interface IOS3rdPartyLoginResult {
  channel: string
  isSigned: boolean
  serverAuthCode: string
}
export default class PlatformIOS implements IPlatform {
  googleClientId: string
  fbAppId: string

  constructor(serverClientId: string, fbAppId: string) {
    this.googleClientId = serverClientId
    this.fbAppId = fbAppId
  }

  isGoogleChannel(channel: string) {
    return channel === IOS3rdPartyLoginChannel.Google
  }

  isFacebookChannel(channel: string) {
    return channel === IOS3rdPartyLoginChannel.Facebook
  }

  googleSingIn() {
    return this.thirdPartSignIn(IOS3rdPartyLoginChannel.Google)
  }

  facebookSingIn() {
    return this.thirdPartSignIn(IOS3rdPartyLoginChannel.Facebook)
  }

  firebaseEvent(args: {
    key: string
    value: string | number
    [key: string]: string | number
  }) {
    //当 JS 需要调用 ios 时用 window.webkit?.messageHandlers.注册的方法名.postMessage(参数)
    window.webkit?.messageHandlers.firebaseEvent.postMessage({
      key: args.key,
      val: args.value,
    })
  }

  setClientStorage(key: string, val: string) {
    //当 JS 需要调用 ios 时用 window.webkit?.messageHandlers.注册的方法名.postMessage(参数)
    window.webkit?.messageHandlers.setClientStorage.postMessage({ key, val })
  }

  getClientStorage(key: string) {
    //当 JS 需要调用 ios 带有参数和返回值的方法时，使用window.prompt(方法名，参数)
    return JSON.parse(window.prompt('getClientStorage', key) || '{}')
  }

  removeClientStorage(key: string) {
    //当 JS 需要调用 ios 时用 window.webkit?.messageHandlers.注册的方法名.postMessage(参数)
    window.webkit?.messageHandlers.removeClientStorage.postMessage({ key })
  }

  clearClientStorage() {
    //当 JS 需要调用 ios 时用 window.webkit?.messageHandlers.注册的方法名.postMessage(参数)
    window.webkit?.messageHandlers.clearClientStorage.postMessage({})
  }

  getAllClientStorage() {
    //当 JS 需要调用 ios 带有参数和返回值的方法时，使用window.prompt(方法名，参数)
    const clientStorage = window.prompt('getAllClientStorage') || '{}'
    return JSON.parse(clientStorage)
  }

  thirdPartSignIn(channel: IOS3rdPartyLoginChannel) {
    return new Promise<ThirdPartSignResult>((resove, reject) => {
      window.webkit?.messageHandlers.do3rdSignIn.postMessage({
        channel,
        serverClientId: this.googleClientId,
      })
      window.on3rdSignResult = res => {
        let data: null | IOS3rdPartyLoginResult = null
        if (typeof res === 'string') {
          data = JSON.parse(res) as IOS3rdPartyLoginResult
        } else if (typeof res === 'object') {
          data = res as unknown as IOS3rdPartyLoginResult
        }
        if (!data || data.channel !== channel) {
          reject('invalid data')
        } else if (!data.isSigned) {
          reject('signin failed')
        } else {
          resove({
            channel: data.channel,
            isSigned: data.isSigned,
            authCode: data.serverAuthCode,
          })
        }
      }
    })
  }
}
