import { isProduction } from '@/common/env'
import store from '@/modules/app/store'
import { projectName, sdk } from '@/websites/current/property'

import { warn } from '../../logger'
import type { EventConfigType, TrackFn } from '../const'
import { EventName } from '../const'

let Adjust: any

// 2023 11 23 B3 M1 接入adjustWeb
let PARAMS_EVENT_CONFIGS: EventConfigType = {}
let appTokenFromParams = ''

let initPollingTimes: any = null

export const initAdjustWeb = (appToken: string, environment = 'production') => {
  const win = window as any
  Adjust = win.Adjust
  if (!Adjust || Adjust?.getWebUUID()) {
    return
  }

  Adjust?.initSdk({
    appToken: appToken,
    environment,
    // logLevel: 'verbose',
  })

  if (!initPollingTimes) {
    initPollingTimes = setInterval(() => {
      if (Adjust?.getWebUUID()) {
        clearInterval(initPollingTimes)
        initPollingTimes = null
        return
      }
      Adjust?.initSdk({
        appToken: appToken,
        environment,
        // logLevel: 'verbose',
      })
    }, 1000)
  }
}

export const handleAdjustGlobalCallbackParameters = (val?: string) => {
  if (projectName === 'N1') {
    const Adjust = (window as any)?.Adjust
    if (!Adjust || !Adjust?.getWebUUID()) {
      initAdjustWeb(sdk.ADJUST_WEB, isProduction() ? 'production' : 'sandbox')
    }
    if (!val) {
      Adjust.removeGlobalCallbackParameter('user_id')
      return
    }
    Adjust.addGlobalCallbackParameters([{ key: 'user_id', value: val }])
  }
}

export const initAdjustWebFromParams = () => {
  // 详细参数说明见 文档 https://project.feishu.cn/tringame/story/detail/19049808?parentUrl=%2Ftringame%2FstoryView%2Fn5UlZMRIR%3Fscope%3Dworkspaces%26node%3D16992846&scope=workspaces&node=16992846
  const searchParams = new URLSearchParams(window.location.search)
  // adjust_web tokens
  const atk = searchParams.get('atk')
  // params
  const params = atk?.split(',')

  // appToken
  const appToken = params
    ?.find(item => item.startsWith('tk'))
    ?.replace('tk', '')

  if (!appToken) return

  appTokenFromParams = appToken

  // 先判断一下当前是否初始化了 adjustWeb
  const win = window as any
  const Adjust = win.Adjust
  if (!Adjust || !Adjust?.getWebUUID()) {
    initAdjustWeb(appToken, isProduction() ? 'production' : 'sandbox')
  }

  // pageView
  const pageView = params
    ?.find(item => item.startsWith('pv'))
    ?.replace('pv', '')

  // facebook login
  const fbLogin = params?.find(item => item.startsWith('fl'))?.replace('fl', '')

  // facebook register
  const fbRegister = params
    ?.find(item => item.startsWith('fr'))
    ?.replace('fr', '')

  // google login
  const googleLogin = params
    ?.find(item => item.startsWith('gl'))
    ?.replace('gl', '')

  // google register
  const googleRegister = params
    ?.find(item => item.startsWith('gr'))
    ?.replace('gr', '')

  // native login
  const nativeLogin = params
    ?.find(item => item.startsWith('nl'))
    ?.replace('nl', '')

  // native register
  const nativeRegister = params
    ?.find(item => item.startsWith('nr'))
    ?.replace('nr', '')

  PARAMS_EVENT_CONFIGS = {
    [EventName.ContentView]: [pageView ?? ''],
    [EventName.FbLogin]: [fbLogin ?? ''],
    // 暂时没有这个事件
    // [EventName.FbRegister]: [fbRegister??''],
    // 暂时没有这个事件
    // [EventName.GoogleLogin]: [googleLogin??''],
    [EventName.CompleteRegistration]: [nativeRegister ?? ''],
    [EventName.Login]: [nativeLogin ?? ''],
    [EventName.GoogleLogin]: [googleLogin ?? ''],
    [EventName.CompleteFbRegistration]: [fbRegister ?? ''],
    [EventName.PhoneRegister]: [nativeRegister ?? ''],
    [EventName.EmailRegister]: [nativeRegister ?? ''],
    [EventName.PhoneLogin]: [nativeLogin ?? ''],
    [EventName.EmailLogin]: [nativeLogin ?? ''],
  }
}

// 针对N1的adjustWeb的事件配置
const N1_EVENT_CONFIGS: EventConfigType = {
  // 事件定义查看EventName里面的注释，右边为adid，事件标识码
  //   如果实在还是不明白，就看下面的文档，里面的场景定义
  // https://tringame.feishu.cn/docx/NWdUdBjtgo9oFvxDcEUcc2yLnSb
  [EventName.AdjustLogin]: ['7nxu1y'],
  [EventName.CompleteRegistration]: ['yiwusm'],
  [EventName.EnterHome]: ['upw4y2'],
  [EventName.EnterRegister]: ['3hcmph'],
  [EventName.CompleteFbRegistration]: ['ee8wyn'],
  [EventName.PhoneRegister]: ['pvphmq'],
  [EventName.EmailRegister]: ['mpj0id'],
  [EventName.EnterLogin]: ['lf7dee'],
  [EventName.FbLogin]: ['sy08pv'],
  [EventName.PhoneLogin]: ['nt832b'],
  [EventName.EmailLogin]: ['32u1gl'],
  [EventName.ClickWallet]: ['o1jaqp'],
  [EventName.ShowRechargePanel]: ['tlwdva'],
  [EventName.ClickRecharge]: ['uphbc6'],
  [EventName.ShowWithdrawPanel]: ['c0xj50'],
  [EventName.ClickDownload]: ['5dkj13'],
  [EventName.ApkRegister]: ['16lwpr'],
  [EventName.ApkLogin]: ['3ia6ml'],
}

let preUserId: any = undefined
export const trackerFn: TrackFn = (event, data) => {
  try {
    const isEnableParamsProjects = ['B3', 'M1']
    const specialProjects = ['N1']

    const isEnableParams = isEnableParamsProjects.includes(projectName)
    const isSpecial = specialProjects.includes(projectName)

    if (window?.AndroidWebView && !isEnableParams) {
      // trackerFnForApk(event)
      return
    }

    if (isEnableParams && !Object.keys(PARAMS_EVENT_CONFIGS).length) {
      initAdjustWebFromParams()
      return
    }
    // 如果是N1项目，就用特殊的事件配置
    // 如果是B3 M1项目，就用参数配置
    const events = isEnableParams
      ? PARAMS_EVENT_CONFIGS[event]
      : isSpecial
      ? N1_EVENT_CONFIGS[event]
      : undefined
    if (!events) {
      return
    }

    const curUserId = store.getState().user.userId
    if (curUserId !== preUserId) {
      preUserId = curUserId
      handleAdjustGlobalCallbackParameters(curUserId)
    }
    const win = window as any
    const Adjust = win.Adjust
    // 如果没有初始化
    if (!Adjust || !Adjust?.getWebUUID()) {
      if (isSpecial) {
        initAdjustWeb(sdk.ADJUST_WEB, isProduction() ? 'production' : 'sandbox')
      }
      if (isEnableParams) {
        initAdjustWeb(
          appTokenFromParams,
          isProduction() ? 'production' : 'sandbox',
        )
      }
    }
    for (const event of events) {
      Adjust?.trackEvent({
        eventToken: event,
        ...data,
      })
    }
  } catch (error) {
    warn(`[Tracker]: ADJUST event ${event} fail to send ${error}`)
  }
}

export const getWebUUID = () => {
  if (!Adjust) {
    return ''
  }
  return Adjust?.getWebUUID()
}

export const getAdid = () => {
  if (!Adjust) {
    return ''
  }
  return Adjust.getAttribution()?.adid
}
