import format from './number-format'

type FormatFunc = (value: number) => string
type CurrFormat = string | FormatFunc
type CurrConfig = {
  code: string
  sign: string
  // 原来的sign (用于推广页中,充值界面的显示，以及存档，等待充值成功后再还原)
  originalSign?: string
  integerFormat: CurrFormat
  decimalFormat: CurrFormat
}

// const NUM_K = 1000
// const NUM_M = 1000 * NUM_K

// // 将数字转换为K/M为单位的
// function toKM(value: number, threshold: number): [number, string] {
//   if (value >= threshold * NUM_M) {
//     return [value / NUM_M, 'M']
//   }
//   if (value >= threshold * NUM_K) {
//     return [value / NUM_K, 'K']
//   }
//   return [value, '']
// }

const NUM_K = 1
const NUM_M = 1 * NUM_K

// 将数字转换为K/M为单位的
function toKM(value: number, threshold: number): [number, string] {
  if (value >= threshold * NUM_M) {
    return [value / NUM_M, '']
  }
  if (value >= threshold * NUM_K) {
    return [value / NUM_K, '']
  }
  return [value, '']
}

function getKMFormatter(formatTemp: string, threshold = 1): FormatFunc {
  return (value: number) => {
    // value = Number(Number(value || 0).toFixed(2))
    const [val, unit] = toKM(value, threshold)
    return (
      format(formatTemp, val, {
        noRounding: true,
      }) + unit
    )
  }
}

// 在此配置各个货币的展示信息
export const supportedCurrencies: {
  [code: string]: CurrConfig
} = {
  PHP: {
    code: 'PHP',
    sign: '₱',
    integerFormat: getKMFormatter('#,##0.'),
    decimalFormat: getKMFormatter('#,##0.00'),
  },
  INR: {
    code: 'INR',
    sign: '₹',
    integerFormat: getKMFormatter('#.##0,'),
    decimalFormat: getKMFormatter('#.##0,00'),
  },
  BRL: {
    code: 'BRL',
    sign: 'R$',
    integerFormat: getKMFormatter('#.##0,'),
    decimalFormat: getKMFormatter('#.##0,00'),
  },
  MXN: {
    code: 'MXN',
    sign: '$',
    integerFormat: getKMFormatter('#,##0.'),
    decimalFormat: getKMFormatter('#,##0.00'),
  },
  COP: {
    code: 'COP',
    sign: '$',
    integerFormat: getKMFormatter('#.##0,'),
    decimalFormat: getKMFormatter('#.##0,00'),
  },
  CLP: {
    code: 'CLP',
    sign: '$',
    integerFormat: getKMFormatter('#.##0,'),
    decimalFormat: getKMFormatter('#.##0,00'),
  },
  USD: {
    code: 'USD',
    sign: '$',
    integerFormat: getKMFormatter('#.##0,'),
    decimalFormat: getKMFormatter('#.##0,00'),
  },
  PEN: {
    code: 'PEN',
    sign: 'S/.',
    integerFormat: getKMFormatter('#.##0,'),
    decimalFormat: getKMFormatter('#.##0,00'),
  },
  VND: {
    code: 'VND',
    sign: '₫',
    integerFormat: getKMFormatter('#.##0,'),
    decimalFormat: getKMFormatter('#.##0,00'),
  },
  NGN: {
    code: 'NGN',
    sign: '₦',
    integerFormat: getKMFormatter('#,##0.'),
    decimalFormat: getKMFormatter('#,##0.00'),
  },
  KES: {
    code: 'KES',
    sign: 'KSh',
    integerFormat: getKMFormatter('#,##0.'),
    decimalFormat: getKMFormatter('#,##0.00'),
  },
  fallback: {
    code: 'fallback',
    sign: '$',
    integerFormat: getKMFormatter('0.'),
    decimalFormat: getKMFormatter('#.00'),
  },
}

export const setSupportedCurrencies = ({
  currency,
  code,
  sign,
  originalSign,
  integerFormat,
  decimalFormat,
}: {
  currency: string
  code?: string
  sign?: string
  originalSign?: string
  integerFormat?: CurrFormat
  decimalFormat?: CurrFormat
}) => {
  const item = supportedCurrencies[currency]
  if (item) {
    item.code = code || currency
    item.sign = sign || ''
    item.originalSign = originalSign || item.sign
    item.integerFormat = integerFormat
      ? getKMFormatter((integerFormat || '0.') as string)
      : item.integerFormat
    item.decimalFormat = decimalFormat
      ? getKMFormatter((decimalFormat || '#.00') as string)
      : item.decimalFormat
  } else {
    supportedCurrencies[currency] = {
      code: code || currency,
      sign: sign || supportedCurrencies.fallback.sign,
      integerFormat: integerFormat
        ? getKMFormatter((integerFormat || '0.') as string)
        : supportedCurrencies.fallback.integerFormat,
      decimalFormat: decimalFormat
        ? getKMFormatter((decimalFormat || '#.00') as string)
        : supportedCurrencies.fallback.decimalFormat,
    }
  }
}
function getConfig(code: string): CurrConfig {
  return supportedCurrencies[code] || supportedCurrencies.fallback
}

function convertFormat(formatter: CurrFormat, value: number): string {
  if (typeof formatter === 'string') {
    return format(formatter, value)
  }
  return formatter(value)
}

/** 获取货币符号前缀 */
export function getSignByCode(code: string): string {
  const config = getConfig(code)
  return config.sign
}

/** 整数格式转换 */
export function integerFormatByCode(code: string, value: number): string {
  const config = getConfig(code)
  return convertFormat(config.integerFormat, value)
}

/** 小数格式转换 */
export function decimalFormatByCode(code: string, value: number): string {
  const config = getConfig(code)
  return convertFormat(config.decimalFormat, value)
}
