const Tooltip =  {
    baseStyle: {
        colorScheme: {
            white: {
                bg: "gray.50",
                color: "inherit",
                "--popper-arrow-bg": "var(--chakra-colors-gray-50)"
            }
        }
    },
    sizes: {
        lg: {
            p: 5,
            borderRadius: "2xl"
        }
    }
}

export default Tooltip;