// storage key

/** 用户ID */
import Platform from '@/common/platform'
import { localStorage } from '@/utils/localStorage'

export const TOKEN = 'TOKEN'
/** 用户TOKEN */
export const USER_ID = 'USER_ID'

const win = window as any

export const getClientStorageForWebView = () => {
  const clientStorage = Platform?.getAllClientStorage()
  Object.keys(clientStorage || {})?.forEach((item: string) => {
    localStorage.setItem(item, clientStorage[item])
  })
}

/** 用户ID */
export const getUserId = (): string => {
  if (typeof window === 'undefined') {
    return ''
  }
  return localStorage.getItem(USER_ID) ?? ''
}

export const removeUserId = (): void => {
  return localStorage.removeItem(USER_ID)
}

/** 用户TOKEN */
export const getUserToken = (): string => {
  if (typeof window === 'undefined') {
    return ''
  }

  return localStorage.getItem(TOKEN) ?? ''
}

export const removeUserToken = (): void => {
  return localStorage.removeItem(TOKEN)
}

export const persistUserCredentials = (userId: string, token: string) => {
  localStorage.setItem(USER_ID, userId)
  localStorage.setItem(TOKEN, token)
}
