import type { SystemStyleObject } from '@chakra-ui/react'
import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import type { ColumnDef } from '@tanstack/react-table'
import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'

import { useCustomizedStyle } from '@/utils/hooks'

import Empty from '../empty'

export type GameTableProps<T extends object> = {
  loading?: boolean

  /** data: unit of row */
  data: T[]

  /** columns */
  columns: ColumnDef<T, any>[]

  /** 固定行数 */
  fixRow?: number
}

export const NAME = 'GameTable'
export const PARTS = ['box', 'table', 'thead', 'tbody', 'tr', 'th', 'td']

export function GameTable<T extends object>({
  data,
  columns,
  fixRow = 0,
}: GameTableProps<T>) {
  const {
    box,
    table: tableStyle,
    thead: theadStyle,
    tbody: tbodyStyle,
    tr: trStyle,
    th: thStyle,
    td: tdStyle,
  } = useCustomizedStyle(
    NAME,
    {
      box: {
        padding: '0 6',
        bgColor: 'gray.50',
        borderRadius: 'lg',
        overflowX: 'hidden',
        scrollbarGutter: 'stable',
        overflowY: 'auto',
        maxHeight: '100%',
      },
      table: {
        tableLayout: 'fixed',
      },
      thead: {
        bgColor: 'gray.50',
      },
      tbody: {},
      tr: { textAlign: 'center' },
      th: {
        textAlign: 'center',
      },
      td: { textAlign: 'center' },
    },
    PARTS,
  )
  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  })

  if (fixRow > 0 && typeof (tdStyle?.height === 'string')) {
    const fixHeight = getFixHeight(fixRow, tdStyle.height)

    // tbodyStyle.display = 'table-row-group'
    tbodyStyle.maxH = fixHeight + 'px'
    tbodyStyle.overflow = 'auto'
  }

  return (
    <TableContainer sx={box}>
      <Table sx={tableStyle} variant='fill'>
        <Thead position='sticky' top='0' zIndex='1' sx={theadStyle}>
          {table.getHeaderGroups().map(headerGroup => (
            <Tr key={headerGroup.id} sx={trStyle}>
              {headerGroup.headers.map(header => {
                // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type this correctly
                const meta: any = header.column.columnDef.meta

                return (
                  <Th
                    sx={thStyle}
                    key={header.id}
                    // onClick={header.column.getToggleSortingHandler()}
                    isNumeric={meta?.isNumeric}
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
                  </Th>
                )
              })}
            </Tr>
          ))}
        </Thead>
        <Tbody sx={tbodyStyle}>
          {(() => {
            if (data.length === 0)
              return (
                <Tr>
                  <Td colSpan={table.getAllColumns().length}>
                    <Empty />
                  </Td>
                </Tr>
              )

            return (
              <>
                {table.getRowModel().rows.map(row => (
                  <Tr key={row.id} sx={trStyle}>
                    {row.getVisibleCells().map(cell => {
                      // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type this correctly
                      const meta: any = cell.column.columnDef.meta
                      return (
                        <Td
                          key={cell.id}
                          isNumeric={meta?.isNumeric}
                          sx={tdStyle}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                        </Td>
                      )
                    })}
                  </Tr>
                ))}
              </>
            )
          })()}
        </Tbody>
      </Table>
    </TableContainer>
  )
}
export default GameTable

function getFixHeight(row: number, height: SystemStyleObject['height']): any {
  function getValidHeight(h: unknown): typeof h {
    if (typeof h === 'string') {
      if (h.endsWith('px')) {
        return parseInt(h.replace('px', '')) * row
      } else {
        return h
      }
    } else if (typeof h === 'number') {
      return h * row
    }
  }

  if (height !== undefined) {
    if (Array.isArray(height)) {
      return height.map(h => getValidHeight(h))
    } else {
      return getValidHeight(height)
    }
  }
}
