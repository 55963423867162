const AtomBadge = {
    baseStyle: {},
    sizes: {
        sm: {
            w: 2,
            h: 2
        },
        md: {
            w: 2.5,
            h: 2.5
        },
        lg: {
            w: 4,
            h: 4
        }
    },
    variants: {
        round: {
            top: "5%",
            right: "8%"
        }
    },
    defalutProps: {
        size: "md"
    }
}

export default AtomBadge;