import { Box } from '@chakra-ui/react'
import { type FC, memo, useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import type { RecoilState } from 'recoil'
import { useRecoilState, useRecoilValue } from 'recoil'

import { BetButton } from '@/modules/inhouse/common/components'

import { balanceInfoAtom, walletInfoAtom } from '../../atoms/common-state'
import { useAuthenticFn } from '../../hooks'
import { betPlinko, type BetPlinkParams, playPlinko } from './api'
import {
  blueAutoAtom,
  blueInputAtom,
  greenAutoAtom,
  greenInputAtom,
  isAutoStartedBlue,
  isAutoStartedGreen,
  isAutoStartedRed,
  PLINK_COLOR_MAP,
  type PlinkColors,
  redAutoAtom,
  redInputAtom,
} from './state'

type LogicBetProps = {
  color: PlinkColors
}

const LogicBet: FC<LogicBetProps> = ({ color }) => {
  const { isAuto: isAutoAtom } = getColorAtoms(color)
  const isAuto = useRecoilValue(isAutoAtom)

  if (isAuto) {
    return <LogicAutoBetButton color={color} />
  } else {
    return <LogicBetButton color={color} />
  }
}

export default LogicBet

const LogicBetButton: FC<LogicBetProps> = ({ color }) => {
  const { amount: amountAtom } = getColorAtoms(color)
  const amount = useRecoilValue(amountAtom)
  const { updateBalance, currency, showRecharge } =
    useRecoilValue(walletInfoAtom)
  const balance = useRecoilValue(balanceInfoAtom)
  const { enhanceAuth } = useAuthenticFn()
  const { t } = useTranslation()

  const handleBetClick = useCallback(async () => {
    if (amount > balance || balance <= 0) {
      showRecharge()
    } else {
      const params: BetPlinkParams = {
        amount,
        color,
        currency: currency.code,
      }

      const { code, data: betRes } = await betPlinko(params)
      if (code === 200) {
        updateBalance(-params.amount)
        playPlinko({
          betRes,
          color: PLINK_COLOR_MAP[color],
        })
      }
    }
  }, [amount, color, currency.code, updateBalance, balance, showRecharge])

  return (
    <Box h='60px' mt={['6px', '20px']}>
      <MemoBetButton
        maintext={t('GAME_BET')}
        variant={color}
        onClick={() => enhanceAuth(handleBetClick)()}
      />
    </Box>
  )
}

const LogicAutoBetButton: FC<{
  color: PlinkColors
}> = ({ color }) => {
  const { t } = useTranslation()
  const { isAutoStarted: isAutoStartedAtom, amount: amountAtom } =
    getColorAtoms(color)
  const [isAutoStarted, setIsAutoStarted] = useRecoilState(isAutoStartedAtom)
  const amount = useRecoilValue(amountAtom)
  const { updateBalance, currency } = useRecoilValue(walletInfoAtom)
  const balance = useRecoilValue(balanceInfoAtom)

  const handleStart = useCallback(() => {
    setIsAutoStarted(true)
  }, [])

  const handleStop = useCallback(() => {
    setIsAutoStarted(false)
  }, [])

  const handleClick = useMemo(
    () => (isAutoStarted ? handleStop : handleStart),
    [isAutoStarted],
  )
  const maintext = useMemo(
    () => (isAutoStarted ? t('GAME_AUTOPLAY_STOP') : t('GAME_AUTOPLAY_START')),
    [isAutoStarted],
  )
  const { enhanceAuth } = useAuthenticFn()
  const handleBet = useCallback(async () => {
    if (amount > balance || balance <= 0) {
      handleStop()
    } else {
      const params: BetPlinkParams = {
        amount,
        color,
        currency: currency.code,
      }
      const { code, data: betRes } = await betPlinko(params)
      if (code === 200) {
        updateBalance(-params.amount)
        playPlinko({
          betRes,
          color: PLINK_COLOR_MAP[color],
        })
      }
    }
  }, [amount, balance, color, currency.code, updateBalance])

  const handleBetClick = useCallback(() => {
    enhanceAuth(handleClick)()
  }, [enhanceAuth, handleClick])

  useEffect(() => {
    let timer: any
    if (isAutoStarted) {
      timer = setInterval(() => handleBet(), 1000)
    }

    return () => {
      timer && clearInterval(timer)
      timer = undefined
    }
  }, [handleBet, isAutoStarted])

  return (
    <Box h='60px' mt={['6px', '20px']}>
      <MemoBetButton
        maintext={maintext}
        variant={color}
        onClick={handleBetClick}
      />
    </Box>
  )
}

const MemoBetButton = memo(BetButton)

const blue = {
  amount: blueInputAtom,
  isAuto: blueAutoAtom,
  isAutoStarted: isAutoStartedBlue,
}

const green = {
  amount: greenInputAtom,
  isAuto: greenAutoAtom,
  isAutoStarted: isAutoStartedGreen,
}

const red = {
  amount: redInputAtom,
  isAuto: redAutoAtom,
  isAutoStarted: isAutoStartedRed,
}

export function getColorAtoms(color: PlinkColors): {
  amount: RecoilState<number>
  isAuto: RecoilState<boolean>
  isAutoStarted: RecoilState<boolean>
} {
  switch (color) {
    case 'blue':
      return blue
    case 'green':
      return green
    case 'red':
      return red
    default: {
      const exhanustiveCheck: never = color
      return exhanustiveCheck
    }
  }
}
