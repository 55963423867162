const AtomGameCard = {
    baseStyle: {
        gamecard: {
            borderRadius: "lg"
        },
        gamecardimg: {
            _groupHover: {
                filter: "blur(5px)",
                transform: "scale(1.1)",
                transition: "all .2s"
            }
        },
        gamecardhover: {}
    }
}

export default AtomGameCard;