const Slider = {
    baseStyle: {
        colorScheme: {
            white: {
                filledTrack: {
                    bg: "white"
                },
                track: {
                    bg: "whiteAlpha.300"
                }
            }
        }
    },
    variants: {},
    sizes: {},
    defaultProps: {}
}

export default Slider;