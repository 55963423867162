
import { getCurrencyCode } from '@/common/locale/currency'
import i18n from '@/common/locale/i18n'
import property from '@/websites/current/property'

/** call this when all res are prepared */
export function initDynamicI18n() {
  const dynamicI18n = {
    APP_NAME: property.appName,
    currency: getCurrencyCode(),
    domain: property.appName,
    website: 'https://' + property.domain,
    proName: property.appName,
    TelegramLink: property?.social?.telegramLink || '',
    ...(property.i18n_dyn || {}),
  }
  i18n.addResources(i18n.language, 'dyn', dynamicI18n)
}

export function changeLanguage(lang: string) {
  i18n.changeLanguage(lang)
  initDynamicI18n()
}

