const tokenColors = {
    base: "",
    placeholder: "",
    sectionHeading: "prim.400",
    tab: "",
    tabActive: "",
    ranking1: "tert.400",
    ranking2: "#CCE9FF",
    ranking3: "tert.500",
    tipsLight: "prim.500",
    progressText: ""
}
export default tokenColors;