const AchievementBonus = {
    baseStyle: {
        card: {
            layerStyle: "ls-prim"
        },
        actTable: {
            layerStyle: "ls-white-transparent"
        }
    },
    defaultProps: {}
}

export default AchievementBonus;