const TopBanner = {
    baseStyle: {
        card: {
            layerStyle: "ls-accent-to-b"
        },
        button: {
            bg: "prim.500"
        }
    }
}

export default TopBanner;