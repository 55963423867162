import { Box, Center, Container, Flex, Grid } from '@chakra-ui/react'
import type { FC, PropsWithChildren } from 'react'
import React, {
  useMemo,
} from 'react'
import {
  Outlet,
  useLocation,
  useMatch,
} from 'react-router-dom'
import Splash from './Splash'
import { useAppDispatch, useAppSelector } from '@/modules/app/store'
import { useCurrencyBindingBlocker } from '@/modules/bind-currency/useCurrencyBindingBlocker'
import { useCheckValidGame } from '@/utils/hooks'
import useLoader from '@/utils/hooks/useLoader'
import { getDeviceType } from '@/utils/tools'

import { afterLoad, initLoad } from '../app/initLoad'
import {
  createDrawerOpenSelector,
  DrawerKey,
  gridTransitionEnd,
} from './app-layout-slice'
import {
  LEFT_DRAWER_WIDTH,
  RIGHT_DRAWER_WIDTH,
} from './constants'
import QueriesOnAppInit from './QueriesOnAppInit'
import { useBalanceUpdater } from './useBalanceUpdater'


const DEVICE = getDeviceType()

function parseQueryString(queryString: string) {
  const params: Record<string, any> = {}
  const pairs = queryString.split('&')

  for (let i = 0; i < pairs.length; i++) {
    const pair = pairs[i].split('=')
    const key = decodeURIComponent(pair[0])
    const value = decodeURIComponent(pair[1] || '')

    params[key] = value
  }

  return params
}

export default function AppCore() {
  const [isLoading] = useLoader(initLoad, afterLoad)
  return (
    <>
      <QueriesOnAppInit />
      {isLoading ? (
        <FullScreen>
          <Splash />
        </FullScreen>
      ) : (
        <AppLayout />
      )}
    </>
  )
}

function AppLayout() {
  return (
    <div>
      <BodyLayout>
        <div></div>
        <Box overflowX='hidden' bg='bg.appLayout.midCol'>
          <Flex bg='bg.appLayout.content' pt='env(safe-area-inset-top)'>
            <MainContainer>
              <Outlet />
            </MainContainer>
          </Flex>
        </Box>
      </BodyLayout>
      <AppEffects />
    </div>
  )
}


function CurrencyBindingBlocke() {
  useCurrencyBindingBlocker()
  useCheckValidGame()
  return null
}
function AppEffects() {
  // useRedirectWithQuery()
  // usePageChangeNotification()
  // useRegionLimitGuard()
  // usePageViewTracker()
  useBalanceUpdater()
  // useOpenAddToHomeScreenModalWithParams()

  return <CurrencyBindingBlocke />
}

function BodyLayout(props: { children: React.ReactNode }) {
  const dispatch = useAppDispatch()

  const isLeftDrawerOpen = useAppSelector(
    createDrawerOpenSelector(DrawerKey.Left),
  )

  const isRightDrawerOpen = useAppSelector(
    createDrawerOpenSelector(DrawerKey.Right),
  )

  const handleGridTranslationEnd: React.TransitionEventHandler<
    HTMLDivElement
  > = e => {
    e.preventDefault()
    if (e.target === e.currentTarget) {
      dispatch(gridTransitionEnd())
    }
  }

  return (
    <Grid
      // paddingTop='64px'
      w='full'
      templateColumns={[
        '0px 1fr 0px',
        `${isLeftDrawerOpen ? LEFT_DRAWER_WIDTH : '0px'} 1fr ${isRightDrawerOpen ? RIGHT_DRAWER_WIDTH : '0px'
        }`,
      ]}
      onTransitionEnd={handleGridTranslationEnd}
      transition={['none', 'all 0.3s']}
    >
      {props.children}
    </Grid>
  )
}

function MainContainer(props: { children: React.ReactNode }) {
  const { pathname } = useLocation()
  const matchActivity = useMatch('/activity/:actType/*')
  const isActivtiyRoute = !!matchActivity
  const style = useMemo(() => {
    const fullScreenStyle = {
      maxWidth: 'inherit',
      minHeight: 'lg',
      padding: '0',
    }
    if (pathname === '/user/sport') {
      fullScreenStyle.padding = '5px'
      return fullScreenStyle
    } else if (isActivtiyRoute) {
      return fullScreenStyle
    } else {
      return {
        maxWidth: 'container.lg',
        minHeight: 'lg',
        pb: '6',
        px: { base: '2.5', md: '0' },
      }
    }
  }, [pathname, isActivtiyRoute])

  return (
    <Container
      position='relative'
      as='main'
      boxSizing='content-box'
      overflowX='hidden'
      sx={style}
    >
      {props.children}
    </Container>
  )
}

const FullScreen: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Center position='fixed' w='100vw' h='100vh'>
      {children}
    </Center>
  )
}
