const TableWithTabs = {
    parts: ["tablepanel", "tablist", "tablistContainer"],
    baseStyle: {
        tablistContainer: {
            layerStyle: "ls-accent"
        },
        tablepanel: {
            bg: "gray.400"
        }
    },
    defaultProps: {}
}

export default TableWithTabs;