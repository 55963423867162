import AchievementBonus from './AchievementBonus'
import ActionsBarStyle, { name as ActionsBarName } from './ActionsBar'
import AmountControl from './AmountControl'
import AppLayout from './AppLayout'
import AtomActiveLinkStyle, { name as AtomActiveLink } from './AtomActiveLink'
import AtomBadge from './AtomBadge'
import AtomCard from './AtomCard'
import AtomDatePicker from './AtomDatePicker'
import AtomEmpty from './AtomEmpty'
import AtomGameCard from './AtomGameCard'
import AtomNavAccordionItemStyle, {
  name as AtomNavAccordionItemName,
} from './AtomNavAccordionItem'
import AtomSelect from './AtomSelect'
import AutoBetConfigStyle, { name as AutoBetConfigName } from './AutoBetConfig'
import BetButtomStyle, { name as BetButtomName } from './BetButton'
import BonusesNowIssued from './BonusesNowIssued'
import Button from './Button'
import Campaign from './Campaign'
import CashoutStyle, { name as CashoutName } from './Cashout'
import Checkbox from './Checkbox'
import CopyInput from './CopyInput'
import Drawer from './Drawer'
import FormError from './FormError'
import FormLabel from './FormLabel'
import GameTableStyle, { name as GameTableName } from './GameTable'
import GroupGame from './GroupGame'
import H24HoursRank from './H24HoursRank'
import Heading from './Heading'
import Input from './Input'
import InviteExplanation from './InviteExplanation'
import InviteGetCommission from './InviteGetCommission'
import InvitePartner from './InvitePartner'
import Link from './Link'
import LoginAndSignUp from './LoginAndSignUp'
import MinesSelectorStyle, { name as MinesSelectorName } from './MinesSelector'
import Modal from './Modal'
import Notification from './Notification'
import PersonalCenterProfileCard from './PersonalCenterProfileCard'
import PersonalCenterVipCard from './PersonalCenterVipCard'
import PersonalCenterWalletCard from './PersonalCenterWalletCard'
import PinInput from './PinInput'
import PlinkAmountControlStyle, {
  name as PlinkAmountControlName,
} from './PlinkAmountControl'
import Popover from './Popover'
import Progress from './Progress'
import RankingHeadBoxStyle, {
  name as RankingHeadBoxName,
} from './RankingHeadBox'
import Slider from './Slider'
import Stat from './Stat'
import Table from './Table'
import TableWithTabs from './TableWithTabs'
import Tabs from './Tabs'
import Tag from './Tag'
import Text from './Text'
import Tooltip from './Tooltip'
import TopBanner from './TopBanner'
import TotalProfitCard from './TotalProfitCard'
import V24HoursRank from './V24HoursRank'
import VipInfo from './VipInfo'

const components = {
  AppLayout,
  [AtomActiveLink]: AtomActiveLinkStyle,
  AtomBadge,
  AtomCard,
  AtomDatePicker,
  AtomEmpty,
  Button,
  Campaign,
  CopyInput,
  Checkbox,
  Link,
  FormError,
  FormLabel,
  Heading,
  Modal,
  Tabs,
  Table,
  Slider,
  Stat,
  Input,
  InviteExplanation,
  InviteGetCommission,
  InvitePartner,
  AchievementBonus,
  BonusesNowIssued,
  TotalProfitCard,
  AtomGameCard,
  AtomSelect,
  PersonalCenterProfileCard,
  PersonalCenterVipCard,
  PersonalCenterWalletCard,
  Progress,
  Popover,
  TableWithTabs,
  Tag,
  Tooltip,
  Text,
  TopBanner,
  LoginAndSignUp,
  Notification,
  AmountControl,
  [CashoutName]: CashoutStyle,
  [ActionsBarName]: ActionsBarStyle,
  [BetButtomName]: BetButtomStyle,
  [AutoBetConfigName]: AutoBetConfigStyle,
  [PlinkAmountControlName]: PlinkAmountControlStyle,
  [GameTableName]: GameTableStyle,
  [MinesSelectorName]: MinesSelectorStyle,
  [RankingHeadBoxName]: RankingHeadBoxStyle,
  [AtomNavAccordionItemName]: AtomNavAccordionItemStyle,
  V24HoursRank,
  H24HoursRank,
  VipInfo,
  PinInput,
  GroupGame,
  Drawer
}

export default components
