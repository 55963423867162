import type { RouteObject } from 'react-router-dom'
import { createBrowserRouter } from 'react-router-dom'

import NotFound from '@/components/not-found/NotFound'
import AppLayout from '@/modules/app-layout/AppLayout'

import { gameRouter } from './game'

const allRouterList: RouteObject[] = [
  {
    path: '/',
    element: <AppLayout />,
    children: [
      {
        path: '*',
        element: <NotFound />,
      },
      ...gameRouter,
    ],
  },
]

export const router = createBrowserRouter(allRouterList)
