import type { InHouseGameId } from '../game/logic/game-list/data/inhouseGames'

export interface Balance {
  amount: number
  currency: string
  withdrawable_amount: number
  bet_progress: number
  bet_target: number
}

export enum CurrencyType {
  PHP = 'PHP',
}

interface Bank {
  accountname: string
  phone: string
  pixkey: string
  pixtype: string
}

export interface User {
  _id: string
  account: string
  activities: Record<string, Activity>
  avatar: string
  balance_map: Record<CurrencyType, Balance>
  bank_br: Record<string, Bank>
  bet_amount: Record<CurrencyType, BetAmount>
  bet_limit: unknown
  blocked: boolean
  country_code: string
  create_at: string
  first_name: string
  from: unknown
  from_ext: unknown
  from_id: unknown
  game_history: Record<InHouseGameId, GameRecord> // game id as key
  gender: string
  ip: string
  is_email_verified: boolean
  is_verified: boolean
  last_name: string
  login_at: string
  nick_name: string
  open_google: unknown
  open_meta: unknown
  phone: string
  platform: string
  profit_amount: Record<CurrencyType, BetAmount>
  recharge_amount: Record<CurrencyType, BetAmount>
  recharge_limit: unknown
  reward_counter: unknown[]
  spread_info: SpreadInfo
  task_data: unknown[]
  tax_id: string | null
  ua: string
  update_at: string
  user_type: string
  vip: number
  vip_award: Record<string, string>
  vip_info: VipInfo
  withdraw_amount: Record<CurrencyType, BetAmount>
}

export interface Activity {
  counter: number
  start_time: number
}

export interface BetAmount {
  amount: number
  count: number
}

export interface GameRecord {
  current_seed: string[]
  game_id: string
  game_name: string
  last_play_at: string
  previous_seed: string[]
}

export interface SpreadInfo {
  child_first_recharge_count: number
  spread_award_amount: Record<CurrencyType, string>
  spread_bet_amount: Record<CurrencyType, string>
  spread_bet_award_rate: unknown
  spread_children: unknown[]
  spread_children_count: number
  spread_grand_children_count: number
  spread_grand_parent: unknown
  spread_grandgrand_children_count: number
  spread_grandgrand_parent: unknown
  spread_parent: string | null
  spread_recharge_award_rate: unknown
}

export interface VipInfo {
  bet: number
  bet_refund: number
  bet_refund_map: Record<InHouseGameId, number>
  recharge: number
  withdraw_count: number
  withdraw_free_amount: number
}

export interface BetRefundMap {
  coinflip: number
  dice: number
  double: number
  mines: number
  roulette: number
}

export interface AccountBindInfo {
  bind_email: number
  bind_facebook: number
  bind_google: number
}

export interface GoogleUserInfo {
  iss: string
  nbf: number
  aud: string
  sub: string
  email: string
  email_verified: boolean
  azp: string
  name: string
  picture: string
  iat: number
  exp: number
  jti: string
}

export enum ClientType {
  PcWeb = 'pc_web',
  IosWeb = 'ios_web',
  IosApp = 'ios_app',
  AndroidWeb = 'android_web',
  AndroidApk = 'android_apk',
  Other = 'other',
}
