const Tabs =  {
    tabs: ["tab", "tablist", "tabpanel"],
    baseStyle: {
        colorScheme: {
            referral: {
                root: {
                    "--tab-color": "var(--chakra-colors-text-base)",
                    "--tab-color-selected": "var(--chakra-colors-white)",
                    "--tab-list-bg-color": "var(--chakra-colors-bg-control)"
                },
                tab: {
                    overflow: "hidden",
                    _selected: {
                        layerStyle: "ls-prim"
                    }
                }
            },
            game: {
                root: {
                    "--tab-color": "var(--chakra-colors-text-secondary)",
                    "--tab-color-selected": "var(--chakra-colors-white)",
                    "--tab-list-bg-color": "var(--chakra-colors-bg-control)"
                },
                tab: {
                    _selected: {
                        "--icon-brightness": 999,
                        layerStyle: "ls-prim"
                    }
                }
            },
            "second-game": {
                root: {
                    "--tab-color": "var(--chakra-colors-gray-700)",
                    "--tab-bg-color": "var(--chakra-colors-gray-400)",
                    "--tab-icon-color": "var(--chakra-colors-text-base)",
                    "--tab-color-selected": "var(--chakra-colors-gray-900)",
                    "--tab-border-color": "var(--chakra-colors-prim-500)"
                },
                tab: {
                    bgColor: "var(--tab-bg-color)",
                    overflow: "hidden",
                    _selected: {
                        "--tab-icon-color": "var(--chakra-colors-white)"
                    }
                }
            },
            "announce-tab": {
                root: {
                    "--tab-color": "rgba(255, 255, 255, 0.60)",
                    "--tab-bg-color": "rgba(255, 255, 255, 0.20)",
                    "--tab-bg-color-selected": "var(--chakra-colors-prim-500)",
                    "--tab-icon-color": "var(--chakra-colors-text-base)",
                    "--tab-color-selected": "white",
                    "--tab-border-color": "rgba(255, 255, 255, 0.20)"
                },
                tab: {
                    bgColor: "var(--tab-bg-color)",
                    overflow: "hidden",
                    _selected: {
                        "--tab-icon-color": "var(--chakra-colors-white)",
                        "--tab-bg-color": "var(--tab-bg-color-selected)",
                        "--tab-border-color": "var(--tab-bg-color-selected)"
                    }
                }
            }
        }
    },
    variants: {
        "solid-rounded": {
            tab: {
                display: "inline-block",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                px: 1,
                borderRadius: "lg",
                flex: "1",
                color: "gray.900",
                fontSize: "14px",
                fontWeight: "extrabold",
                lineHeight: "18px",
                _selected: {
                    layerStyle: "ls-seco",
                    "--tabs-bg": "none"
                }
            },
            tablist: {
                height: 10,
                w: "full",
                borderRadius: "xl",
                bgColor: "gray.500"
            },
            tabpanel: {
                p: 0
            }
        },
        "special-solid-rounded": {
            tab: {
                display: "inline-block",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                px: 1,
                borderRadius: "lg",
                flex: "1",
                color: "white",
                fontSize: "14px",
                fontWeight: "extrabold",
                lineHeight: "14px",
                _selected: {
                    layerStyle: "ls-seco-to-r",
                    "--tabs-bg": "none",
                    margin: "1",
                    borderRadius: "20"
                }
            },
            tablist: {
                height: 10,
                w: "full",
                borderRadius: "120px",
                bgColor: "#3b3f47",
                backdropFilter: "blur(12px)"
            },
            tabpanel: {
                p: 0
            }
        },
        "square-rounded": {
            tablist: {
                bgColor: "gray.400",
                borderRadius: "lg",
                overflow: "hidden",
                color: "gray.50"
            },
            tab: {
                fontSize: "14px",
                fontWeight: "extrabold",
                lineHeight: "18px",
                borderRadius: "lg",
                color: "primary",
                _selected: {
                    layerStyle: "ls-accent"
                }
            }
        },
        game: {
            tablist: {
                bgColor: "var(--tab-list-bg-color)",
                borderRadius: "xl",
                p: "1.5"
            },
            tab: {
                borderRadius: "lg",
                h: "10",
                color: "gray.700",
                _selected: {
                    color: "var(--tab-color-selected)",
                    alignItems: "center"
                }
            }
        },
        "second-game": {
            tablist: {
                overflow: "auto",
                "--scrollbar-width": "0",
                borderRadius: "xl",
                p: "1.5"
            },
            tab: {
                bgColor: "var(--tab-bg-color)",
                borderRadius: "lg",
                h: "10",
                whiteSpace: "nowrap",
                color: "var(--tab-color)",
                flexShrink: 0,
                mr: "8px",
                _selected: {
                    color: "var(--tab-color-selected)",
                    alignItems: "center",
                    border: "2px solid var(--tab-border-color)"
                }
            }
        },
        "announce-tab": {
            tablist: {
                overflow: "auto",
                "--scrollbar-width": "0",
                borderRadius: "xl"
            },
            tabs: {},
            tab: {
                bgColor: "var(--tab-bg-color)",
                borderRadius: "lg",
                height: "34px",
                whiteSpace: "nowrap",
                color: "var(--tab-color)",
                flexShrink: 0,
                mr: "8px",
                mb: "12px",
                border: "2px solid var(--tab-bg-color)",
                _selected: {
                    color: "var(--tab-color-selected)",
                    alignItems: "center",
                    border: "2px solid var(--tab-bg-color-selected)"
                }
            }
        },
        "capsule-shape": {
            tablist: {
                borderRadius: "full",
                p: "1",
                overflow: "hidden",
                backdropFilter: "blur(12px)",
                bg: "whiteAlpha.700"
            },
            tab: {
                flex: "1",
                borderRadius: "full",
                overflow: "hidden",
                maxW: 40,
                w: 40,
                h: 8,
                px: 1,
                fontSize: "12px",
                lineHeight: "18px",
                fontWeight: "700",
                color: "gray.700",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                _selected: {
                    color: "white",
                    layerStyle: "ls-prim"
                }
            }
        },
        "solid-rounded-split": {
            tab: {
                minW: "24",
                px: "2",
                display: "inline-block",
                overflow: "hidden",
                borderRadius: "lg",
                flex: "1",
                color: "white",
                fontSize: "12px",
                fontWeight: "800",
                lineHeight: "16px",
                bgColor: "gray.500",
                _notLast: {
                    mr: "2"
                },
                _selected: {
                    bg: "seco.500",
                    "--tabs-bg": "none"
                }
            },
            tablist: {
                display: "inline-flex",
                overflow: "hidden"
            }
        }
    }
}

export default Tabs;