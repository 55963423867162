const AtomCard = {
  baseStyle: {
    colorScheme: {
      silver: {
        container: {
          layerStyle: "ls-silver",
        },
        borderBox: {
          borderColor: "whiteAlpha.200",
        },
      },
      prim: {
        container: {
          layerStyle: "ls-accent-to-b",
        },
        borderBox: {
          borderColor: "whiteAlpha.200",
        },
      },
      "fill-accent-hori": {
        container: {
          bgGradient: {
            base: "linear-gradient(0deg, #FF4040 0%, #F94D72 44%, #9573EE 70%, #235CF1 100%)",
            lg: "linear-gradient(270deg, #FF4040 0%, #F94D72 44%, #9573EE 70%, #235CF1 100%)",
          },
        },
        borderBox: {
          borderColor: "whiteAlpha.200",
        },
      },
      "fill-accent-vert": {
        container: {
          bgGradient:
            "linear-gradient(0deg, #FF4040 0%, #F94D72 44%, #9573EE 70%, #235CF1 100%)",
        },
        borderBox: {
          borderColor: "whiteAlpha.200",
        },
      },
      white: {
        container: {
          bgColor: "bg.control",
          color: "text.accent",
          fill: "text.accent",
        },
        borderBox: {
          borderColor: "whiteAlpha.100",
        },
      },
      whiteMultilayer: {
        container: {
          bgColor: "gray.500",
          color: "text.accent",
          fill: "text.accent",
          "--content-bg": "var(--chakra-colors-bg-content)",
        },
        borderBox: {
          borderColor: "whiteAlpha.100",
        },
      },
      transparent: {
        container: {
          bgColor: "rgba(255,255,255,0.1)",
        },
        borderBox: {
          borderColor: "rgba(255,255,255, 0)",
        },
      },
    },
  },
  variants: {
    round: {
      container: {
        borderRadius: "2xl",
      },
    },
  },
  defaultProps: {
    variant: "round",
    colorScheme: "prim",
  },
};

export default AtomCard;
