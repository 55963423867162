import { floor } from 'lodash-es'

import { isProduction } from '@/common/env'

type EventCategory = 'inhouse_cost' | 'common_cost'

interface LogParams {
  eventName: string
  eventCategory: EventCategory
  eventLabel?: string
  value: string | number
}
export default class PerformanceMonitor {
  private static instance: PerformanceMonitor

  private currentStart?: number

  private constructor() {}

  public static getInstance(): PerformanceMonitor {
    if (!PerformanceMonitor.instance) {
      PerformanceMonitor.instance = new PerformanceMonitor()
    }
    return PerformanceMonitor.instance
  }

  public setStart(number: number) {
    this.currentStart = number
  }
  public getStart(): number {
    return this.currentStart ?? -1
  }
  public removeCurrentStart(): void {
    this.currentStart = undefined
  }

  /**
   *
   * @returns 进入路由到此方法的耗时, 毫秒，精度2
   */
  public getCurrentPageCost(): number {
    const start = this.getStart()
    if (start === -1) return -1
    return floor(performance.now() - start, 2)
  }

  public resetAllProperties(): void {
    this.currentStart = undefined
  }

  public destory(): void {
    this.resetAllProperties()
    PerformanceMonitor.instance = null as any
  }

  public log({
    eventName,
    value,
    eventCategory,
    eventLabel = 'cost',
  }: LogParams) {
    const gaParams = {
      event_category: eventCategory,
      event_label: eventLabel,
      value,
    }
    if (isProduction() && typeof window.gtag === 'function') {
      gtag('event', eventName, gaParams)
    } else {
      // console.log('ga', gaParams)
    }
  }
}

export const pm = PerformanceMonitor.getInstance()
