const PersonalCenterVipCard = {
    baseStyle: {
        card: {
            layerStyle: "ls-accent-to-b",
            "--target-value-color": "var(--chakra-colors-four-500)"
        }
    },
    defaultProps: {}
}

export default PersonalCenterVipCard;