import * as Sentry from "@sentry/react";

import { isProduction } from "@/common/env";
import { warn } from "@/common/logger";
import { getSocket } from "@/common/ws";
import {
  getUserId,
  getUserToken,
  removeUserId,
  removeUserToken,
} from "@/modules/user/storage";

import type { BetInfo } from "../logic/atoms/common-state";
import type { RequestRes } from "./request";
import request from "./request";

export function getApiUrl(suffix?: string) {
  return suffix
    ? window.GAME_INFO.apiBaseUrl + suffix
    : window.GAME_INFO.apiBaseUrl;
}

export function getWssUrl(suffix?: string) {
  return suffix
    ? window.GAME_INFO.wssBaseUrl + suffix
    : window.GAME_INFO.wssBaseUrl;
}

export function getGameUrl() {
  return window.GAME_INFO.gameBaseUrl;
}

export const post = async (url: string, data: any, check_login?: boolean) => {
  const res = await request({
    method: "post",
    url: getApiUrl(url),
    timeout: 60000,
    data,
  });
  if (!check_login && res?.data?.code === 1002) {
    try {
      if (isProduction()) {
        Sentry.captureMessage("auth_error", {
          extra: {
            err_msg: JSON.stringify(res || {}),
            err_params: JSON.stringify(data || {}),
            err_url: url,
          },
        });
      }
    } catch (e) {
      //
    }
    setTimeout(() => {
      window.location.reload();
    }, 500);
    return;
  }

  return res?.data;
};

export async function postWS(
  command: any,
  ...params: any
): Promise<RequestRes> {
  const socket = getSocket().socket;

  function doPost(resolve: (res: any) => void, reject: (err: string) => void) {
    if (socket) {
      socket.emit(command, ...params, (res: RequestRes) => {
        if (res.code === 519) {
          setTimeout(() => doPost(resolve, reject), 300);
        } else if (res.code === 1002) {
          try {
            if (isProduction()) {
              Sentry.captureMessage("inhouse_ws_error", {
                extra: {
                  err_msg: JSON.stringify(res || {}),
                  err_command: command,
                  err_params: JSON.stringify(params || {}),
                },
              });
            }
          } catch (e) {
            //
          }
          warn(JSON.stringify(res));
        } else {
          resolve(res);
        }
      });
    } else {
      reject("FAIL_TO_CONNECT");
    }
  }

  return new Promise(doPost);
}

export const postTakeTokenWithoutAuth = async (
  url: string,
  data: any,
  login?: boolean
) => {
  return postTakeToken(url, data, login, {disableAuthHeader: true})
}

export const postTakeToken = async (
  url: string,
  data: any,
  login?: boolean,
  options = {
    disableAuthHeader: false,
  }
) => {
  const headConfig = { Authorization: `${getUserId()};${getUserToken()}` };
  const res = await request({
    method: "post",
    url: getApiUrl() + url,
    timeout: 60000,
    headers: options?.disableAuthHeader ? undefined : headConfig,
    data,
  });

  if (!login && res?.data?.code === 1002) {
    removeUserId();
    removeUserToken();
    try {
      if (isProduction()) {
        Sentry.captureMessage("auth_error", {
          extra: {
            err_msg: JSON.stringify(res || {}),
            err_headers: JSON.stringify(headConfig || {}),
            err_params: JSON.stringify(data || {}),
            err_url: url,
          },
        });
      }
    } catch (e) {
      //
    }
    setTimeout(() => {
      window.location.reload();
    }, 500);

    return;
  }

  return res?.data;
};

const URL_ALL_HISTORY = "/game/list_all";
export async function getAllBetHistory(
  game: string
): Promise<RequestRes<BetInfo[]>> {
  return postTakeToken(URL_ALL_HISTORY, {
    size: 30,
    game_id: game,
  });
}

const URL_HISTORY = "/game/list";
export async function getBetHistory(
  game: string,
  listType: "mine" | "all"
): Promise<RequestRes<BetInfo[]>> {
  if (!getUserToken() || !getUserId()) {
    return Promise.resolve({ code: 400, data: [] });
  }
  return postTakeToken(URL_HISTORY, {
    token: getUserToken(),
    user_id: getUserId(),
    list_type: listType,
    game_id: game,
  });
}
