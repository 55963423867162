import type { Action } from '@/utils/hooks/useActionTrigger'
import { getDeviceType } from '@/utils/tools'

const DEVICE = getDeviceType()

const CURRENT_SCOPE = {
  apk: 'APPANDIOS',
  'android-desktop-icon': 'WEBPAGE',
  android: 'WEBPAGE',
  pc: 'WEBPAGE',
  ios: 'WEBPAGE',
  'ios-desktop-icon': 'APPANDIOS',
}[DEVICE] as 'WEBPAGE' | 'APPANDIOS'

// 安装应用配置设备枚举
export enum ACTION_SYSTEM_TYPE {
  IOS = 1,
  ANDROID = 2,
  WEB = 3,
}

/**
 * 显示设备
 */
export enum SCOPE_TYPE {
  ALL = 0,
  WEBPAGE = 1,
  APPANDIOS = 2,
}

// 侧边栏banner模块
interface SideBarBannerConfigProps {
  id: string //  侧边栏bannerid
  type: number // banner类型 （1：大Banner；2: 小Banner;）
  language_content: Record<
    string,
    {
      image: string // 多语言图片
    }
  >
  action: Action // 跳转相关
  status: number // 禁启用状态（1：启用；0：停用）
  scope?: SCOPE_TYPE // 显示设备
}

// 侧边栏下拉模块
interface SideBarDropDownConfigProps {
  language_content: Record<
    string,
    {
      title: string // 标题
      btn_text: string // 按钮文案
    }
  >
  image: string // 图标Url
  btn_url: string // 跳转链接
  action: Action // 跳转相关
}

// 侧边栏菜单项
interface SideBarMenuItemProps {
  id: string //  侧边栏bannerid
  icon: string // 未选中图标Url
  actived_icon: string // 选中图标Url
  action: Action // 跳转相关
  status: number // 禁启用状态（1：启用；0：停用）
  language_content: Record<
    string,
    {
      title: string // 标题
    }
  >
  scope?: SCOPE_TYPE // 显示设备
}

interface ResponsiveImage {
  image: string // for Desktop Device
  mobile_image?: string //for Mobile Device
}

// 首页banner配置
export interface HomePageBannerProps {
  id: string // banner Id
  type: number // banner类型 （1：大Banner；2: 小Banner; 3：固定Banner）
  action: Action // 跳转相关
  status: number // 禁启用状态（1：启用；0：停用）
  language_content: Record<string, ResponsiveImage>
  scope?: SCOPE_TYPE // 显示设备
}

export interface LoginRegisterBanner {
  status: number
  sort_weight: number
  current_language: string
  action: Action
  tl_img_url: string
  en_img_url: string
  language_content: Languagecontent
  updated_time: string
  operator: string
  scene: string[]
}

export interface LoginRegisterContent {
  current_language: string
  language_content: Languagecontent
  showBtn: string[]
  image: string
}

interface Languagecontent {
  tl: Tl
  en: Tl
  [key: string]: Tl
}

interface Tl {
  icon_text: string
}

interface Languagecontent {
  tl: Tl
  en: Tl
}

interface Tl {
  image: string
}

// 首页悬浮按钮配置
interface HomePageHoverButtonProps {
  id: string // id
  type: number // 悬浮按钮类型(1：签到按钮；2：在线客服按钮)
  status: number // 禁启用状态（1：启用；0：停用）
  image?: string // Url 为空时请用c端写死图片
  language_content?: Record<
    string,
    {
      text?: string // 文案 为空时请用c端写死文案
      image?: string // Url 为空时请用c端写死图片
    }
  >
}

// 充值弹窗按钮规则
interface RechargeBtnRuleConfigProps {
  btn_num: number // 每行按钮数量
  btn_non_participation: number // 选择框状态（1：启用；0：停用）
}

// 充值快捷按钮配置
interface RechargeQuickerBtnConfigProps {
  currency_content: Record<
    string,
    {
      btn_amount: number // 按钮金额
    }
  >
  status: number // 禁启用状态（1：启用；0：停用）
}

// 安装应用配置
export interface ActionSystemProps {
  id: string //1：iOS；2：Android；3：Web
  type: ACTION_SYSTEM_TYPE //1：iOS；2：Android；3：Web
  label: string //iOS||Android||Web
  status: STATUS_TYPE // 禁启用状态（1：启用；0：停用）
  app_url: string //app链接
  code_img_url: string //二维码
  app_img_url?: string //ios图片
}

/** 禁启用状态 */
export enum STATUS_TYPE {
  OFF = 0,
  ON = 1,
}

/** 功能模块枚举 */
export enum FUNCTION_TYPE {
  WITHDRAW,
  VIP_REWARD,
  CASHBACK,
  AGENT,
  LOGIN_REWARD,
  WHEEL,
  COUPON,
  DAILY_TASK,
}

/** 平台枚举 */
export enum DEVICE_TYPE {
  WEB = 'web',
  ANDROID = 'android',
  IOS = 'ios',
}

/** 配置项 */
export interface DiversionItemProps {
  id?: string // id
  android_url?: string // 安卓按钮点击跳转链接
  ios_url?: string // IOS按钮点击跳转链接
  web_url?: string // web二维码图片
  language_content?: Record<
    string,
    {
      content: string // 弹窗内容
      image: string // 弹窗图片
    }
  > // 多语言map
  function_type: FUNCTION_TYPE // 功能
  status: STATUS_TYPE // 禁启用状态（1：启用；0：停用）
}

//排行榜管理
interface HomePageRankProps {
  mobile_show: number // 电脑端：（1：展示；0：不展示）
  pc_show: number // 移动端：（1：展示；0：不展示）
}

// homePage配置
export interface HomePageConfig {
  // 侧边栏配置
  side_bar_banner_config: SideBarBannerConfigProps[]
  side_bar_dropdown_config: SideBarDropDownConfigProps
  side_bar_dropdown_menu: SideBarMenuItemProps[]
  side_bar_bottom_menu: SideBarMenuItemProps[]

  // 首页配置
  homepage_big_banner: HomePageBannerProps[]
  homepage_small_banner: HomePageBannerProps[]
  homepage_fixed_banner: HomePageBannerProps[]
  homepage_hover_button: HomePageHoverButtonProps[]
  homepage_rank_manage: HomePageRankProps //排行榜管理
  // 充值弹窗配置
  recharge_btn_rule_config: RechargeBtnRuleConfigProps
  recharge_quicker_btn_config: RechargeQuickerBtnConfigProps[]

  // 分享短链配置
  share_url: { domain: string }

  // 引流下载弹窗配置
  app_download_diversion: DiversionItemProps[]

  // 登录注册banner
  login_register_banner: LoginRegisterBanner[]

  // 登录注册logo
  login_register_content: LoginRegisterContent
  // 移动端底部导航
  footer_bar_menu: MobileBarMenuProps[]

  // 底部推荐
  bottom_recommend: BottomRecommend[]

  // 顶部通知
  top_promotion_bar: topPromotionsConfig[]
}

export interface Banner {
  key: string
  action: Action
  imageSrc: string
  mobileImageSrc?: string
}

interface MenuItem {
  key: string
  action: Action
  icon: string
  imageSrc: string
  iconActive: string
  text: string
}

export interface SideBarDropDown {
  title: string
  imageSrc: string
  btnText: string
  action: Action
  items: MenuItem[]
}

export enum HOME_HOVER_BTN_TYPE {
  NORMAL = 0,
  SIGN_IN = 1,
  LIVE_CHAT = 2,
  DOENLOAD_APP = 3,
  RECHARGE_ACTIVITY = 4,
  RECHARGE_PACKAGE = 5,
}

export interface FloatButton {
  key: string // id
  imageSrc?: string // Url 为空时请用c端写死图片
  text?: string
  type: HOME_HOVER_BTN_TYPE // 悬浮按钮类型(1：签到按钮；2：在线客服按钮, 3: 下载app按钮)
  [key: string]: any
}

export interface BottomRecommend {
  action: Action
  language_content: Record<string, BottomRecommendPropConfig>
  id: string
  status: number
  sort_weight: number
  [key: string]: any
}

export interface topPromotionsConfig {
  action: Action
  id: string | number
  language_content: Record<string, TopPromotionPropConfig>
  platform_name: string
  sort_weight: number
  status: number
  [key: string]: any
}

function createHomePageConfigTrans(lang: string) {
  const transToMenuItem = (item: SideBarMenuItemProps): MenuItem => {
    return {
      key: item.id,
      action: item.action,
      icon: item.icon,
      imageSrc: item.icon,
      iconActive: item.actived_icon,
      text: item.language_content[lang]?.title,
    }
  }

  const transToBanner = (item: HomePageBannerProps): Banner => {
    return {
      key: item.id,
      action: item.action,
      imageSrc: item.language_content[lang]?.image,
      mobileImageSrc: item.language_content[lang]?.mobile_image,
    }
  }

  const transToFloatButton = (item: HomePageHoverButtonProps): FloatButton => {
    const languageContent = item.language_content
      ? item.language_content[lang]
      : {}
    return {
      key: item.id,
      // type: item.type,
      imageSrc: languageContent?.image,
      ...item,
    }
  }

  return {
    menu: transToMenuItem,
    banner: transToBanner,
    floatBtn: transToFloatButton,
  }
}

export interface MobileBarMenuPropsConfig {
  image: string // 未选中
  image_active: string // 选中
  name: string // 未选中
  name_active: string // 选中
}

export interface BottomRecommendPropConfig {
  title: string
}

export interface TopPromotionPropConfig {
  btn_text: string
  promotion_text: string
}

// 移动端导航配置
export interface MobileBarMenuProps {
  id: string
  action: Action // 未选中按钮点击反馈跳转相关
  action_active: Action // 跳转选中按钮点击反馈跳转相关
  language_content: Record<string, MobileBarMenuPropsConfig>
  [key: string]: any
}

export interface HomePageConfigInClient {
  sideBar?: {
    largeBanners: Banner[]
    smallBanners: Banner[]
    dropdown: SideBarDropDown
    menuItems: MenuItem[] | undefined
    isConfigLargeBanner: boolean
    isConfigSmallBanner: boolean
    isConfigDropDown: boolean
    isConfigDropDownMenuItems: boolean
    isConfigBottomMenu: boolean
  }
  tabBar: MobileBarMenuProps[]
  heroSection?: {
    largeBanners: Banner[] | undefined
    smallBanners: Banner[] | undefined
    fixedBanners: Banner[] | undefined
  }
  dailyRanking?: {
    mobileShow?: boolean
    pcShow?: boolean
  }
  floatButtons?: FloatButton[]
  bottomRecommend?: BottomRecommend[]
  topPromotions?: topPromotionsConfig
}

// 当前设备
const device = getDeviceType()

export function transToHomePageConfigInClient(
  config: HomePageConfig,
  lang: string,
) {
  const isEnable = (item: { status: number }) => item.status === 1
  const isScope = (item: { scope?: SCOPE_TYPE }) =>
    !item.scope || item.scope === SCOPE_TYPE[CURRENT_SCOPE]
  const isShowInApk = (item: { action: Action }) => {
    return (
      !['apk', 'ios-desktop-icon'].includes(DEVICE) ||
      (['apk', 'ios-desktop-icon'].includes(DEVICE) &&
        item.action.eventName !== 'download-app')
    )
  }
  const trans = createHomePageConfigTrans(lang)

  const sideBarBanners = {
    largeBanners: [] as Banner[],
    smallBanners: [] as Banner[],
    isConfigLargeBanner: false,
    isConfigSmallBanner: false,
    isConfigDropDown: false,
    isConfigDropDownMenuItems: false,
    isConfigBottomMenu: false,
  }
  if (config?.side_bar_banner_config) {
    const largeBanners = config?.side_bar_banner_config.filter(
      item => item.type === 1,
    )
    const smallBanners = config?.side_bar_banner_config.filter(
      item => item.type === 2,
    )
    if (largeBanners.length) {
      sideBarBanners.isConfigLargeBanner = true
      sideBarBanners.largeBanners = largeBanners
        .filter(item => isEnable(item) && isScope(item) && isShowInApk(item))
        .map(item => trans.banner(item))
    }
    if (smallBanners.length) {
      sideBarBanners.isConfigSmallBanner = true
      sideBarBanners.smallBanners = smallBanners
        .filter(item => isEnable(item) && isScope(item) && isShowInApk(item))
        .map(item => trans.banner(item))
    }
  }

  /**如果有配置sideBarDropDown */

  if (
    config?.side_bar_dropdown_config &&
    Object.keys(config?.side_bar_dropdown_config).length
  ) {
    sideBarBanners.isConfigDropDown = true
  }

  if (config?.side_bar_dropdown_menu && config?.side_bar_dropdown_menu.length) {
    sideBarBanners.isConfigDropDownMenuItems = true
  }

  const sideBarDropDown: SideBarDropDown = {
    title: config?.side_bar_dropdown_config?.language_content?.[lang]?.title,
    imageSrc: config?.side_bar_dropdown_config?.image ?? '',
    btnText:
      config?.side_bar_dropdown_config?.language_content?.[lang]?.btn_text,
    action: config?.side_bar_dropdown_config?.action,
    items:
      config?.side_bar_dropdown_menu
        ?.filter(item => isEnable(item) && isScope(item) && isShowInApk(item))
        .map(trans.menu) || [],
  }

  const floatButtons = config?.homepage_hover_button
    ?.filter(isEnable)
    ?.reduce((acc, item) => {
      acc.push(trans.floatBtn(item))
      return acc
    }, [] as FloatButton[])

  let sideBarMenuItems: MenuItem[] | undefined = undefined

  if (config?.side_bar_bottom_menu && config?.side_bar_bottom_menu.length) {
    sideBarBanners.isConfigBottomMenu = true
    sideBarMenuItems =
      config?.side_bar_bottom_menu
        ?.filter(item => isEnable(item) && isScope(item) && isShowInApk(item))
        .map(trans.menu) || []
  }

  function getEnabledBanners(
    banners:
      | ({
          status: number
        } & HomePageBannerProps)[]
      | undefined,
    trans: ReturnType<typeof createHomePageConfigTrans>,
  ): Banner[] | undefined {
    // 未配置或者配置为空时，返回空数组
    if (!banners || !banners.length) return []
    const temp = banners.reduce((acc, item) => {
      if (isEnable(item) && isScope(item) && isShowInApk(item)) {
        acc.push(trans.banner(item))
      }
      return acc
    }, [] as Banner[])
    // 如果过滤后的数组为空，返回undefined
    return temp.length > 0 ? temp : undefined
  }

  const heroSection = {
    largeBanners: getEnabledBanners(config?.homepage_big_banner, trans),
    smallBanners: getEnabledBanners(config?.homepage_small_banner, trans),
    fixedBanners: getEnabledBanners(config?.homepage_fixed_banner, trans),
  }

  let bottomRecommend = config?.bottom_recommend || []
  bottomRecommend = bottomRecommend?.filter(item => item.status === 1)

  const isAndroid = ['apk', 'android-desktop-icon', 'android'].includes(device)
  const isIos = ['ios-desktop-icon', 'ios'].includes(device)

  const selectId = isAndroid ? 2 : isIos ? 3 : 1

  const topPromotions = config?.top_promotion_bar?.find(
    item => item.status === 1 && Number(item.id) === selectId,
  )

  return {
    sideBar: {
      largeBanners: sideBarBanners?.largeBanners,
      smallBanners: sideBarBanners?.smallBanners,
      isConfigLargeBanner: sideBarBanners?.isConfigLargeBanner,
      isConfigSmallBanner: sideBarBanners?.isConfigSmallBanner,
      isConfigDropDown: sideBarBanners?.isConfigDropDown,
      isConfigDropDownMenuItems: sideBarBanners?.isConfigDropDownMenuItems,
      isConfigBottomMenu: sideBarBanners?.isConfigBottomMenu,
      dropdown: sideBarDropDown,
      menuItems: sideBarMenuItems,
    },
    tabBar: config?.footer_bar_menu || [],
    heroSection: {
      largeBanners: heroSection.largeBanners,
      smallBanners: heroSection.smallBanners,
      fixedBanners: heroSection.fixedBanners,
    },
    dailyRanking: config?.homepage_rank_manage
      ? {
          mobileShow: config?.homepage_rank_manage?.mobile_show === 1,
          pcShow: config?.homepage_rank_manage?.pc_show === 1,
        }
      : undefined,
    floatButtons,
    // 底部推荐
    bottomRecommend: bottomRecommend || [],
    // 顶部通知
    topPromotions: topPromotions || {},
  }
}
