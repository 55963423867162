import { Button, Flex,  Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'



export default function NotFound() {
  const navigate = useNavigate()
  const { t } = useTranslation()
  return (
    <Flex
      flexDirection='column'
      alignItems='center'
      py={{ base: '40', lg: '60' }}
      px='5'
      textAlign='center'
    >
      <Text textStyle='h4' mb='6'>
        404
      </Text>

      <Text textStyle='text6' color='gray.900' mb='8' w='full' maxW='xs'>
        {t('NOTFOUND_NOT_EXIST')}
      </Text>
      <Button
        onClick={() => navigate('/')}
        size='lg'
        colorScheme='fill-prim'
        w='full'
        maxW='xs'
      >
        {t('NOTFOUND_BACK_HOME')}
      </Button>
    </Flex>
  )
}
