import isMobile from 'ismobilejs'

import { getVariable, isFEProduction } from '@/common/env'
import { getCurrencyCode } from '@/common/locale/currency'
import { decimalFormatByCode } from '@/common/locale/currency-const'
import { isFromApk, isFromApp, isFromIpa } from '@/common/platform/utils'
import { getAdid, getWebUUID } from '@/common/tracker/trackers/adjustWeb'
import store from '@/modules/app/store'
import { ClientType } from '@/modules/user/types'
import { localStorage } from '@/utils/localStorage'
import property, {
  features,
} from '@/websites/current/property'

const S3_MULTI_DOMAIN = getVariable('S3_MULTI_DOMAIN')

/** 随机密码 */
export const generateRandomPassword = () => Math.random().toString(36).slice(2)

/** 随机用户ID */
export const generateRandomUserId = () =>
  'User' +
  (new Date().getTime() * Math.random() * 1000000).toString().slice(2, 11)

//export const formatLocalMoney = (n = 0) => `${getSign()} ${n.toLocaleString()}`

// 重构后的货币格式化函数 新增参数_sign 如果传入_sign 则使用_sign作为货币符号
// 否则使用store中的货币符号
export const formatLocalMoney = (n = 0, _sign?: string) => {
  const CURRENCY = getCurrencyCode()
  const sign =
    typeof _sign === 'string' ? _sign : store.getState().user.currencySign
  return `${_sign || sign} ${decimalFormatByCode(CURRENCY, n)}`
}

/** 检测是否在移动应用中 */
export { isFromApk } from '@/common/platform/utils'

/** 延迟函数 */
export const delay = (delayTime: number) => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve(true)
    }, delayTime)
  })
}

/**
 *
 * @param key string
 * @returns string Return an empty string if the key does not exist
 *
 */
export function getUrlParam(key: string) {
  const reg = new RegExp('(^|&)' + key + '=([^&]*)(&|$)', 'i')
  const r = window.location.search.substr(1).match(reg)
  if (r !== null) {
    return decodeURIComponent(r[2])
  }
  return ''
}

export function getColorMode() {
  return property.colorMode
}

export function getColorModeValue<TLight = unknown, TDark = unknown>(
  light: TLight,
  dark: TDark,
): TLight | TDark {
  return getColorMode() === 'light' ? light : dark
}

export function isSupportedImageUrl(url: string): boolean {
  return /\.(apng|avif|gif|jpe?g|png|svg|webp)$/i.test(url)
}

/**
 * 请求全屏
 */
export function requestFullscreen(ele: any) {
  if (!ele) return
  if (ele.requestFullscreen) {
    ele.requestFullscreen()
  } else if (ele.mozRequestFullScreen) {
    ele.mozRequestFullScreen()
  } else if (ele.webkitRequestFullscreen) {
    ele.webkitRequestFullscreen()
  } else if (ele.webkitRequestFullScreen) {
    ele.webkitRequestFullScreen()
  } else if (ele.msRequestFullscreen) {
    ele.msRequestFullscreen()
  }
}

/**
 * 判断当前进入页面的设备类型(目前还缺少IOS客户端的判断)
 */

export function getDeviceType() {
  const ua = navigator.userAgent
  const fromApp = isFromIpa()

  // 是否从桌面图标进入
  let isFromIconInDesktop = false
  if (
    'standalone' in window.navigator &&
    (window as any).navigator['standalone']
  ) {
    isFromIconInDesktop = true
  }

  // 是否是安卓
  const isAndroid = /android|adr|linux/gi.test(ua)
  if (isAndroid) {
    // 是否是安卓APK
    const KEY = 'fromApk'
    if (window.location.href.indexOf(KEY) !== -1 || localStorage.getItem(KEY)) {
      localStorage.setItem(KEY, KEY)
      return 'apk'
    }

    return isFromIconInDesktop ? 'android-desktop-icon' : 'android'
  }

  // 是否是IOS
  const isIOS = /iphone|ipod|ipad/gi.test(ua) && !isAndroid
  if (fromApp) return 'ios_app'
  if (isIOS) return isFromIconInDesktop ? 'ios-desktop-icon' : 'ios'

  return 'pc'
}

/**
 * 获取用户的设备系统版本
 */

export function getDeviceOSVersion() {
  const userAgent = navigator.userAgent
  let osVersion = ''
  if (userAgent.indexOf('Windows NT') !== -1) {
    osVersion = userAgent.split('Windows NT ')[1].split(';')[0]
  } else if (userAgent.indexOf('Mac OS X') !== -1) {
    osVersion = userAgent.split('Mac OS X ')[1].split(')')[0]
  } else if (userAgent.indexOf('Android') !== -1) {
    osVersion = userAgent.split('Android ')[1].split(';')[0]
  } else if (userAgent.indexOf('iPhone OS') !== -1) {
    osVersion = userAgent.split('iPhone OS ')[1].split(' ')[0]
  }
  return osVersion
}

/**
 * S3图片资源域名白名单
 */
export const s3SourceWhiteList = [
  'static.apanalo.com', // P3
  'static.lucky.com', // p2
  'static.betfiery.com', // B1
  'static.rico100.com', // B3
  'static.aajogo.com', // B2
  'static.mexlucky.com', // M1
  'static.mexswin.com', // M2
  'static.megawin001.com', // N1
  'static.kelucky.com', // K1
]

/**
 * 处理S3资源的域名
 */
export function handleS3SourceDomain(src: string) {
  if (isFEProduction() && S3_MULTI_DOMAIN) {
    // 是前端生产环境（不包含release）
    const hostdomain = location.host.split('.')
    const host2 = hostdomain.slice(-2).join('.')
    let _src = src
    // 是否匹配到S3白名单资源域名
    const httpsS3 = 'https://' + S3_MULTI_DOMAIN
    const httpS3 = 'http://' + S3_MULTI_DOMAIN
    if (
      src.startsWith(httpsS3) ||
      src.startsWith(httpS3) ||
      src.startsWith(S3_MULTI_DOMAIN)
    ) {
      const _reg = new RegExp(`${S3_MULTI_DOMAIN}`)
      _src = src.replace(_reg, `static.${host2}`)
    }
    return _src
  } else {
    return src
  }
}

const getClientType = (): ClientType => {
  const isMobileResult = isMobile(window.navigator)
  const fromApp = isFromApp()
  if (isMobileResult.any) {
    if (isMobileResult.android.phone && fromApp) {
      return ClientType.AndroidApk
    } else if (isMobileResult.android.phone && !fromApp) {
      return ClientType.AndroidWeb
    } else if (isFromIpa()) {
      return ClientType.IosApp
    } else if (isMobileResult.apple.phone && !isFromIpa()) {
      return ClientType.IosWeb
    }
  } else {
    return ClientType.PcWeb
  }
  return ClientType.Other
}

export const clientType = getClientType()
export function isFromAd() {
  const promotionalChannel = new URLSearchParams(window.location.search).get(
    'promotionalChannel',
  )
  return promotionalChannel === 'fromAd'
}

/**
 *
 * @returns 是否来自马甲
 */
export function isFromVest() {
  new URLSearchParams(window.location.search).get('fromVest')
  return window.location.search?.indexOf('fromVest') > -1
}

export function get_url_params(name: string, url: string) {
  const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
  url = url || window.location.search
  const r = url.substr(1).match(reg)
  if (r !== null) {
    return decodeURIComponent(r[2])
  }
  return null
}
function removeKeysWithMissingValues(obj: Record<string, any>) {
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      if (
        obj[key] === null ||
        obj[key] === undefined ||
        obj[key] === 'null' ||
        obj[key] === 'undefined'
      ) {
        obj[key] = ''
      }
    }
  }

  return obj
}

export function getN1ServiceBuryData() {

  let res = {}

  if (isFromApk()) {
    try {
      const adArgs = JSON.parse(window.AndroidWebView.getAdArgs() || '{}')
      const clickLabel = (adArgs?.clickLabel || '')?.split('__')

      res = {
        adid: adArgs?.adid || '',
        web_uuid: '',
        gps_adid: adArgs?.googleAdid || '',
        external_id: clickLabel?.[1]?.slice(3) || '',
        fbc: clickLabel?.[2]?.slice(3) || '',
        fbp: clickLabel?.[3]?.slice(3) || '',
      }
      return {
        adjust: removeKeysWithMissingValues(res),
      }
      // eslint-disable-next-line no-empty
    } catch (error) {}
  }

  res = {
    adid: getAdid(),
    web_uuid: getWebUUID(),
    gps_adid: '',
    external_id: sessionStorage.getItem('external_id'),
    fbc: sessionStorage.getItem('fbc'),
    fbp: sessionStorage.getItem('fbp'),
  }

  return {
    adjust: removeKeysWithMissingValues(res),
  }
}

export function getFeatures(key: string) {
  return (features as Record<string, any>)[key] ?? ''
}

export function isNotLink(string: string) {
  // 匹配链接的正则表达式模式
  const linkPattern = /^(http|https|ftp):\/\/[^\s/$.?#].[^\s]*$/i

  // 判断字符串是否匹配链接模式
  return !linkPattern.test(string)
}
