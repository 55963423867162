const Drawer = {
    parts: ["header"],
  baseStyle: {
      colorScheme: {
          normal: {
              dialog: {
                  "--drawer-bg": "var(--chakra-colors-bg-drawer)",
                  "--chakra-colors-bg-control": "var(--chakra-colors-gray-100)"
              }
          },
          multilayer: {
              dialog: {
                  "--drawer-bg": "var(--chakra-colors-gray-200)"
              }
          }
      },
      dialog: {
          bg: "var(--drawer-bg)",
          mx: {
              base: "2.5",
              md: "auto"
          }
      },
      header: {
          textAlign: "center",
          fontWeight: "extrabold",
          fontSize: "lg",
          pt: 7,
          pb: 2
      },
      overlay: {
          layerStyle: "ls-drawer-mask"
      },
      body: {
          m: 0,
          px: 5,
          pb: 8
      }
  },
  variants: {
      round: {
          dialog: {
              borderTopLeftRadius: "2xl",
              borderTopRightRadius: "2xl",
              maxH: "90vh",
              minH: "60vh",
              backdropFilter: "blur(2px)"
          },
          body: {
              borderRadius: "2xl"
          }
      },
      "fill-square": {
          dialog: {
              my: 0,
              mx: 0,
              borderRadius: 0
          }
      }
  },
  sizes: {
      sm: {
          dialog: {
              w: "356px",
              maxW: "356px"
          }
      },
      md: {
          dialog: {
              w: "411px",
              maxW: "411px"
          },
          body: {
              m: 0,
              px: 7,
              pb: 7
          }
      },
      mmd: {
          dialog: {
              w: "460px",
              maxW: "460px"
          },
          body: {
              mx: 10,
              my: 5,
              p: 0
          }
      },
      lg: {
          dialog: {
              w: "556px",
              maxW: "556px"
          }
      },
      "3xl": {
          dialog: {
              w: "794px",
              maxW: "794px"
          },
          body: {
              m: 0,
              px: 7,
              pb: 7
          }
      }
  },
  defaultProps: {
      variant: "round",
      colorScheme: "normal"
  }
}

export default Drawer;