import { configureStore } from '@reduxjs/toolkit'
import type { TypedUseSelectorHook } from 'react-redux'
import { useDispatch, useSelector } from 'react-redux'

import { api } from '@/services/api'
import { jsonApi, pageConfigApi } from '@/services/jsonApi'

import { appLayoutReducer } from '../app-layout/app-layout-slice'
import { autoModalReducer } from '../auto-modal/ModalSlice'
import { currencyModalReducer } from '../bind-currency/currencySlice'
import { gameReducer } from '../game/gameSlice'
import { globalModalReducer } from '../global-modal/globalModalSlice'


import { authMiddleware, userReducer } from '../user/userSlice'
import { listenerMiddleware } from './listenerMiddleware'
import modalsReducer from './modalSlice'

export const store = configureStore({
  reducer: {
    user: userReducer,
    globalModal: globalModalReducer,
    modals: modalsReducer,
    appLayout: appLayoutReducer,
    // referralDetailsNew: referralDetailsNewReducer,
    // referralNew: referralNewReducer,
    [api.reducerPath]: api.reducer,
    [jsonApi.reducerPath]: jsonApi.reducer,
    [pageConfigApi.reducerPath]: pageConfigApi.reducer,
    currencyModal: currencyModalReducer,
    gameModal: gameReducer,
    autoModal: autoModalReducer,
  },

  middleware: gDM =>
    gDM()
      .prepend(listenerMiddleware.middleware)
      .concat(api.middleware)
      .concat(jsonApi.middleware)
      .concat(pageConfigApi.middleware)
      .concat(authMiddleware),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export type AppState = ReturnType<typeof store.getState>

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export default store
