const Heading = {
    baseStyle: {
        position: "relative",
        fontFamily: "inherit",
        cursor: "default"
    },
    variants: {
        accent: {
            display: "flex",
            w: "max-content",
            px: "4",
            my: "7",
            mx: "auto",
            maxW: "100%",
            color: "text.accent",
            textAlign: "center",
            textTransform: "uppercase",
            _before: {
                content: "''",
                position: "absolute",
                top: "0.5em",
                left: "0",
                display: "block",
                w: "2",
                h: "2",
                bgColor: "prim.500",
                borderRadius: "full"
            },
            _after: {
                content: "''",
                position: "absolute",
                top: "0.5em",
                right: "0",
                display: "block",
                w: "2",
                h: "2",
                bgColor: "seco.500",
                borderRadius: "full"
            }
        },
        star: {
            display: "flex",
            w: "max-content",
            px: "6",
            my: "2",
            mx: "auto",
            maxW: "100%",
            color: "white",
            textAlign: "center",
            textTransform: "uppercase",
            "word-break": "break-word",
            _before: {
                content: "''",
                position: "absolute",
                top: "50%",
                left: "0",
                display: "block",
                w: "4",
                h: "15.5px",
                borderRadius: "full",
                backgroundImage: "url(/assets/images/star.png)",
                backgroundSize: "contain",
                transform: "translateY(-50%)"
            },
            _after: {
                content: "''",
                position: "absolute",
                top: "50%",
                right: "0",
                display: "block",
                w: "4",
                h: "15.5px",
                borderRadius: "full",
                backgroundImage: "url(/assets/images/star.png)",
                backgroundSize: "contain",
                transform: "translateY(-50%)"
            }
        }
    },
    sizes: {
        md: {
            fontSize: "18px",
            lineHeight: "24px",
            fontWeight: "800"
        }
    }
}

export default Heading;